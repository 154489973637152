@import 'modules/variables';
@import 'modules/mixins';

@import 'partials/animations';
@import 'partials/messages.scss';

/* 
-------------------------------
TABLE OF CONTENTS
-------------------------------

General
Skip Menu
Content
Notifications
Side Navigation
Secondary Navigation
Mobile/Compact Nav Toggle
Mobile/Compact Nav
Experiences
Logged Out Nav
Open State
Responsive - Misc.

------------------------------- 
*/

// -------------------------------
// SKIP MENU
// -------------------------------

.re-skip {
	position: fixed;
	left: 0;
	list-style: none;
	font-family: $font-family-sans;
	margin: 0;
	padding: 0;
	top: 0;
	z-index: 1000;
	
	&-item {
		margin: 0;
		position: absolute;
	}

	&-link {
		display: block;
		left: -9999em;
		position: absolute;
		padding: 5px;
		white-space: nowrap;
		@include selected-state($color-grey, $color-white);
	}
	
	&-link:focus {
		position: static;
		left: 0;
	}
}


// -------------------------------
// CONTENT
// -------------------------------

html {
	position: relative;
	min-height: 100vh;

	@media #{$mediaquery-nav-compact-max} {
		&.re-compact-overflow-y-hidden {
			overflow-y: hidden;
			height: 100vh;
			min-height: 0;

			body {
				overflow-y: hidden;
				min-height: 0;
				position: fixed;
			}
		}
	}
}

// -------------------------------
// NOTIFICATIONS
// -------------------------------

.re-page-navigation {
	.re-notification {
		background-color: $color-error;
		color: $color-white;
		border-radius: 17px;
		display: none;
		font-size: $font-size-xxs;
		padding: 3px;
		right: 10px;
		left: auto;
		@include vertical-align-translate;

		&-dot {
			padding: 0;
			width: 6px;
			height: 6px;
			left: 4px;
			right: auto;
		}
	}

	.re-notification-dot {
		display: none;
	}

	.re-secondary-hasactivity .re-notification {
		display: block;
		right: 4px;
		max-width: 25px;
		word-break: break-all;
	}

	.re-nav-hasactivity {
		.re-notification-dot,
		> .re-notification {
			display: block;
		}

		.re-nav-item.re-icon:before {
			opacity: 1;
		}
	}
}

// -------------------------------
// SIDE NAVIGATION
// -------------------------------

.re-nav-container {
	font-family: $font-family-sans;
	font-size: $font-size-sm;
	font-weight: $font-weight-semibold;
	margin-left: $spacing-lg;
	position: relative;
	width: 185px;
	z-index: 300;

	&.re-tour-highlight {
		position: absolute;
	}

	@media #{$mediaquery-nav-full-min} {
		min-height: 100%;

		&.re-experienceswitcher-open {
			.re-nav-container-inner {
				box-shadow: 0 0 0 300vw rgba(black,0.25);
				border-top-right-radius: 0;
			}
		}
	}

	@media #{$mediaquery-nav-compact-max} {
		@include border(bottom);
		left: 0;
		margin-left: 0;
		padding: 0;
		position: fixed;
		right: 0;
		top: 0;
		width: 100%;
	}

	.re-nav-container-inner {
		@media #{$mediaquery-nav-compact-max} {
			border-radius: 0;
			position: static !important;
			width: auto !important;
		}
	}

	* {
		box-sizing: border-box;
	}

	a {
		color: $color-secondary;
		cursor: pointer;
		@include nav-transition;
		text-decoration: none;

		#{$hoverfocus} {
			@media #{$mediaquery-nav-full-min} {
				text-decoration: none;
			}
		}
	}

	.re-nav-primary {
		position: relative;

		@media #{$mediaquery-nav-compact-max} {
			text-align: right;
			font-size: 0;

			.re-notification-dot {
				font-size: 0;
				right: 12px;
				padding: 0;
				width: 8px;
				height: 8px;
				border: 1px solid $color-white;
			}

			.re-nav-list-item-primary {
				width: 56px;
			}
		}
	}

	.re-nav-list-item {

		&-primary:last-child {
			.re-nav-item-primary {
				@include border(bottom);
			}
		}

		@media #{$mediaquery-nav-full-min} {
			display: block;
			position: relative;

			&.re-position-static {
				position: static;
			}
		}

		.re-nav-item-downloadapp {
			.re-icon-mobile {
				padding-right: $spacing-sm;
				color: $color-primary;
			}
		}
	}

	.re-nav-user-handle {
		opacity: 0.8;
		font-size: $font-size-xs;
	}

	.re-image-item img {
		max-width: 24px;
		width: 100%;
		margin-right: 8px;
	}

	.re-icon-plus-circle {
		&:before {
			margin-right: 5px;
		}
	}

	.re-nav-item {
		display: block;
		margin-left: auto;
		margin-right: auto;
		padding: 20px 30px 20px 20px;
		position: relative;
		width: 100%;
		line-height: 1.1;
		min-height: 50px;
		word-break: break-word;

		@media #{$mediaquery-nav-compact-max} {
			padding: 10px;
		}

		#{$hoverfocus} {
			span:not(.re-notification) {
				@media #{$mediaquery-nav-full-min} {
					text-decoration: underline;
					text-decoration-skip-ink: auto;
				}
			}
		}

		&-primary {
			@include border(top);

			&:before {
				@include vertical-align-translate;
				right: 15px;
				opacity: 0.6;
			}

			&.re-nav-item-active.re-nav-highlight {
				color: $color-primary;
			}

			&.re-nav-item-open {
				@media #{$mediaquery-nav-full-min} {
					&:before {
						transform: translateY(-50%) rotate(90deg);
						opacity: 1;
					}
				}

				@media #{$mediaquery-nav-compact-max} {
					background: lighten($color-grey-xtralight, 2%);
				}
			}
		}

		&.re-avatar-sm {
			width: 100%;
			padding: 20px;
			height: auto;
			@include nav-transition;
			text-align: center;

			@media #{$mediaquery-nav-full-min} {
				padding-bottom: 0;
			}

			&.re-nav-item-active.re-nav-highlight {
				color: inherit;
			}

			&.re-nav-highlight,
			#{$hoverfocus} {
				@media #{$mediaquery-nav-full-min} {
					background: inherit;

					img {
						box-shadow: 0 0 0 4px $color-primary;
					}
				}
			}

			img {
				@include nav-transition;
				display: block;
				margin-left: auto;
				margin-right: auto;
			}

			&:after {
				content: none;
			}

			.re-nav-user-firstname {
				display: block;
				text-transform: none;
				margin-top: 10px;
				width: 100%;
			}

			@media #{$mediaquery-nav-compact-max} {
				border-radius: 0;
				height: 100%;
				width: 100%;

				img {
					height: 30px;
					width: 30px;
				}
			}
		}
	}

	.re-nav-item span {
		overflow: hidden;
		padding-bottom: 2px;
		text-overflow: ellipsis;
		word-break: keep-all;
	}

	.flexbox & {

		.re-nav-list-item {
			display: flex;
			flex-wrap: wrap;
		}

		.re-nav-item:not(.re-experiences-item) {
			padding: 7px 30px 7px 15px;
			display: flex;
			align-items: center;

			&.re-avatar-sm {
				display: block;
				height: 100%;
				padding: 20px;

				@media #{$mediaquery-nav-full-min} {
					padding-bottom: 0;
				}
			}

			&.re-nav-experience-switcher {
				padding: 10px;
				display: flex;
				justify-content: center;

				@media #{$mediaquery-nav-full-min} {
					border-top: 0;
					height: 60px;
				}
			}

			@media #{$mediaquery-nav-compact-max} {
				&.re-close-nav {
					display: none;
				}
			}
		}

		@media #{$mediaquery-nav-full-min} {
			.re-mobile-nav-logo {
				display: none;
			}
		}

		.re-mobile-list-item {
			@media #{$mediaquery-nav-compact-max} {
				display: inline-block;
			}

			> .re-nav-item {
				justify-content: center;
				padding: 10px 20px;
				text-decoration: none;
			}
		}

		.re-close-nav {
			justify-content: center;
		}

		.re-nav-secondary-experiences .re-nav-secondary-list {
			display: flex;
			flex-wrap: wrap;

			@media #{$mediaquery-nav-compact-max} {
				display: block;
			}
		}
	}
	// -------------------------------
	// SECONDARY NAVIGATION
	// -------------------------------
	.re-nav-secondary {
		@include border(top);
		box-shadow: inset 0 10px 20px -10px rgba(black,0.1), inset 0 -10px 20px -10px rgba(black,0.1);
		color: $rGreyDarkest;
		width: 100%;
		display: none;
		z-index: 99;

		@media #{$mediaquery-nav-full-min} {
			padding: 7px 0;
		}

		@media #{$mediaquery-nav-compact-max} {
			height: calc(100vh - 60px);
			overflow-y: auto;
		}

		&:not(.re-nav-secondary-experiences) {
			@media #{$mediaquery-nav-compact-max} {
				left: calc(45% + 10px);
				right: 0;
				top: 0;
				overflow: hidden;
			}
		}

		.re-nav-secondary-list {
			@media #{$mediaquery-nav-compact-max} {
				height: 100%;
				padding-bottom: 60px;
			}
		}

		.re-nav-list-item {
			&:last-child {
				position: relative;
				z-index: 999;
			}
		}

		.re-nav-item {
			display: block;
			overflow: hidden;
			padding-left: 30px;
			padding-right: 30px;

			@media #{$mediaquery-nav-full-min} {
				min-height: 0;
			}

			@media #{$mediaquery-nav-compact-max} {
				padding: 22px 20px;
				@include border(bottom);
			}

			&.re-nav-item-active {
				color: $color-primary;
			}
		}

		.re-close-nav {
			@include border(top);
			background: $color-white;
			text-align: center;
			color: $color-primary;
			position: relative;
			z-index: 1;
			box-shadow: -60px -5px 40px 35px rgba($color-white, 0.8);
		}
	}

	.re-nav-border-top {
		@media #{$mediaquery-nav-full-min} {
			@include border(top);
			padding-top: 6px;
		}
	}

	.re-powered-by-resaas {
		display: block;
		margin-bottom: 20px;
		margin-left: auto;
		margin-right: auto;
		margin-top: 20px;
		opacity: 0.7;
		width: 65%;
	}

	.re-scrollable-container {
		@media #{$mediaquery-nav-compact-max} {
			overflow-y: auto;
			height: 100%;
		}
	}
	// ------------------------------
	// NOTIFICATIONS
	// ------------------------------
	.re-notification {
		background-color: $color-error;
		color: $color-white;
		border-radius: 4px; // I know this isn't in our variables but 3 is too little and 5 is too much!
		display: none;
		font-size: $font-size-xxs;
		padding: 3px;
		right: 10px;
		left: auto;
		@include vertical-align-translate;

		&-dot {
			padding: 0;
			width: 8px;
			height: 8px;
			left: 3px;
			right: auto;
			border: 1px solid $rWhite;
		}
	}
	// -------------------------------
	// MOBILE/COMPACT NAV
	// -------------------------------
	.re-menu-container {
		@media #{$mediaquery-nav-compact-max} {
			overflow-x: hidden;
		}
	}

	.re-nav-mobile {
		@media #{$mediaquery-nav-compact-max} {
			display: none;
			font-size: $font-size-sm;
			margin-left: auto;
			margin-right: 0;
			position: relative;
			text-align: left;
			width: 100%;
			padding-left: 20px;
			padding-bottom: 60px;
			background: transparent;

			&:before {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: 20px;
				right: 0;
				bottom: 0;
				background: $color-white;
				min-height: calc(100vh - #{$topnavheight});
			}

			&:after {
				content: '';
				display: block;
				position: absolute;
				left: calc(45% + 10px);
				top: 0;
				bottom: 0;
				right: 0;
				background: lighten($color-grey-xtralight, 2%);
				box-shadow: inset 5px 0 15px -5px rgba($color-grey,0.2);
				min-height: calc(100vh - #{$topnavheight});
			}

			.re-nav-list-item-primary {
				position: static;
				width: 45%;
			}

			.re-nav-item-primary {
				padding: 21px 30px 21px 20px;
			}

			.re-avatar-sm {
				text-align: center;
				padding-right: 20px;
			}

			.re-nav-dialpad {
				text-align: center;
			}

			.re-nav-profile .re-nav-item-primary {
				flex-direction: column;
				justify-content: center;
				padding: 7px 26px;
				border-top: 0;

				span {
					display: block;
				}
			}

			.re-nav-secondary {
				background: $color-white;
				border: none;
				box-shadow: none;
				bottom: 0;

				&:not(.re-nav-experiences-items) {
					height: calc(100vh - #{$topnavheight});
					background: transparent;

					.re-nav-list-item {
						display: block;
						position: relative;
					}

					&:not(.re-nav-secondary-experiences) {
						position: fixed;
						top: 50px;
						right: 0;
						width: calc(55% - 10px);
						transform-style: preserve-3d;
						backface-visibility: hidden;
					}
				}
			}

			.re-close-nav {
				display: none;
			}

			.re-nav-list-item,
			.re-nav-item {
				display: block;
			}
		}
	}

	.re-mobile-list-item {
		text-align: center;

		@media #{$mediaquery-nav-compact-max} {
			display: inline-block;
			height: 100%;
			width: 45px;
		}

		> .re-nav-item {
			padding: 19px 20px;
			min-height: 0;
			height: 100%;
			border-top: none;

			&:before {
				@include fa-icon(18px);
			}

			&:after {
				content: none;
			}

			#{$hoverfocus} {
				@include nav-transition();

				@media #{$mediaquery-nav-full-min} {
					box-shadow: inset 0 0 0 5px $color-white;
				}
			}

			&.re-nav-item-active.re-nav-highlight {
				color: $color-primary;
			}
		}

		.re-notification {
			top: 17px;
			right: calc(50% - 16px);
			left: auto;

			@media #{$mediaquery-nav-compact-max} {
				top: 24px;
			}
		}

		+ :not(.re-mobile-list-item) {
			clear: both;
		}

		@media #{$mediaquery-nav-full-min} {
			float: left;
			width: 50%;
			height: 50px;
		}

		.re-nav-item {
			@media #{$mediaquery-nav-compact-max} {
				padding: 20px;
				height: 100%;
			}
		}

		&.re-mobile-nav-logo {
			@media #{$mediaquery-nav-full-min} {
				display: none;
			}

			@media #{$mediaquery-nav-compact-max} {
				border: none;
				position: absolute;
				left: 0;
				top: 0;
				bottom: 0;
				width: 125px;
			}
		}
	}
	// -------------------------------
	// EXPERIENCES
	// -------------------------------
	.re-nav-secondary-experiences {

		@media #{$mediaquery-nav-full-min} {
			z-index: 99;
			@include border(left);
			position: absolute;
			padding: 0;
			top: 0;
			left: 100%;
			width: 700px;
			margin-right: 20px;
			right: 0;
			max-height: calc(100vh - 40px);
			overflow: hidden;
			background: $color-white;
			box-shadow: 20px 10px 50px 0px rgba(black,0.2);
			border-radius: 0 $border-radius-large $border-radius-large 0;
		}

		@media #{$mediaquery-nav-compact-max} {
			display: block;
			border-radius: 0;
			min-width: 0;
			overflow-y: auto;
			opacity: 0;
			z-index: 100;
			visibility: hidden;
			width: 0;
			width: 100%;
			position: absolute;
			bottom: 0;
			left: -100%;
			right: auto;
			top: 0;
			@include nav-transition(0.5s);
		}

		.re-nav-secondary-list {
			@media #{$mediaquery-nav-full-min} {
				height: 100%;
				overflow-y: auto;
				max-height: calc(100vh - 116px);
				@include border;
				border-bottom: none;
			}
		}

		.re-toggle-experiences {
			font-size: $font-size-xs;
			background: $color-white;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			width: 71px;
			padding: 25px 5px 15px;
			text-align: center;
			z-index: 99;
			height: 100%;
			@include border(left);
			@include vertical-align;
			@include nav-transition;

			&:before {
				content: "\f078";
				@include fa-icon();
			}

			&.toggle-open:before {
				content: "\f077";
			}

			#{$hoverfocus} {
				@media #{$mediaquery-nav-full-min} {
					@include selected-state;
				}
			}

			@media #{$mediaquery-nav-full-min} {
				display: none;
			}
		}

		.re-nav-experiences-items {
			@media #{$mediaquery-nav-full-min} {
				// !important to override JS slideUp and slideToggle animations which render inline "display: none" styling
				display: block !important;
				padding: 7px 0;

				.re-nav-item {
					#{$hoverfocus} {
						text-decoration: underline;
						text-decoration-skip-ink: auto;
					}
				}
			}

			@media #{$mediaquery-nav-compact-max} {
				position: static;
				height: auto;
				height: auto;
				display: none;

				.re-nav-item {
					padding-right: 35px;
				}

				.re-nav-list-item:last-child {
					border-bottom: none;
				}
			}
		}

		.re-experiences-grid-item {
			@include border(bottom);

			.re-experiences-item[data-targetexperience="globalelite"] img {
				max-height: 100%;
			}

			@media #{$mediaquery-nav-full-min} {
				@include border(right);
				float: left;
				width: percentage(1/3);
				display: block;
				min-height: 0;
				flex: 0 0 33.33333%;
				// two to three items
				&:first-child:nth-last-child(2),
				&:first-child:nth-last-child(2) ~ .re-experiences-grid-item,
				&:first-child:nth-last-child(3),
				&:first-child:nth-last-child(3) ~ .re-experiences-grid-item {
					flex: 1 0 30%;
				}
				// four items
				&:first-child:nth-last-child(4),
				&:first-child:nth-last-child(4) ~ .re-experiences-grid-item {
					flex: 1 0 50%;
				}

				&:before {
					content: none;
				}

				img {
					display: inline-block;
					height: auto;
					max-height: 80%;
					max-width: 90%;
					vertical-align: middle;
				}
			}

			@media #{$mediaquery-nav-compact-max} {
				width: 100%;
				border-top: none;

				&:before {
					content: none;
				}
			}
		}

		.re-experiences-item {
			height: 100px;
			padding: 12px 30px;
			height: 60px;
			text-align: center;
			@include border(bottom);

			#{$hoverfocus} {
				@include selected-state(inherit, inherit);
			}

			&.re-current-experience {
				.re-icon {
					display: block;
				}
			}

			@media #{$mediaquery-nav-compact-max} {
				height: 70px;
				text-align: left;
				position: relative;
				padding: $spacing-md 80px $spacing-md $spacing-md;
				position: relative;
				border: none;
			}

			.re-notification {
				@media #{$mediaquery-nav-full-min} {
					display: none;
				}
			}

			.re-notification-dot {
				@media #{$mediaquery-nav-compact-max} {
					right: 80px;
				}
			}
		}

		.re-experience-image-container {
			width: 100%;
			height: 100%;
			@include vertical-align;

			.re-icon {
				position: absolute;
				bottom: 5px;
				right: 5px;
				display: none;
			}

			@media #{$mediaquery-nav-compact-max} {
				display: inline-block;
				position: relative;
				vertical-align: middle;

				img {
					max-height: 100%;
					max-width: 150px;
					display: inline-block;
					vertical-align: middle;
				}
			}
		}

		.re-nav-secondary {
			@media #{$mediaquery-nav-full-min} {
				display: block !important;
				border-left: 0;
				border-radius: 0;
				@include border(top);
				box-shadow: none;
				height: 300px;
				min-width: 0;
				overflow: auto;
				position: static;
				@include nav-transition;
				height: 0;
			}
		}

		.accordion-open {
			@media #{$mediaquery-nav-compact-max} {
				@include border(top);
				margin-bottom: -1px;
			}
		}

		.re-close-nav {
			box-shadow: none;
			margin-top: -1px;
		}

		.re-nav-item.re-nav-item-active {
			background: inherit;
		}
	}
	// Experience Switcher
	.re-nav-experience-switcher {
		padding: 10px;
		text-align: center;

		&:after {
			content: none;
		}

		&.re-nav-item-active.re-nav-highlight {
			@media #{$mediaquery-nav-full-min} {

				#{$hoverfocus} {
					box-shadow: inset 0 0 0 5px $color-grey-xtralight;
				}
			}
		}

		@media #{$mediaquery-nav-full-min} {
			#{$hoverfocus} {
				background: $color-white;
				box-shadow: inset 0 0 0 5px $color-grey-xtralight;

				.re-nav-experience-switcher-tab {
					box-shadow: inset 5px 0 0 $color-grey-xtralight;
					border-top: 5px solid $color-grey-xtralight;
					border-bottom: 5px solid $color-grey-xtralight;
				}
			}
		}
	}

	.re-nav-experience-switcher-tab {

		&:before {
			content: '';
			display: inline-block;
			background-image: url('../../../home/images/navigation/touchpad.svg');
			background-position: center;
			background-repeat: no-repeat;
			background-size: 100% auto;
			margin-left: auto;
			margin-right: auto;
			margin-left: 0;
			vertical-align: middle;

			@media #{$mediaquery-nav-compact-max} {
				height: 20px;
				width: 20px;
			}
		}

		+ .re-experience-logo {
			margin-left: 10px;
			margin-right: 0;
		}

		@media #{$mediaquery-nav-full-min} {
			left: -13px;
			background: $color-white;
			border-radius: 5px 0 0 5px;
			box-shadow: -5px 0 5px -3px rgba($color-grey,0.1);
			height: 100%;
			text-align: center;
			width: 34px;
			opacity: 1;
			@include vertical-align;
			@include vertical-align-translate;

			&:before {
				width: 17px;
				height: 16px;
			}
		}
	}

	.re-experience-home-link {
		@media #{$mediaquery-nav-compact-max} {
			padding: 10px;
			display: block;
			height: 100%;
			@include vertical-align;

			img.re-experience-logo {
				display: inline-block;
				vertical-align: middle;
				width: auto;
				height: auto;
				max-width: 100%;
				max-height: 100%;
				margin-left: 0;
			}
		}
	}

	.re-experience-logo {
		display: inline-block;
		vertical-align: middle;
		max-height: 40px;
		max-width: 150px;
		margin-left: auto;
		margin-right: auto;

		@media #{$mediaquery-nav-compact-max} {
			max-width: 80px;
		}
	}
	// -------------------------------
	// LOGGED OUT NAV
	// -------------------------------
	.re-nav-loggedout {
		display: none;

		@media #{$mediaquery-nav-compact-max} {
			text-align: right;
			padding-right: 10px;

			.re-nav-list-item {
				padding: 0;
				display: inline-block;
				height: 60px;
				text-align: center;
			}

			.re-nav-item {
				display: block;
				height: 100%;
			}

			.re-nav-sign-up,
			.re-nav-login {
				.re-nav-item {
					padding: 20px 10px;
				}
			}

			.re-nav-prompt {
				display: none;
			}
		}

		.re-nav-item:before {
			@include fa-icon($font-size-xl);
			display: block;
		}

		.re-nav-login .re-nav-item:before {
			@media #{$mediaquery-nav-full-min} {
				content: "\f007";
			}
		}

		.re-nav-sign-up .re-nav-item:before {
			@media #{$mediaquery-nav-full-min} {
				content: "\f234";
			}
		}

		.re-nav-logo {
			height: 60px;
			width: auto;

			@media #{$mediaquery-nav-full-min} {
				margin: 10px 0;
			}

			@media #{$mediaquery-nav-compact-max} {
				float: left;
			}

			.re-nav-item {
				padding: 15px 10px;
			}

			img {
				max-height: 100%;
				max-width: 100%;
			}
		}
	}
	// OPEN STATE
	// -------------------------------
	.re-nav-open {

		> .re-nav-secondary {
			z-index: 99;

			@media #{$mediaquery-nav-compact-max} {
				&.re-nav-secondary-experiences {
					background: $color-white;
					height: calc(100% - #{$topnavheight});
					left: 20px;
					opacity: 1;
					position: fixed;
					right: auto;
					top: $topnavheight;
					visibility: visible;
					width: calc(100% - 20px);
					z-index: 100;
				}
			}
		}

		&.re-menu-container {
			@media #{$mediaquery-nav-compact-max} {
				animation: fadeIn 0.3s ease 1;
				background: rgba(black,0.3);
				position: fixed;
				top: $topnavheight;
				left: 0;
				right: 0;
				bottom: 0;
				overflow-y: auto;
			}
		}

		.re-nav-mobile {
			@media #{$mediaquery-nav-compact-max} {
				display: block;
				animation: slideInFromRight 0.3s ease 1;

				.re-nav-secondary {
					animation: slideInFromRight 0.3s ease 1;
				}
			}
		}
	}
}

// deactive instant leads feature
.re-nav-instantbuyer {
	display: none!important;
}