@import "modules/variables.scss";

.re-usermention-items {
    position: absolute;
    background: $color-white;
    max-height: 234px;
    min-width: 200px;
    overflow-y: auto;
    border: $border;
    list-style: none;
    z-index: 9999;

    .re-usermention-no-found {
        padding: 5px $spacing-sm;
    }

    .re-usermention-item {
        cursor: pointer;
        color: $color-black;
        display: block;
        padding: $spacing-sm $spacing-sm;
        text-decoration: none;
        display: flex;
        flex-direction: row;
        align-items: center;

        &:hover {
            background: $color-primary-highlight;
        }

        span {
            margin-left: $spacing-sm;
        }
    }
}