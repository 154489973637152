/*********************************** */
/* RESAAS user list */
/*********************************** */

.re-userlist-overlay.overlay-wrapper {
    display: flex;
    flex-direction: column;

    @media #{$mediaquery-md-min} {
        max-width: 500px;
    }
}

.re-userlist {
	display: flex;
	flex-direction: column;

	a {
		color: inherit;

		#{$hoverfocus} {
			color: $color-primary-dark;
		}
	}

	&-title {
		padding: 0;
		margin-bottom: 15px;
		font-weight: 600;
		font-size: $font-size-sm;
	}

	&-list {
		overflow-y: auto;
		margin-right: -20px;
		padding-right: 20px;
	}

	&-item {
		@include flexSpaceBetween();
		align-items: center;
		padding: 5px 0;
		position: relative;

		&:before {
			content: '';
			display: block;
			position: absolute;
			left: 56px;
			right: 0;
			bottom: 0;
			@include border(bottom);
		}

		&:last-child {
			padding-bottom: 0;

			&:before {
				border-bottom: 0;
			}
		}
	}

	&-actions a {
		display: block;
		padding: 8px 0 8px 10px;
		color: $color-grey-light;

		#{$hoverfocus} {
			color: $color-primary;
		}
	}

	&-profilelink {
		flex: 1;
	}

	.re-agenttile-profilelink {
		display: block;
		padding: 8px 0;
	}
}

