%svg-common {
	background: url("../../../dist/images/spritesheets/resaas-spritesheet-17972f0c.svg") no-repeat;
}

.re-svg-badges-globalelite {
	@extend %svg-common;
	background-position: 0 0;
}

.re-svg-badges-globalelite-dims {
	width: 100px;
	height: 58.3px;
}

.re-svg-badges-premium {
	@extend %svg-common;
	background-position: 3.02571860816944% 0;
}

.re-svg-badges-premium-dims {
	width: 100px;
	height: 100px;
}

.re-svg-badges-premiumplus {
	@extend %svg-common;
	background-position: 6.05143721633888% 0;
}

.re-svg-badges-premiumplus-dims {
	width: 100px;
	height: 100px;
}

.re-svg-file-avi {
	@extend %svg-common;
	background-position: 9.025270758122744% 0;
}

.re-svg-file-avi-dims {
	width: 81px;
	height: 100px;
}

.re-svg-file-doc {
	@extend %svg-common;
	background-position: 11.462093862815884% 0;
}

.re-svg-file-doc-dims {
	width: 81px;
	height: 100px;
}

.re-svg-file-docx {
	@extend %svg-common;
	background-position: 13.898916967509026% 0;
}

.re-svg-file-docx-dims {
	width: 81px;
	height: 100px;
}

.re-svg-file-excel {
	@extend %svg-common;
	background-position: 16.335740072202167% 0;
}

.re-svg-file-excel-dims {
	width: 81px;
	height: 100px;
}

.re-svg-file-jpg {
	@extend %svg-common;
	background-position: 18.772563176895307% 0;
}

.re-svg-file-jpg-dims {
	width: 81px;
	height: 100px;
}

.re-svg-file-m4v {
	@extend %svg-common;
	background-position: 21.209386281588447% 0;
}

.re-svg-file-m4v-dims {
	width: 81px;
	height: 100px;
}

.re-svg-file-mov {
	@extend %svg-common;
	background-position: 23.646209386281587% 0;
}

.re-svg-file-mov-dims {
	width: 81px;
	height: 100px;
}

.re-svg-file-mp4 {
	@extend %svg-common;
	background-position: 26.08303249097473% 0;
}

.re-svg-file-mp4-dims {
	width: 81px;
	height: 100px;
}

.re-svg-file-pdf {
	@extend %svg-common;
	background-position: 28.51985559566787% 0;
}

.re-svg-file-pdf-dims {
	width: 81px;
	height: 100px;
}

.re-svg-file-png {
	@extend %svg-common;
	background-position: 30.95667870036101% 0;
}

.re-svg-file-png-dims {
	width: 81px;
	height: 100px;
}

.re-svg-file-ppt {
	@extend %svg-common;
	background-position: 33.39350180505415% 0;
}

.re-svg-file-ppt-dims {
	width: 81px;
	height: 100px;
}

.re-svg-file-pptx {
	@extend %svg-common;
	background-position: 35.83032490974729% 0;
}

.re-svg-file-pptx-dims {
	width: 81px;
	height: 100px;
}

.re-svg-file-text {
	@extend %svg-common;
	background-position: 38.26714801444043% 0;
}

.re-svg-file-text-dims {
	width: 81px;
	height: 100px;
}

.re-svg-file-wmv {
	@extend %svg-common;
	background-position: 40.70397111913358% 0;
}

.re-svg-file-wmv-dims {
	width: 81px;
	height: 100px;
}

.re-svg-gps-location-icon {
	@extend %svg-common;
	background-position: 42.350856467808626% 0;
}

.re-svg-gps-location-icon-dims {
	width: 19px;
	height: 19px;
}

.re-svg-planicons-alerts {
	@extend %svg-common;
	background-position: 43.963691376701966% 0;
}

.re-svg-planicons-alerts-dims {
	width: 100px;
	height: 100px;
}

.re-svg-planicons-calendar {
	@extend %svg-common;
	background-position: 46.98940998487141% 0;
}

.re-svg-planicons-calendar-dims {
	width: 100px;
	height: 100px;
}

.re-svg-planicons-chat {
	@extend %svg-common;
	background-position: 50.01512859304085% 0;
}

.re-svg-planicons-chat-dims {
	width: 100px;
	height: 100px;
}

.re-svg-planicons-connections {
	@extend %svg-common;
	background-position: 53.04084720121029% 0;
}

.re-svg-planicons-connections-dims {
	width: 100px;
	height: 100px;
}

.re-svg-planicons-emailmarketing {
	@extend %svg-common;
	background-position: 56.066565809379725% 0;
}

.re-svg-planicons-emailmarketing-dims {
	width: 100px;
	height: 100px;
}

.re-svg-planicons-global {
	@extend %svg-common;
	background-position: 59.092284417549166% 0;
}

.re-svg-planicons-global-dims {
	width: 100px;
	height: 100px;
}

.re-svg-planicons-globalmarketing {
	@extend %svg-common;
	background-position: 62.11800302571861% 0;
}

.re-svg-planicons-globalmarketing-dims {
	width: 100px;
	height: 100px;
}

.re-svg-planicons-graph {
	@extend %svg-common;
	background-position: 65.14372163388805% 0;
}

.re-svg-planicons-graph-dims {
	width: 100px;
	height: 100px;
}

.re-svg-planicons-leadgen {
	@extend %svg-common;
	background-position: 68.16944024205749% 0;
}

.re-svg-planicons-leadgen-dims {
	width: 100px;
	height: 100px;
}

.re-svg-planicons-listinguploads {
	@extend %svg-common;
	background-position: 71.19515885022693% 0;
}

.re-svg-planicons-listinguploads-dims {
	width: 100px;
	height: 100px;
}

.re-svg-planicons-ondemandvideo {
	@extend %svg-common;
	background-position: 74.22087745839637% 0;
}

.re-svg-planicons-ondemandvideo-dims {
	width: 100px;
	height: 100px;
}

.re-svg-planicons-oneonone {
	@extend %svg-common;
	background-position: 77.24659606656581% 0;
}

.re-svg-planicons-oneonone-dims {
	width: 100px;
	height: 100px;
}

.re-svg-planicons-ontouch {
	@extend %svg-common;
	background-position: 80.27231467473526% 0;
}

.re-svg-planicons-ontouch-dims {
	width: 100px;
	height: 100px;
}

.re-svg-planicons-posts {
	@extend %svg-common;
	background-position: 83.29803328290468% 0;
}

.re-svg-planicons-posts-dims {
	width: 100px;
	height: 100px;
}

.re-svg-planicons-prioritydiscovery {
	@extend %svg-common;
	background-position: 86.32375189107412% 0;
}

.re-svg-planicons-prioritydiscovery-dims {
	width: 100px;
	height: 100px;
}

.re-svg-planicons-star {
	@extend %svg-common;
	background-position: 89.34947049924357% 0;
}

.re-svg-planicons-star-dims {
	width: 100px;
	height: 100px;
}

.re-svg-planicons-timesaving {
	@extend %svg-common;
	background-position: 92.37518910741301% 0;
}

.re-svg-planicons-timesaving-dims {
	width: 100px;
	height: 100px;
}

.re-svg-planicons-unlimitedconnections {
	@extend %svg-common;
	background-position: 95.40090771558245% 0;
}

.re-svg-planicons-unlimitedconnections-dims {
	width: 100px;
	height: 100px;
}

.re-svg-referral-icon {
	@extend %svg-common;
	background-position: 98.42662632375189% 0;
}

.re-svg-referral-icon-dims {
	width: 100px;
	height: 92.76px;
}

.re-svg-sharecrm-logo-blue {
	@extend %svg-common;
	background-position: 99.23054158034921% 0;
}

.re-svg-sharecrm-logo-blue-dims {
	width: 26px;
	height: 26px;
}

.re-svg-sharecrm-logo-white {
	@extend %svg-common;
	background-position: 100% 0;
}

.re-svg-sharecrm-logo-white-dims {
	width: 26px;
	height: 26px;
}

