

.component {
	position: relative;


	> .gutter {
		margin: 10px;
	}

	&.mojoTemplate, .mojoTemplate {
		display: none;
	}

	.UIBlock {
		display: none;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 1000;
		background: white url(/assets/common/images/spinner20xmodern.gif) center center no-repeat;

		opacity: 0.7;

		&.show {
			display: block;
		}
	}
}

.component-global-reblast-module, .component-activity-feed {
	min-height: 115px;
}