.hidden {
	display: none !important;
}

/* Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */

.visuallyhidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

/* Extends the .visuallyhidden class to allow the element
 * to be focusable when navigated to via the keyboard:
 * https://www.drupal.org/node/897638
 */

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
	clip: auto;
	height: auto;
	margin: 0;
	overflow: visible;
	position: static;
	width: auto;
}

/* Hide visually and from screen readers, but maintain layout
 */

.invisible {
	visibility: hidden;
}

/* Clearfix: contain floats
 *
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *	`contenteditable` attribute is included anywhere else in the document.
 *	Otherwise it causes space to appear at the top and bottom of elements
 *	that receive the `clearfix` class.
 * 2. The use of `table` rather than `block` is only necessary if using
 *	`:before` to contain the top-margins of child elements.
 */

.clearfix:before,
.clearfix:after {
	content: " ";
	display: table;
}

.clearfix:after {
	clear: both;
}

/* ==========================================================================
 * EXAMPLE Media Queries for Responsive Design.
 * These examples override the primary ('mobile first') styles.
 * Modify as content requires.
 * ========================================================================== */

/* @media only screen and (min-width: 35em) */
/* Style adjustments for viewports that meet the condition */

/* @media print,
 * (-webkit-min-device-pixel-ratio: 1.25),
 * (min-resolution: 1.25dppx),
 * (min-resolution: 120dpi) */
/* Style adjustments for high resolution devices */

/* ==========================================================================
 * Print styles.
 * Inlined to avoid the additional HTTP request:
 * http://www.phpied.com/delay-loading-your-print-css/
 * ========================================================================== */

@media print {
	*,
	*:before,
	*:after {
		background: transparent !important;
		color: #000 !important;
		box-shadow: none !important;
		text-shadow: none !important;
	}

	a,
	a:visited {
		text-decoration: underline;
	}

	a[href]:after {
		content: " (" attr(href) ")";
	}

	abbr[title]:after {
		content: " (" attr(title) ")";
	}

	/* Don't show links that are fragment identifiers,
	 * or use the `javascript:` pseudo protocol
	 */

	a[href^="#"]:after,
	a[href^="javascript:"]:after {
		content: "";
	}

	pre,
	blockquote {
		border: 1px solid #999;
		page-break-inside: avoid;
	}

	/* Printing Tables:
	 * http://css-discuss.incutio.com/wiki/Printing_Tables
	 */

	thead {
		display: table-header-group;
	}

	tr,
	img {
		page-break-inside: avoid;
	}

	img {
		max-width: 100% !important;
	}

	p,
	h2,
	h3 {
		orphans: 3;
		widows: 3;
	}

	h2,
	h3 {
		page-break-after: avoid;
	}
}
