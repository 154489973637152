﻿// buttons variables
// ---------------------------------------------------------

// btn
$btnTextLight: $rGreyLightest;
$btnTextDark: $rGreyDarkest;
$btnActiveLoadBg: $rGrey;

// btn blue
$btnBlueBorder: $rBlueDark;
$btnBlueStart: $rBlueLight;
$btnBlueEnd: $rBlue;
$btnBlueIconBg: $rBlue;

// btn red
$btnRedBorder: #88151f;
$btnRedStart: $rRed;
$btnRedEnd: $rRedDark;
$btnRedIconBg: $rRedDark;

// btn yellow
$btnYellowBorder: #e1d730;
$btnYellowStart: $rYellow;
$btnYellowEnd: #e5d93b;
$btnYellowIconBg: #e5d93b;

// btn grey light
$btnGreyStart: #efefef;
$btnGreyEnd: $rGreyLightest;

// btn grey light
$btnGreyDarkStart: $rGreyDark;
$btnGreyDarkEnd: $rGreyDarkest;

@mixin btn2 {
	background: $color-primary;
	border: 1px solid $color-primary;
	border-radius: $border-radius-small;
	color: $color-white;
	margin: 10px 0 10px;
	min-width: 100px;
	padding: 10px 15px;
	font-size: $font-size-sm;
	position: relative;
	text-align: center;
	text-decoration: none;
	word-break: break-word;
	display: inline-block;
	-webkit-appearance: none;
	-moz-appearance: none;
	-o-appearance: none;
	appearance: none;

	&:hover,
	&:focus {
		background: $color-black;
		border-color: $color-black;
		text-decoration: none;
	}

	&:focus {
		text-decoration: underline;
	}

	& + & {
		margin-left: 10px;
	}
}

@mixin btn2-large {
	padding: 15px 25px;
	font-size: $font-size-md;
}

@mixin re-btn-small {
	min-width: 60px;
	padding: 6px 10px;
}

@mixin btn2-small {
	padding: 6px 10px;
}

@mixin btn2-color($background, $color, $hover-background, $hover-color) {
	background: $background;
	border-color: $background;
	color: $color;

	&:hover,
	&:focus {
		background: $hover-background;
		border-color: $hover-background;
		color: $hover-color;
	}
}

@mixin btn2-upload {
	cursor: pointer;
	overflow: hidden;
	width: 100px;

	& > input {
		bottom: 0;
		cursor: pointer;
		font-size: 1000px !important;
		height: 40px;
		opacity: 0;
		position: absolute;
		right: -2px;
		top: -2px;
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	}
}

@mixin btn2-full-width {
	box-sizing: border-box;
	display: block;
	margin-left: 0;
	margin-right: 0;
	width: 100%;

	& + & {
		margin-left: 0;
	}
}

@mixin btn2-icon {
	padding-left: 47px;

	&:before {
		background: no-repeat;
		content: " ";
		height: 20px;
		left: 14px;
		margin-top: -10px;
		position: absolute;
		top: 50%;
		width: 20px;
	}
}

@mixin submitting() {
	background: $color-black url("/assets/common/images/ajax-loader-2-black.gif") scroll no-repeat center center;
	color: transparent;
	pointer-events: none;
}

@mixin submittingBefore() {
	animation: icon-spin 2s infinite linear;
	background: none;
	content: '\f3f4'
}

@mixin submittingAfter() {
	&:after {
		background: url("/assets/common/images/ajax-loader-2-black.gif") scroll no-repeat center center transparent;
		content: " ";
		display: block;
		height: 20px;
		margin-left: -10px;
		margin-top: -10px;
		position: absolute;
		left: 50%;
		top: 50%;
		width: 20px;
	}
}
