.btn-overylay-close {
	color: $rGreyLightest;
	cursor: pointer;
	font-size: 23px;
	position: absolute;
	right: 5px;
	text-decoration: none;
	top: 5px;
	z-index: 1003;

	&:hover {
		color: #9B9B9B;
	}
}

.re-upgrade-to-premium-dismiss {
	color: #464646;
	right: 10px;
	top: -15px;
	z-index: 1;
}

.re-notificationbanner.js-add-billing-details-notification {
	.re-notificationbanner-inner {
		margin-bottom: $spacing-md;
	}
}

.re-notificationbanner {
	text-align: center;
	line-height: 1.7;
	margin: 0 auto $spacing-lg;

	.btn-overylay-close {
		right: 5px;
		top: 5px;
		text-decoration: none;
		z-index: 1;
	}

	&-inner {
		background: $color-primary;
		color: $rWhite;
		font-size: 18px;
		padding: 15px 30px;
		position: relative;

		a {
			color: $rWhite;
			text-decoration: underline;
		}

		.btn-overylay-close {
			color: $rWhite;
			line-height: 1;
		}
	}
}

.referral-pro-welcome {
	background: $rGreyLightest;
	font-size: $font-size-lg;
	position: relative;
	padding-right: 52px;
	text-align: center;

	span {
		line-height: 60px;
	}

	a {
		color: #000;
	}

	.premium-badges-silver-medium, .globalelite-assigned-badges-medium {
		display: inline-block;
		width: 34px;
		height: 47px;
		vertical-align: middle;
		margin-left: 10px;
	}

	.globalelite-assigned-badges-medium {
		width: 47px;
		top: 7px;
		position: relative;
	}

	.btn-overylay-close.premium-welcome-close {
		color: $color-grey-light;
		line-height: 1;
	}
}

.pending-notification {
	background: $color-primary;
	color: $color-white;
	font-size: $font-size-lg;
	padding: 11px 0;
	margin-bottom: $spacing-sm;
}

@media (min-width: 100px) and (max-width: 800px) {
	.mobile-decline-subscription {
		display: block;
		color: #fff;
		text-align: center;
		position: relative;
		top: -25px;
	}
}
