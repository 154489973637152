@mixin input-text($width) {
	width: $width;
	padding: 5px 8px;
	display: block;
	border: 1px solid #CCC;

	border-radius: $border-radius-small;
}

@mixin input-select($width) {
	width: $width;
	padding: 4px 5px;
	display: block;
	border: 1px solid #CCC;

	border-radius: $border-radius-small;
}

@mixin input-textarea($width, $height, $display: block) {
	width: $width;
	height: $height;
	padding: 4px 5px;
	display: $display;
	border: 1px solid #CCC;
	resize: none;

	border-radius: $border-radius-small;
}

.input-field {
	margin-bottom: 20px;
}

.form-label {
	font-size: $font-size-xs;
	display: block;
	margin: 5px 0;

	&.required {
		font-weight: bold;
	}
}

.form-checkbox-label {
	display: inline;
	padding: 0 12px 0 2px;
}

.form-col {
	float: left;
	margin: 0 20px 10px 0;
}

input {
	&.error {
		border: 1px solid red;
		color: red;
		background: url(/assets/home/images/sprites/sprite-icons.png) 100% -804px no-repeat;
	}

	&.valid {
		background: url(/assets/home/images/sprites/sprite-icons.png) 100% -836px no-repeat;
	}
}

textarea {
	&.error {
		border: 1px solid red;
		color: red;
	}
}

.form-btn-submit {
	@include btnflexy(75px, none);
}

.input-text {
	@include input-text(200px);
}

.input-select {
	@include input-select(218px);
}

.input-textarea {
	@include input-textarea(400px, 60px);
}
