/*********************************** */
/* RESAAS CSS3 Animations			 */
/*********************************** */

//animations config
@keyframes fadeIn {
	from {opacity: 0;}
	to {opacity: 1;}
}

@keyframes fadeOut {
	from {opacity: 1;}
	to {opacity: 0;}
}

@keyframes bgHighlight {
	from {background-color: #fff200;}
	to {background-color: white;}
}

@keyframes slideInFromRight {
	from {margin-left: 100%;}
	to {margin-left: 0;}
}

@keyframes slideInFromLeft {
	from {transform: translateX(-100%);}
	to {transform: translateX(0);}
}

@keyframes growSubnav {
	from {height: 0; opacity: 0;}
	to {height: 300px; opacity: 1;}
}

//animation classes
.fade-in-no-delay {
	animation: fadeIn 1.5s linear 0s;
}

.fade-in {
	animation: fadeIn 2s linear 2.5s;
}

.fade-out {
	animation: fadeOut 2s linear 2.5s;
}

.bg-highlight {
	animation: bgHighlight 1s linear 0s;
}
