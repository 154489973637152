﻿.re-idbar {
	display: flex;
	height: 0%;
	min-width: 0;

	a {
		color: $color-black;
	}

	.re-reblast-idbar-containerwithactions & {
		width: calc(100% - 20px);
	}
}

.re-idbar-avatar {
	margin-right: $spacing-md;
}

.re-idbar-info {
    align-self: center;
    max-width: calc(100% - 65px);

    .re-idbar-info-container {
        display: flex;
        flex-wrap: wrap;
    }

    .re-idbar-info-group {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .re-idbar-icons {
        margin-left: 5px;
    }
}

.re-idbar-name {
	font-weight: $font-weight-bold;
	position: relative;

	.re-icon-check-circle {
		padding-left: 5px;
	}
}

.re-idbar-details {
	margin-top: 3px;
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.re-idbar-detail {
	display: inline;

	&:not(:first-child):before {
		content: '\2002\00b7\2002';
		font-weight: 700;
	}

	@media #{$mediaquery-xs-max} {
		&:not(.re-idbar-detail-mobileview) {
			display: none;
		}

		&::before {
			display: none;
		}

		&.re-idbar-detail-mobileview ~ .re-idbar-detail-mobileview::before {
			display: inline;
		}
	}
}

.re-reblast-idbar-containerwithactions {
	display: flex;
}