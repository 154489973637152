@import "modules/_variables.scss";

html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
	position: relative;
	display: block;
}

blockquote, q {
	quotes: none;
}

blockquote {
	&:before, &:after {
		content: "";
		content: none;
	}
}

q {
	&:before, &:after {
		content: "";
		content: none;
	}
}

ins {
	background-color: #ff9;
	color: #000;
	text-decoration: none;
}

mark {
	background-color: #ff9;
	color: #000;
	font-style: italic;
	font-weight: bold;
}

del {
	text-decoration: line-through;
}

abbr[title], dfn[title] {
	border-bottom: 1px dotted;
	cursor: help;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid #ccc;
	margin: 1em 0;
	padding: 0;
}

input, select {
	vertical-align: middle;
}

body {
	font: 13px / 1.231 $font-family-sans;
	*font-size: small;
}

select, input, textarea, button {
	font: 99% $font-family-sans;
}

pre, code, kbd, samp {
	font-family: monospace, $font-family-sans;
}

a {
	outline: none;

	&:hover, &:active, &:visited, &:focus {
		outline: none;
	}
}

ul {
	margin-left: 0;
}

ol {
	margin-left: 0;
	list-style-type: decimal;
}

ul, li {
	margin: 0;
	list-style: none;
	list-style-image: none;
}

small {
	font-size: 85%;
}

strong, th {
	font-weight: bold;
}

td {
	vertical-align: top;
}

sub {
	font-size: 75%;
	line-height: 0;
	position: relative;
}

sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	top: -0.5em;
}

sub {
	bottom: -0.25em;
}

pre {
	white-space: pre;
	white-space: pre-wrap;
	word-wrap: break-word;
	padding: 15px;
}

textarea {
	overflow: auto;
}

.ie7 legend {
	margin-left: -7px;
}

input {
	&[type="radio"] {
		vertical-align: text-bottom;
	}

	&[type="checkbox"] {
		vertical-align: bottom;
	}
}

.ie7 input[type="checkbox"] {
	vertical-align: baseline;
}

label {
	cursor: pointer;
	border: 0;
}

input {
	&[type="button"], &[type="submit"], &[type="image"] {
		cursor: pointer;
		border: 0;
	}
}

button {
	cursor: pointer;
	border: 0;
	margin: 0;
}

input, select, textarea {
	margin: 0;
}

button {
	width: auto;
	overflow: visible;
}

.ie7 img {
	*-ms-interpolation-mode: bicubic;
}

body, select, input, textarea {
	color: #444;
}

input {
	&[type="text"], &[type="password"] {
		outline: none;
	}
}

textarea, select {
	outline: none;
}

button::-moz-focus-inner {
	border: none;
}

input {
	&::-moz-focus-inner {
		border: none;
	}

	&:focus {
		outline: none;
	}
}

/* utility classes */

.clearfix {
	clear: both;
}

.clear-after:after {
	content: " ";
	display: block;
	clear: both;
}

.error {
	color: red;
}

.hide {
	display: none;
}

/* external link icons */

a.external-link {
	padding-right: 20px;
	background: url(../../../home/images/dev/icon.external-link.png) right center no-repeat;
}
