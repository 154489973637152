﻿.re-sendmessage-popup-conatiner {
    display: flex;
    flex-flow: column;

    .re-sendmessage-popup-actions {
        align-self: flex-end;
    }

    .re-sendmessage-popup-desc {
        margin-bottom: $spacing-sm;
    }
}




