/*********************************** */
/* Basic layout and global wrapper	 */
/*********************************** */

// basic layout
.inner-wrapper {
	padding: 10px;
}

// panel container - for board page look
.panel-container {
	position: relative;
	z-index: 0;
}

// layout one column
.layout-one-column,
.layout-two-column {
	background: #fff;
	width: 100%;
}

// layout global
.layout-no-bg {
	background: transparent;
}

.layout-header {
    background: $color-white;

    .layout-header-title {
        color: $rGreyDarkest;
        line-height: 22px;
        padding: 15px 15px 13px;
    }
}

// full width background image
// we remove header background colour when using the full width image
// below we override some legacy style
[class*="fullwidthbg-"] {
	header {
		background: 0;
		position: relative;
	}

	.header-holder {
		.component-user-search {
			display: none !important;
		}
	}

	.component-footer-wrapper {
		background: $backgroundColor;
	}
}

.fullwidthbg-community {
	background: #fff url("https://content-eecuhxg6gch7hahp.a03.azurefd.net/cpgrowth/HomePageJuly2014.jpg") no-repeat center center fixed;

	background-size: cover;
}

// *****************************
// tablet portrait - smaller than 640px
// *****************************
@media only screen and (max-width: 640px) {
	.single-reblast-container {
		margin-top: 10px !important;
	}

	.layout-two-column-no-profile {
		float: none;
		padding: 0;
	}

	.column-right {
		margin-top: $spacing-lg;
	}
}

// *****************************
// tablet portrait - larger than 640px
// *****************************
@media only screen and (min-width: 640px) {
	.inner-wrapper {
		margin: 0 auto;
		width: 640px;
	}

	.column-main {
		float: right;
		width: 55.7377%;

		.column-left {
			float: none;
			width: 100%;
		}
	}

	.column-left {
		float: left;
		margin: 0 3.27869% 0 0;
		width: 40.98361%;
	}

	.layout-two-column {
		.column-left {
			float: none;
			width: 100%;
		}
	}

	.layout-two-column-no-profile {
		float: none;
		padding: 0;
	}
}

// *****************************
// tablet and desktop - min width 800px
// *****************************
@media only screen and (min-width: 800px) {
	section.reblast {
		min-height: 200px !important;
	}

	.column-main {
		width: 64.47368%;
	}

	.column-left {
		margin-right: 2.63158%;
		width: 32.89474%;
	}

	.layout-two-column-no-profile {
		float: none;
		padding: 0;
	}
}

// *****************************
// tablet and desktop - min width 980px
// *****************************
@media only screen and (min-width: 980px) {
	.column-main {
		width: 71.57895%;

		.column-left {
			float: left;
			margin: 0 2.94118% 0 0;
			width: 60.29412%;
		}

		.column-right {
			float: right;
			width: 36.76471%;
		}
	}

	.column-left {
		margin: 0 2.10526% 0 0;
		width: 26.31579%;
	}

	.inner-wrapper {
		width: 980px;
	}

	.column-aside,
	.column-content {
		float: left;
	}

	.column-aside {
		width: 25%;
	}

	.column-content {
		width: 75%;
	}

	.container {
		margin: 20px 0;
	}

	.container-inner {
		width: 980px;
	}

	.layout-two-column {
		.column-left {
			float: left;
			position: relative;
			width: 66.5%;
		}

		.column-right {
			float: right;
			position: relative;
			width: 31.375%;
		}
	}

	section.reblast {
		min-height: 200px !important;
	}

	.layout-two-column-no-profile {
		padding: 0 0 0 230px;
	}
}

// *****************************
// desktop - min width 1160px
// *****************************
@media only screen and (min-width: 1160px) {
	.layout-two-column-no-profile {
		width: auto;
		padding: 0 0 0 230px;
		box-sizing: border-box;
		max-width: 1160px;
		float: none;
	}

	.column-main {
		width: 76.72414%;

		.column-left {
			margin: 0 2.24719% 0 0;
			width: 69.66292%;
		}

		.column-right {
			width: 28.08989%;
		}
	}

	.column-left {
		margin: 0 1.72414% 0 0;
		width: 21.55172%;
	}
}

.column-right {
	#SideWidget > aside {
		margin-bottom: $spacing-lg;
	}
}

.re-useractivity-feed-container {
	display: flex;
	flex-flow: column;

	.re-useractivity-feed-previw {
		margin: $spacing-md;
		background: $backgroundColor;
		padding: $spacing-md;
	}

	.re-useractivity-feed-message {
		margin: $spacing-sm 0 0 $spacing-md;
	}

	.re-useractivity-feed-previw-message {
		margin: $spacing-sm 0px 5px;
	}

	.re-useractivity-comment-conatiner {
		padding: 0px 10px;
		border-left-width: 3px;
		border-style: solid;
		margin: 10px 15px;
		border-color: $backgroundColor;

		.re-useractivity-comment-conatiner-message {
			padding: 5px 0;
			margin: 5px 0;
		}
	}

	.re-useractivity-feed-link {
		text-align: right;
		display: block;
		color: $color-black;
		text-decoration: none;
	}
}