/*********************************** */
/* reusable classes					 */
/*********************************** */

// floats
.pull-left {
	float: left;
}

.pull-right {
	float: right;
}

// clearfix
.clearfix {
	&:before,
	&:after {
		content: "";
		display: table;
	}

	&:after {
		clear: both;
	}
}

// inline/block
.display-inline {
	display: inline;
}

.display-inline-block {
	display: inline-block;
}

.display-block,
.show {
	display: block;
}

// display-table
.display-table {
	display: table;
	height: 100%;
	width: 100%;
}

.display-table-cell {
	display: table-cell;
	height: 100%;
	vertical-align: middle;
}

// list styling
.list-unstyled {
	list-style: none;
	margin: 0;

	li {
		padding: 0;
	}
}

.list-inline {
	margin: 0;
	padding: 0;
	border: 0;
	overflow: hidden;

	li {
		list-style-image: none;
		list-style-type: none;
		margin-left: 0;
		display: inline-block;
		vertical-align: middle;
		white-space: nowrap;
	}
}

.list-inline li.hide {
	display: none;
}

.position-relative {
	position: relative;
}

// text element
.hide-text {
	font: 0 / 0 a;
	color: transparent;
	text-shadow: none;
	background-color: transparent;
	border: 0;
}

.margin-center {
	margin-left: auto;
	margin-right: auto;
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

.text-left {
	text-align: left;
}

.text-normal {
	font-weight: normal;
}

.text-semibold {
	font-weight: 600;
}

.text-bold {
	font-weight: 700;
}

.text-italic {
	font-style: italic;
}

.text-uppercase {
	text-transform: uppercase;
}

.text-lowercase {
	text-transform: lowercase;
}

.text-capitalize {
	text-transform: capitalize;
}

.text-xs {
    font-size: $font-size-xs;
}

.no-wrap {
    white-space: nowrap;
}

// visually hidden
.visually-hidden {
	@include visually-hidden;
}

[aria-hidden="true"] {
	display: none !important;
}

// margin override
.no-margin-bot {
	margin-bottom: 0;
}

.no-margin-top {
	margin-top: 0;
}

.no-margin-left {
	margin-left: 0;
}

.no-margin-right {
	margin-right: 0;
}

//media item
.mediaItem, .mediaItem-body {
	overflow: hidden;
	_overflow: visible;
	zoom: 1;
	word-wrap: break-word;
}

.mediaItem-body {
	flex: 1;
}

.mediaItem .mediaItem-media img,
.mediaItem img.mediaItem-media {
	display: block;

	&.hide {
		display: none;
	}
}

.mediaItem .mediaItem-media {
	float: left;
	margin-right: 20px;
}

.mediaItem.mediaItem-right .mediaItem-media {
	float: right;
	margin-left: 20px;
	margin-right: 0;
}

.mediaItem.mediaItem-verticalCenter {
	display: flex;
	align-items: center;

	.mediaItem-media {
		flex-shrink: 0;
	}
}

//veritcal centering
.re-verticalcenter {
	display: table-cell;
	vertical-align: middle;
	height: inherit;
	width: 10000px;
}

//dot separator
.re-dotseparator + .re-dotseparator:before {
	content: "\2002\00b7\2002";
	font-weight: $font-weight-bold;
}

// responsive hiding
.re-hide-sm-min {	
	@media #{$mediaquery-sm-min} {
		display: none !important;
	}
}
.re-hide-md-min {	
	@media #{$mediaquery-md-min} {
		display: none !important;
	}
}
.re-hide-lg-min {	
	@media #{$mediaquery-lg-min} {
		display: none !important;
	}
}

.re-hide-xxs-max {
	@media #{$mediaquery-xxs-max} {
		display: none !important;
	}
}

.re-hide-xs-max {	
	@media #{$mediaquery-xs-max} {
		display: none !important;
	}
}
.re-hide-sm-max {	
	@media #{$mediaquery-sm-max} {
		display: none !important;
	}
}
.re-hide-md-max {	
	@media #{$mediaquery-md-max} {
		display: none !important;
	}
}
.re-hide-nav-full {
	@media #{$mediaquery-nav-full-min} {
		display: none !important;
	}
}
.re-hide-nav-compact {
	@media #{$mediaquery-nav-compact-max} {
		display: none !important;
	}
}

.re-background-caution {
	@include background-caution;
	display: flex;
	align-items: center;
	justify-content: center;
	
	a {
	text-decoration: none;
	}
}
.re-background-urgent {
	@include background-urgent;
}
.re-no-pointer-event {
	pointer-events: none;
}

.re-overflow-hidden {
	overflow:hidden;
}

.re-error-boundary {
	padding-left: 4px;
}

.re-keyword-link {
	border-bottom: 2px dotted;
	cursor: pointer;
	white-space: nowrap;
	text-decoration: none;
	border-color: $color-primary;

	&:hover {
		text-decoration: none;
	}
}

.re-blur-text {
	color: transparent;
	text-shadow: 0 0 8px rgba(0,0,0,0.5);
	user-select: none;
}

.re-invisible {
	visibility: hidden;
}

.re-invite-user-popup {
	display: flex;
	flex-flow: column;

	.re-invite-user-popup {
		font-size: $font-size-lg;
	}

	button {
		flex: 50%
	}
}



.re-dot-spacer {
	display: inline;

	&:not(:first-child):before {
		content: '\2002\00b7\2002';
		font-weight: $font-weight-bold;
	}
}

.cursor-not-allowed {
	cursor: not-allowed;
}



/* Hide the Google Translate spinner */
.VIpgJd-ZVi9od-aZ2wEe-wOHMyf,
.VIpgJd-ZVi9od-aZ2wEe-OiiCO, .VIpgJd-ZVi9od-aZ2wEe {
    display: none !important;
    height: 0 !important;
}

.skiptranslate, #goog-gt-original-text, #goog-gt-vt {
    display: none !important;
    height: 0 !important;
}


