﻿@import "modules/variables";

.re-zillow-widget-container {
    display: flex;
    flex-flow: column;
    align-items: center;

    .re-zillow-widget-description {
        margin: $spacing-lg 0;
    }

    .re-zillow-widget-btn-color {
        background-color: $color-zillow;
        border-color: $color-zillow;

        &:hover {
            background-color: $color-black;
            border-color: $color-black;
        }
    }
}
