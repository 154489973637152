﻿@import "modules/variables.scss";
@import "modules/mixins.scss";

.re-sharewidget {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 650;

	&-position {
		pointer-events: none;
		position: absolute;

		&-top {
			left: 50%;
			top: 0;
		}

		&-bottom {
			left: 50%;
			top: 100%;
		}

		&-right {
			left: 0;
			top: 50%;
			width: 100%;
		}

		&-left {
			left: 0;
			top: 50%;
		}
	}

	&-content {
		pointer-events: all;
		position: relative;
		text-align: center;
		transform: translate3d(-10000px, 0, 0);
		width: 155px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		@media #{$mediaquery-nav-full-min} {
			width: 305px;
		}

		.re-btn-round-small {
			margin: 5px;
		}
		// must be the same as offset defined in re_share_widget_control.js
		$offset: 15px;

		.re-sharewidget-position-top & {
			transform: translate(-50%, -100%) translate(0, -$offset);
			@include ArrowBox2('down');
		}

		.re-sharewidget-position-bottom & {
			transform: translate(-50%, $offset);
			@include ArrowBox2('up');
		}

		.re-sharewidget-position-right & {
			left: calc(100% + #{$offset});
			transform: translate(0, -50%);
			@include ArrowBox2('left');
		}

		.re-sharewidget-position-left & {
			transform: translate(-100%, -50%) translate(-$offset, 0);
			@include ArrowBox2('right');
		}
	}

    .re-share-permalink {
        width: 100%;
    }

	.re-share-all-listings-checkbox {
		margin: 12px 0;
		padding: 6px 0;
        width: 100%;

		.re-share-all-listings-label {
			margin-bottom: 0;
		}
	}
}
