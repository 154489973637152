/******************************************* */
/* RESAAS profile widget					 */
/* style related to profile widget			 */
/******************************************* */

// legacy profile nav module
#profile_nav_module {
	.user-address {
		position: relative;
	}
}

// hiding customized branded profile page
[class*=custom-profile] {
	display: none;
}

// hide send referral button on listings
// ---------------------------------------------
.resaas-listings-wrapper {
	.referral-link {
		display: none;
	}
}

// badge styling
.badge-profile-container {
	position: relative;
	float: right;
}

.premium-badges-silver-medium {
    width: 45px;
	position: absolute;
	right: 0;
	height: 45px;
}

.globalelite-assigned-badges-medium{
	width: 45px;
	position: absolute;
	right: 3px;
	top: -2px;
	height: 45px;
}

// temporary: nest everything under .module-user-profile to avoid conflict with MainMenu.ascx. Kill .module-user-profile nesting when no longer needed
.module-user-profile {
	// profile picture
	// ---------------------------------------------
	.user-profile-pic-wrapper,
	.user-profile-pic {
		width: auto;
		text-align: center;
		border-radius: $border-radius-large;
	}

	// profile details
	// ---------------------------------------------
	.user-profile-details {
		background: #fff;
		padding: 12px;
		font-size: $font-size-sm;
		margin-top: 17px;
		border-radius: $border-radius-large;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		word-break: break-word;
	}

	.user-profile-details #firm-office-details,
	.user-profile-details #firm-branding-details {
		max-width: 100%;
	}

	.user-profile-officename {
		margin: 10px 0;
		color: #646464;
		font-weight: 700;
	}

	.profile-details-username {
		font-size: $font-size-lg;
		line-height: 24px;
		padding-right: $spacing-xl;
		word-break: break-all;
		a {
			color: #5f5f5f;
			word-break: break-word;
		}
	}

	.profile-details-address,
	.profile-details-additional-info {
		margin: 10px 0;
	}

	// additional info
	.profile-details-additional-info {
		li {
			line-height: 18px;
			margin: 0 0 8px;

			&:last-child {
				margin: 0;
			}
		}

		a {
			width: 70%;
		}
	}

	.user-info-mobile-number,
	.user-info-office-number {
		a {
			color: $baseColor;
		}
	}

	.user-info-firm {
		margin: 0 10px 0 0;
	}


	// social tools
	// ---------------------------------------------
	.user-profile-social-tools {
		background: $rGreyLightest;
		box-shadow: inset 0 3px 6px -6px rgba(0, 0, 0, 0.75);
		padding: 10px;
		border-radius: $border-radius-large;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}

	.social-tool-item {
		float: left;
		width: 33%;

		opacity: 1;

		transition: opacity 0.2s linear;

		&:hover {
			opacity: 0.75;
		}

		&:last-child {
			margin: 0;
		}

		.fa {
			display: block;
			position: relative;
			top: 10px;
		}
	}

	.social-tool {
		border-radius: 50%;

		color: #fff;
		display: block;
		height: 40px;
		margin: 0 auto;
		width: 40px;

		&:hover {
			text-decoration: none;
		}

		.re-social-icon{
			position: relative;
			top: 12px;
		}
	}

	.social-tool-twitter {
		background: $color-twitter;
	}

	.social-tool-facebook {
		background: $color-facebook;
	}

	.social-tool-linkedin {
		background: $color-linkedin;
	}

	.icon-linkedIn {
		.fa {
			top: 8px;
		}
	}
}

// agent discovery lightbox
// ---------------------------------------------
.profile-city-state {
	position: relative;
	z-index: 99;
}

.profile-agents-discovery {
	background: #fff;
	border: 2px solid #dedede;

	border-radius: $border-radius-small;

	display: none;
	position: absolute;
	width: 195px;

	&:after,
	&:before {
		top: 100%;
		left: 25%;
		border: solid transparent;
		content: "";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
	}

	&:after {
		border-color: rgba(222, 222, 222, 0);
		border-top-color: #dedede;
		border-width: 8px;
		margin-left: -8px;
	}

	&:before {
		border-color: rgba(222, 222, 222, 0);
		border-top-color: #dedede;
		border-width: 11px;
		margin-left: -11px;
	}

	p {
		padding: 10px;
		position: relative;
	}

	.discovery-city,
	.discovery-state {
		font-weight: 700;
	}

	.agents-discover-bgimage {
		background: url("/assets/home/images/questions/bg-questions-cityscape.jpg") no-repeat center center;
		background-size: 180%;
		height: 100%;
		opacity: 0.4;
		position: absolute;
		width: 100%;
	}
}

// hide agents discovery on some pages
.resaas-listings-wrapper,
.resaas-question-profile-wrapper {
	.profile-agents-discovery {
		display: none !important;
	}
}

// no custom logo
.re-no-logo {
	width: 195px;
	height: 125px;
	border: 3px dashed $color-grey-light;
	border-radius: 10px;
	margin: $spacing-lg auto;
	display: table;

	.re-no-logo-content {
		display: table-cell;
		vertical-align: middle;
		text-align: center;
	}
}