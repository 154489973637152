@import "variables";

/**************** */
/* RESAAS mixins */
/**************** */

@import "modules/variables";

// custom mixins
// ---------------------------------------------

// buttons
@mixin btnColor($border, $color, $bgStart, $bgEnds, $gradient: true) {
	@if $gradient {
		background: $bgStart;

		$experimental-support-for-svg: true;

		background-image: linear-gradient($bgStart, $bgEnds);

		border: $border;
		color: $color;

		&:hover {
			background: $bgEnds;

			$experimental-support-for-svg: true;

			background-image: linear-gradient($bgEnds, $bgStart);
		}
	}
	@else {
		background: $bgStart;
		border: $border;
		color: $color;

		&:hover {
			background: $bgEnds;
		}
	}
}

// legacy buttons
@mixin button($border, $color, $font-size, $padding, $width, $background, $gradient: false, $gradient-top: #000, $gradient-bottom: #fff) {
	box-sizing: border-box;

	border-radius: $border-radius-small;

	background: $background;

	$experimental-support-for-svg: true;

	@if $gradient == "true" {
		background-image: linear-gradient($gradient-top, $gradient-bottom);
	}

	border: $border;
	color: $color;
	display: block;
	font-size: $font-size;
	letter-spacing: 1px;
	padding: $padding;
	text-align: center;
	text-decoration: none;
	width: $width;

	&:hover,
	&:active {
		background: $background;
	}
}

// ui activation blocker
@mixin UIActivationBlocker($width: 100%, $float: right, $margin-top: -26px, $height: 40px, $display: none, $position: relative, $backgroundColor: #fff) {
	width: $width;
	display: $display;
	margin-top: $margin-top;

	opacity: 0.86;

	float: $float;
	position: $position;
	height: $height;
	background: $backgroundColor url(/assets/common/images/spinner20xmodern.gif) center center no-repeat;

	&.show {
		display: inline-block;
	}
}

// border with arrow
@mixin ArrowBox($background: #fff, $border: 1px solid #ccc, $afterBorderWidth: 0, $afterMarginRight: 0, $afterMarginLeft: 0, $beforeBorderWidth: 0, $beforeMarginRight: 0, $beforeMarginLeft: 0) {
	background: $background;
	border: $border;

	&:after, &:before {
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
	}

	&:after {
		border-color: rgba(255, 255, 255, 0);
		border-bottom-color: #fff;
		border-width: $afterBorderWidth;
		margin-right: $afterMarginRight;
		margin-left: $afterMarginLeft;
	}

	&:before {
		border-color: rgba(204, 204, 204, 0);
		border-bottom-color: #ccc;
		border-width: $beforeBorderWidth;
		margin-left: $beforeMarginLeft;
		margin-right: $beforeMarginRight;
	}
}

@mixin ArrowBox2($arrow-direction: 'down', $background-color: #fff, $border-size: $border-size, $border-color: $border-color, $arrow-size: 10px) {
	border: $border-size solid $border-color;

	&::after, &::before {
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
	}

	&::before {
		border: $arrow-size solid transparent;
	}

	&::after {
		border: $arrow-size - ($border-size * 2) solid transparent;
	}

	@if $arrow-direction == 'down' {
		&::before,
		&::after {
			top: 100%;
		}

		&::before {
			left: calc(50% - #{$arrow-size});
			border-top-color: $border-color;
		}

		&::after {
			left: calc(50% - #{$arrow-size} + #{$border-size});
			border-top-color: $background-color;
		}
	}

	@if $arrow-direction == 'up' {
		&::before,
		&::after {
			bottom: 100%;
		}

		&::before {
			left: calc(50% - #{$arrow-size});
			border-bottom-color: $border-color;
		}

		&::after {
			left: calc(50% - #{$arrow-size} + #{$border-size});
			border-bottom-color: $background-color;
		}
	}

	@if $arrow-direction == 'left' {
		&::before,
		&::after {
			right: 100%;
		}

		&::before {
			top: calc(50% - #{$arrow-size});
			border-right-color: $border-color;
		}

		&::after {
			top: calc(50% - #{$arrow-size} + #{$border-size});
			border-right-color: $background-color;
		}
	}

	@if $arrow-direction == 'right' {
		&::before,
		&::after {
			left: 100%;
		}

		&::before {
			top: calc(50% - #{$arrow-size});
			border-left-color: $border-color;
		}

		&::after {
			top: calc(50% - #{$arrow-size} + #{$border-size});
			border-left-color: $background-color;
		}
	}
}

// arrow
@mixin ArrowDown($aftercolor: #fff, $beforecolor: #ccc, $afterBorderWidth: 0, $afterMarginRight: 0, $afterMarginLeft: 0, $beforeBorderWidth: 0, $beforeMarginRight: 0, $beforeMarginLeft: 0) {
	&:after, &:before {
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
	}

	&:after {
		border-top-color: $aftercolor;
		border-width: $afterBorderWidth;
		margin-right: $afterMarginRight;
		margin-left: $afterMarginLeft;
	}

	&:before {
		border-top-color: $beforecolor;
		border-width: $beforeBorderWidth;
		margin-left: $beforeMarginLeft;
		margin-right: $beforeMarginRight;
	}
}

// panel dropdown
@mixin PanelDropdown($background: #fff, $top: 0, $left: 0, $right: 0, $z-index: 999) {
	background: $background;
	display: none;
	left: $left;
	position: absolute;
	right: $right;
	top: $top;
	z-index: $z-index;
}

// custom scrollbar
@mixin Scrollbar($foreground-color, $background-color) {
	// For Chrome and Safari
	&::-webkit-scrollbar {
		width: 15px;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		border: 4px solid $background-color;
		background-clip: padding-box;
		background-color: $foreground-color;
	}

	// For IE
	scrollbar-face-color: $foreground-color;
	scrollbar-track-color: $background-color;
	scrollbar-base-color: $background-color;
	scrollbar-arrow-color: $foreground-color;
}

@mixin UIOverlay($background: #000, $opacity: 0.7, $zindex: 1000) {
	background: $background;
	opacity: $opacity;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
	filter: alpha(opacity = 50);
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
	z-index: $zindex;
}

/* Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */

@mixin visually-hidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

@mixin clear-fix {
	&:before,
	&:after {
		content: "";
		display: table;
	}

	&:after {
		clear: both;
	}
}

@mixin background-caution() {
	background: $color-yellow;
	color: $color-black;
}

@mixin background-urgent() {
	background: $color-error;
	color: $color-white;
}


// mixin for Navbar

// Vertically align children
@mixin vertical-align() {
	font-size: 0;

	&:after {
		content: '';
		display: inline-block;
		height: 100%;
		vertical-align: middle;
    }
	
}

// Vertically align selector
@mixin vertical-align-translate {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

@mixin nav-transition($duration: 0.2s) {
	transition: all $duration ease;
	-webkit-backface-visibility: none;
	transition-style: preserve-3d;
}

@mixin selected-state($selected-bg-color: $color-primary, $selected-color: $rWhite) {
	background: $selected-bg-color;
	color: $selected-color;
}

// Font Awesome
@mixin fa-icon($fontSize: null) {
	font: $font-awesome;
	@if $fontSize != "" {
		font-size: $fontSize;
	}
}

// Border
@mixin border($border-side: null) {
	@if($border-side == "") {
		border: $border;
	} @else {
		border-#{$border-side}: $border;
	}
}

@mixin container($padding: $spacing-lg) {
	padding: $padding;
}

@mixin outerContainer($background: $color-white) {
	background: $background;
	border-radius: $border-radius-large;
	box-shadow: $box-shadow-outerContainer;
}

@mixin flexSpaceBetween($flex-wrap: nowrap) {
	display: flex;
	justify-content: space-between;
	flex-wrap: $flex-wrap;
}

@mixin textEllipsis() {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}



// COMPASS REPLACEMENTS

// Indicates the direction you prefer to move your text
// when hiding it.
//
// `left` is more robust, especially in older browsers.
// `right` seems have better runtime performance.
$hide-text-direction: left !default;

// Hides html text and replaces it with an image.
// If you use this on an inline element, you will need to change the display to block or inline-block.
// Also, if the size of the image differs significantly from the font size, you'll need to set the width and/or height.
//
// Parameters:
//
// * `img` -- the relative path from the project image directory to the image, or a url literal.
// * `x` -- the x position of the background image.
// * `y` -- the y position of the background image.
@mixin replace-text($img, $x: 50%, $y: 50%) {
  @include hide-text;
  background: {
    @if is-url($img) {
      image: url($img);
    } @else {
      image: url($img);
    }
    repeat: no-repeat;
    position: $x $y;
  };
}

// Like the `replace-text` mixin, but also sets the width
// and height of the element according the dimensions of the image.
//
// If you set `$inline` to true, then an inline image (data uri) will be used.
@mixin replace-text-with-dimensions($img, $x: 50%, $y: 50%, $inline: false) {
  @include replace-text(if($inline, inline-image($img), $img), $x, $y);
  width: image-width($img);
  height: image-height($img);
}

// Hides text in an element so you can see the background.
//
// The direction indicates how the text should be moved out of view.
//
// See `$hide-text-direction` for more information and to set this globally
// for your application.
@mixin hide-text($direction: $hide-text-direction) {
  @if $direction == left {
    $approximate-em-value: 12px / 1em;
    $wider-than-any-screen: -9999em;
    text-indent: $wider-than-any-screen * $approximate-em-value;
    overflow: hidden;
    text-align: left;
  } @else {
    // slightly wider than the box prevents issues with inline-block elements
    text-indent: 110%;
    white-space: nowrap;
    overflow: hidden;
  }
}

// Hides text in an element by squishing the text into oblivion.
// Use this if you need to hide text contained in an inline element
// but still have it read by a screen reader.
@mixin squish-text {
  font: 0/0 serif;
  text-shadow: none;
  color: transparent;
}

// Horizontal list layout module.
//
// Easy mode using simple descendant li selectors:
//
//   ul.nav
//     +horizontal-list
//
// Advanced mode:
// If you need to target the list items using a different selector then use
// +horizontal-list-container on your ul/ol and +horizontal-list-item on your li.
// This may help when working on layouts involving nested lists. For example:
//
//   ul.nav
//     +horizontal-list-container
//     > li
//       +horizontal-list-item


// Can be mixed into any selector that target a ul or ol that is meant
// to have a horizontal layout. Used to implement +horizontal-list.
@mixin horizontal-list-container {
  overflow: hidden;
  }
// Can be mixed into any li selector that is meant to participate in a horizontal layout.
// Used to implement +horizontal-list.
//
// :last-child is not fully supported
// see http://www.quirksmode.org/css/contents.html#t29 for the support matrix
// 
// IE8 ignores rules that are included on the same line as :last-child
// see http://www.richardscarrott.co.uk/posts/view/ie8-last-child-bug for details
//
// Setting `$padding` to `false` disables the padding between list elements
@mixin horizontal-list-item($padding: 4px, $direction: left) {
 list-style: none;
  white-space: nowrap;
float: $direction;
  @if $padding {
    padding: {
      left: $padding;
      right: $padding;
    }
    &:first-child, &.first { padding-#{$direction}: 0; }
    &:last-child { padding-#{opposite-position($direction)}: 0; }
    &.last { padding-#{opposite-position($direction)}: 0; }
  }
}

// A list(ol,ul) that is layed out such that the elements are floated left and won't wrap.
// This is not an inline list.
//
// Setting `$padding` to `false` disables the padding between list elements
@mixin horizontal-list($padding: 4px, $direction: left) {
  @include horizontal-list-container;

  li {
    @include horizontal-list-item($padding, $direction);
	}
  }


// compass sprites

$sprite-default-size: 32px !default;

$sprite-default-margin: 0px !default;

$sprite-image-default-width: $sprite-default-size !default;

$sprite-image-default-height: $sprite-default-size !default;

// Sets all the rules for a sprite from a given sprite image to show just one of the sprites.
// To reduce duplication use a sprite-bg mixin for common properties and a sprite-select mixin for positioning.
@mixin sprite-img($img, $col, $row: 1, $width: $sprite-image-default-width, $height: $sprite-image-default-height, $margin: $sprite-default-margin) {
	@include sprite-background($img, $width, $height);
	@include sprite-position($col, $row, $width, $height, $margin);
}

// Sets rules common for all sprites, assumes you want a square, but allows a rectangular region.
@mixin sprite-background($img, $width: $sprite-default-size, $height: $width) {
	@include sprite-background-rectangle($img, $width, $height);
}

// Sets rules common for all sprites, assumes a rectangular region.
@mixin sprite-background-rectangle($img, $width: $sprite-image-default-width, $height: $sprite-image-default-height) {
	background: url($img) no-repeat;
	width: $width;
	height: $height;
	overflow: hidden;
}

// Allows horizontal sprite positioning optimized for a single row of sprites.
@mixin sprite-column($col, $width: $sprite-image-default-width, $margin: $sprite-default-margin) {
	@include sprite-position($col, 1, $width, 0px, $margin);
}

// Allows vertical sprite positioning optimized for a single column of sprites.
@mixin sprite-row($row, $height: $sprite-image-default-height, $margin: $sprite-default-margin) {
	@include sprite-position(1, $row, 0px, $height, $margin);
}

// Allows vertical and horizontal sprite positioning from a grid of equal dimensioned sprites.
@mixin sprite-position($col, $row: 1, $width: $sprite-image-default-width, $height: $sprite-image-default-height, $margin: $sprite-default-margin) {
	$x: ($col - 1) * -$width - ($col - 1) * $margin;
	$y: ($row - 1) * -$height - ($row - 1) * $margin;
	background-position: $x $y;
}



// Similar to 'sprite-replace-text-with-dimensions' but does not autmaticly set the demensions
@mixin sprite-replace-text ($map, $sprite, $dimensions: false, $offset-x: 0, $offset-y: 0) {
	@include hide-text;
	@include sprite($map, $sprite, $dimensions, $offset-x, $offset-y);
	background-image: $map;
	background-repeat: no-repeat;
}

// Similar to 'replace-text-with-dimensions' but with sprites
// To use, create your sprite and then pass it in the `$map` param
// The name of the image in the sprite folder should be `$img-name`
@mixin sprite-replace-text-with-dimensions ($map, $sprite, $offset-x: 0, $offset-y: 0) {
	@include sprite-replace-text ($map, $sprite, true, $offset-x, $offset-y);
}