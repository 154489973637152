@import "modules/variables";
@import "modules/mixins_buttons";

/*********************************** */
/* RESAAS buttons					 */
/*********************************** */

.btn2, .re-btn {
    @include btn2;

    .submitting &,
    &.submitting {
        @include submitting;
        @include submittingAfter;
    }

    &.re-icon:not(.re-btn-icononly):not(.re-btn-round)::before {
        margin-right: $spacing-sm;
    }
}

.re-btn-large {
    @include btn2-large;
}

.re-btn-small {
    @include re-btn-small;
}

.re-btn-icononly {
    min-width: 0;
}

.re-btn-round {
    border-radius: 100%;
    width: 40px;
    height: 40px;
    padding: 0;
    min-width: 0;
    vertical-align: middle;

    &.re-icon:before {
        font: $font-awesome;
        font-size: 18px;
        line-height: 40px; // vertically aligning icon
        margin-right: 0;
    }

    &:focus {
        outline: none;
    }
}

.re-btn-round-small {
    width: 35px;
    height: 35px;

    &.re-icon:before {
        font-size: $font-size-md;
        line-height: 35px; // vertically aligning icon
    }
}

.re-btn-round-xs {
    width: 20px;
    height: 20px;

    &.re-icon:before {
        font-size: $font-size-sm;
        line-height: 20px; // vertically aligning icon
    }
}

.re-btn-round-large {
    width: 50px;
    height: 50px;

    &.re-icon:before {
        font-size: $font-size-lg;
        line-height: 50px; // vertically aligning icon
    }
}

.re-btn-round-xl {
    width: 120px;
    height: 120px;

    &.re-icon:before {
        font-size: 68px;
        line-height: 120px; // vertically aligning icon
    }
}

.re-btn-red {
    @include btn2-color($btnRedEnd, $color-white, #aa212c, $color-white);

    &:active {
        background: $btnRedEnd;
    }
}

.re-btn-black {
    @include btn2-color($color-black, $color-white, $color-white, $color-black);

    border: 1px solid $color-black;
    &:hover,
    &:focus {
        border-color: $color-black;
        background: transparent;
    }
}

.re-btn-white {
    @include btn2-color($color-white, $color-black, $color-black, $color-white);

    border: 1px solid $color-black;
}

.re-agenttile-dark {
    .re-btn-white {
        border: 1px solid $color-white;

        &:hover {
            background: $color-black;
        }
    }
}

.re-btn-white-border {
    border: 1px solid $color-white !important;
}

.re-btn-secondary {
    @include btn2-color($color-secondary, $color-white, $color-black, $color-white);
}

.re-btn-grey {
    @include btn2-color($rGreyDark, $color-white, $color-black, $color-white);
}

.re-btn-green {
    @include btn2-color($color-green, $color-white, $color-black, $color-white);
}

.re-btn-border-white:hover {
    border: 1px solid $color-white;
}

.re-btn-dark {
    @include btn2-color(#3c4e61, $color-white, $color-white, #3c4e61);

    &:hover,
    &:focus {
        border-color: #3c4e61;
        background: transparent;
    }
}

.re-btn-gold {
    @include btn2-color($color-gold, #1A1919, #1A1919, $color-gold);
}

.re-btn-yellow {
    @include btn2-color($color-yellow, $color-black, $color-black, $color-white);
}

.re-btn-cancel {
    @include btn2-color(transparent, $color-black, transparent, $color-black);
    padding-left: 0;
    min-width: auto;
    padding-right: 5px;

    &:hover, &:focus {
        outline: 0;
        text-decoration: underline;
        color: $color-primary;
    }
}

.re-btn-plain {
    @include btn2-color(transparent, $color-black, transparent, $color-primary);
    padding-left: 0;
    padding-right: 0;

    &.re-btn-icononly {
        padding-left: 8px;
        padding-right: 8px;
    }

    &.re-icon:hover,
    &.re-icon:focus,
    &.re-icon:active {
        text-decoration: underline;
    }
}

.re-btn-subtle {
    @include btn2-color(transparent, $color-grey, $color-grey, $color-white);
    border-color: $color-grey;
}

.re-btn-subtle-white {
    @include btn2-color(transparent, $color-white, $color-black, $color-white);
    border-color: $color-white;
}

.re-btn-full-width {
    @include btn2-full-width;
}

.btn2-icon {
    @include btn2-icon;
}

.re-btn-disabled, .re-btn[disabled] {
    @include btn2-color($color-grey-xtralight, $color-grey, $color-grey-light, $color-grey);
    pointer-events: none;
    border-color: $color-grey-light;

    &:hover {
        cursor: default;
    }
}

.re-btn-grey-disabled {
    @include btn2-color(#585858, $color-white, #585858, $color-white);
    pointer-events: none;

    &:hover {
        cursor: default;
    }
}

.re-btn-red.btn-subtle {
    border: 1px solid $btnRedStart;
    color: $btnRedStart;

    &:hover {
        border-color: $btnRedEnd;
        color: $btnRedEnd;
    }
}

.re-btn-nospacing {
    margin: 0;
}

// social media buttons
.re-btn.re-icon-facebook {
    background-color: $color-facebook;
    border-color: $color-facebook;

    &:hover {
        background-color: $color-black;
        border-color: $color-black;
    }
}

.re-btn.re-icon-linkedin {
    background-color: $color-linkedin;
    border-color: $color-linkedin;

    &:hover {
        background-color: $color-black;
        border-color: $color-black;
    }
}

.re-btn.re-icon-twitter {
    background-color: $color-twitter;
    border-color: $color-twitter;

    &:hover {
        background-color: $color-black;
        border-color: $color-black;
    }
}

.re-btn.re-icon-pinterest {
    background-color: $color-pinterest;
    border-color: $color-pinterest;

    &:hover {
        background-color: $color-black;
        border-color: $color-black;
    }
}

.re-btn.re-icon-instagram {
    background-color: $color-instagram;
    border-color: $color-instagram;

    &:hover {
        background-color: $color-black;
        border-color: $color-black;
    }
}

.re-btn.re-icon-globe {
    background-color: $color-primary;
    border-color: $color-primary;

    &:hover {
        background-color: $color-black;
        border-color: $color-black;
    }
}

.re-inlinebutton {
    background: transparent;
    color: inherit;
    display: inline;
    margin: 0;
    padding: 0;

    &.re-icon::before {
        margin-right: 0.5em;
    }

    &:hover {
        text-decoration: underline;
    }
}

