/*********************************** */
/* RESAAS tooltip					 */
/*********************************** */

.tooltip {
	position: absolute;
	z-index: 1;
	display: block;
	visibility: visible;
	font-size: $font-size-xs;
	line-height: 1.4;

	opacity: 0;
}

.tooltip.in {
	opacity: 1;
}

.tooltip.top {
	margin-top: -3px;
	padding: 5px 0;
}

.tooltip.right {
	margin-left: 3px;
	padding: 0 5px;
}

.tooltip.bottom {
	margin-top: 3px;
	padding: 5px 0;
}

.tooltip.left {
	margin-left: -3px;
	padding: 0 5px;
}

.tooltip-inner {
	max-width: 200px;
	padding: 8px 10px;
	color: #888;
	text-align: center;
	text-decoration: none;
	background-color: #fcfad7;

	border-radius: $border-radius-large;
}

.tooltip-arrow {
	position: absolute;
	width: 0;
	height: 0;
	border-color: transparent;
	border-style: solid;
}

.tooltip.top .tooltip-arrow {
	bottom: 0;
	left: 50%;
	margin-left: -5px;
	border-width: 5px 5px 0;
	border-top-color: #fcfad7;
}

.tooltip.top-left .tooltip-arrow {
	bottom: 0;
	left: 5px;
	border-width: 5px 5px 0;
	border-top-color: #fcfad7;
}

.tooltip.top-right .tooltip-arrow {
	bottom: 0;
	right: 5px;
	border-width: 5px 5px 0;
	border-top-color: #fcfad7;
}

.tooltip.right .tooltip-arrow {
	top: 50%;
	left: 0;
	margin-top: -5px;
	border-width: 5px 5px 5px 0;
	border-right-color: #fcfad7;
}

.tooltip.left .tooltip-arrow {
	top: 50%;
	right: 0;
	margin-top: -5px;
	border-width: 5px 0 5px 5px;
	border-left-color: #fcfad7;
}

.tooltip.bottom .tooltip-arrow {
	top: 0;
	left: 50%;
	margin-left: -5px;
	border-width: 0 5px 5px;
	border-bottom-color: #fcfad7;
}

.tooltip.bottom-left .tooltip-arrow {
	top: 0;
	left: 5px;
	border-width: 0 5px 5px;
	border-bottom-color: #fcfad7;
}

.tooltip.bottom-right .tooltip-arrow {
	top: 0;
	right: 5px;
	border-width: 0 5px 5px;
	border-bottom-color: #fcfad7;
}
