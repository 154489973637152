/*********************************** */
/* forms elements					 */
/*********************************** */

.required-fields {
	label {
		display: none;
	}
}

fieldset,
.required-fields {
	.line {
		margin: 15px 0;
		overflow: visible;
	}
}

textarea {
	margin: 0;
	padding: 0;
	resize: none;
}

::-webkit-input-placeholder {
	color: #9c9c9c;
}

:-moz-placeholder {
	color: #9c9c9c;
	opacity: 1;
}

::-moz-placeholder {
	color: #9c9c9c;
	opacity: 1;
}

:-ms-input-placeholder {
	color: #9c9c9c;
}

.watermark {
	color: #9c9c9c;
}

input {
	&.valid,
	&.error {
		background-color: #fff;
	}

	&.valid {
		background-position: 95% -830px;
	}

	&.error {
		background-position: 100% -800px;
	}
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.rs-message {
	box-sizing: border-box;

	padding: 0;
	*zoom: 1;

	&.error,
	&.success {
		margin: 15px 0;
		padding: 5px 10px;
	}
}

.input-text,
select {
	border: 1px solid #ccc;

	border-radius: $border-radius-small;

	box-shadow: none;

	box-sizing: border-box;

	padding: 10px;
	width: 100%;

	transition: border 0.2s linear, box-shadow 0.2s linear;

	&:focus {
		box-shadow: inset 0 0 1px rgba(9, 97, 168, 0.075), 0 0 8px rgba(9, 97, 168, 0.4);

		border-color: rgba(9, 97, 168, 0.8);
		outline: 0;
	}

	&:disabled {
		background: $color-grey-light;
		border-color: $color-grey;
	}
}

.input-textarea {
	border-radius: $border-radius-small;

	box-shadow: none;

	box-sizing: border-box;

	height: 120px !important;
	padding: 10px;
	width: 100%;
}

.select-small {
	width: 80px;
}
