@import "modules/variables.scss";

.re-feedssearch-searchbar.formV2 {
	overflow: hidden;
	margin: 15px 0 5px;
	position: relative;
	max-width: 634px;

	label {
		visibility: hidden;
		position: absolute;
		z-index: -1;
	}

	.formV2-inputSelectButtonCombo {
		margin-bottom: 10px;
		@include outerContainer(transparent);

		select {
			background-color: lighten($color-grey-xtralight, 2%);
		}

		input {
			padding-right: 30px;
		}

		input, select, btn {
			margin: 0;
		}
	}
}

.re-feedssearch-searchbtn{
	background-color: $color-white;
	border-radius: 3px;
	border: 1px solid $color-grey-light;
	color: $color-primary;
	padding: 0 10px;
	box-sizing: border-box;
	height: 48px;
	width: 48px;
}

.re-feedssearch-title {
	margin-bottom: 15px;
	margin-top: 15px;
	max-width: 634px;
	border-bottom: 1px solid $color-grey-light;
	@include flexSpaceBetween();
}

.re-feedssearch-title-text {
	font-size: $font-size-md;
	align-self: center;
}

.re-feedssearch-returntofeed {
	align-self: center;
	cursor: pointer;
}

.re-feedssearch-noresults {
	text-align: center;
}

.re-feedssearch-no-results-primary-message {
	font-size: $font-size-md;
	font-weight: $font-weight-bold;
}

.re-usersearch-no-results-secondary-message {
	font-weight: $font-weight-light;
}
