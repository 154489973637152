//
// Mixins
// --------------------------------------------------
@mixin clear-fix {
	&:before,
	&:after {
		content: "";
		display: table;
	}

	&:after {
		clear: both;
	}
}

@mixin make-grid-columns($i: 1, $list: ".#{$grid-prefix}-col-xs-#{$i}, .#{$grid-prefix}-col-sm-#{$i}, .#{$grid-prefix}-col-md-#{$i}, .#{$grid-prefix}-col-lg-#{$i}") {
	@for $i from 1 + 1 through $grid-columns {
		$list: "#{$list}, .#{$grid-prefix}-col-xs-#{$i}, .#{$grid-prefix}-col-sm-#{$i}, .#{$grid-prefix}-col-md-#{$i}, .#{$grid-prefix}-col-lg-#{$i}";
	}

	#{$list} {
		position: relative;

		// Prevent columns from collapsing when empty
		min-height: 1px;

		// Inner gutter via padding
		padding-left: $grid-gutter-width / 2;
		padding-right: $grid-gutter-width / 2;
	}
}

@mixin float-grid-columns($class, $i: 1, $list: ".#{$grid-prefix}-col-#{$class}-#{$i}") {
	@for $i from 1 + 1 through $grid-columns {
		$list: "#{$list}, .#{$grid-prefix}-col-#{$class}-#{$i}";
	}

	#{$list} {
		float: left;
	}
}

@mixin calc-grid-column($index, $class, $type) {
	@if $type == width and $index > 0 {
		.#{$grid-prefix}-col-#{$class}-#{$index} {
			width: percentage($index / $grid-columns);
		}
	}

	@if $type == push and $index > 0 {
		.#{$grid-prefix}-col-#{$class}-push-#{$index} {
			left: percentage($index / $grid-columns);
		}
	}

	@if $type == push and $index == 0 {
		.#{$grid-prefix}-col-#{$class}-push-0 {
			left: auto;
		}
	}

	@if $type == pull and $index > 0 {
		.#{$grid-prefix}-col-#{$class}-pull-#{$index} {
			right: percentage($index / $grid-columns);
		}
	}

	@if $type == pull and $index == 0 {
		.#{$grid-prefix}-col-#{$class}-pull-0 {
			right: auto;
		}
	}

	@if $type == offset {
		.#{$grid-prefix}-col-#{$class}-offset-#{$index} {
			margin-left: percentage($index / $grid-columns);
		}
	}
}

@mixin loop-grid-columns($columns, $class, $type) {
	@for $i from 0 through $columns {
		@include calc-grid-column($i, $class, $type);
	}
}

// Create grid for specific class
@mixin make-grid($class) {
	@include float-grid-columns($class);

	@include loop-grid-columns($grid-columns, $class, width);

	@include loop-grid-columns($grid-columns, $class, pull);

	@include loop-grid-columns($grid-columns, $class, push);

	@include loop-grid-columns($grid-columns, $class, offset);
}

// Grid system
//
// Generate semantic grid columns with these mixins.

// Centered container element
@mixin make-container($gutter: $grid-gutter-width) {
	margin-right: auto;
	margin-left: auto;
	padding-left: $gutter / 2;
	padding-right: $gutter / 2;

	@include clear-fix;
}

// Creates a wrapper for a series of columns
@mixin make-row($gutter: $grid-gutter-width) {
	margin-left: $gutter * -1;
	margin-right: $gutter * -1;

	@include clear-fix;
}

@mixin make-row-full-bleed($gutter: $grid-gutter-width) {
	margin-left: $gutter * -1.5;
	margin-right: $gutter * -1.5;
}

// Generate the extra small columns
@mixin make-xs-column($columns, $gutter: $grid-gutter-width) {
	min-height: 1px;
	padding-left: $gutter / 2;
	padding-right: $gutter / 2;
	position: relative;
	float: left;
	width: percentage($columns / $grid-columns);
}

@mixin make-xs-column-offset($columns) {
	@media (max-width: $screen-xs-max) {
		margin-left: percentage($columns / $grid-columns);
	}
}

@mixin make-xs-column-push($columns) {
	@media (max-width: $screen-xs-max) {
		left: percentage($columns / $grid-columns);
	}
}

@mixin make-xs-column-pull($columns) {
	@media (max-width: $screen-xs-max) {
		right: percentage($columns / $grid-columns);
	}
}

// Generate the small columns
@mixin make-sm-column($columns, $gutter: $grid-gutter-width) {
	min-height: 1px;
	padding-left: $gutter / 2;
	padding-right: $gutter / 2;
	position: relative;

	@media (min-width: $screen-sm-min) {
		float: left;
		width: percentage($columns / $grid-columns);
	}
}

@mixin make-sm-column-offset($columns) {
	@media (min-width: $screen-sm-min) {
		margin-left: percentage($columns / $grid-columns);
	}
}

@mixin make-sm-column-push($columns) {
	@media (min-width: $screen-sm-min) {
		left: percentage($columns / $grid-columns);
	}
}

@mixin make-sm-column-pull($columns) {
	@media (min-width: $screen-sm-min) {
		right: percentage($columns / $grid-columns);
	}
}

// Generate the medium columns
@mixin make-md-column($columns, $gutter: $grid-gutter-width) {
	min-height: 1px;
	padding-left: $gutter / 2;
	padding-right: $gutter / 2;
	position: relative;

	@media (min-width: $screen-md-min) {
		float: left;
		width: percentage($columns / $grid-columns);
	}
}

@mixin make-md-column-offset($columns) {
	@media (min-width: $screen-md-min) {
		margin-left: percentage($columns / $grid-columns);
	}
}

@mixin make-md-column-push($columns) {
	@media (min-width: $screen-md-min) {
		left: percentage($columns / $grid-columns);
	}
}

@mixin make-md-column-pull($columns) {
	@media (min-width: $screen-md-min) {
		right: percentage($columns / $grid-columns);
	}
}

// Generate the large columns
@mixin make-lg-column($columns, $gutter: $grid-gutter-width) {
	min-height: 1px;
	padding-left: $gutter / 2;
	padding-right: $gutter / 2;
	position: relative;

	@media (min-width: $screen-lg-min) {
		float: left;
		width: percentage($columns / $grid-columns);
	}
}

@mixin make-lg-column-offset($columns) {
	margin-left: percentage($columns / $grid-columns);
}

@mixin make-lg-column-push($columns) {
	left: percentage($columns / $grid-columns);
}

@mixin make-lg-column-pull($columns) {
	right: percentage($columns / $grid-columns);
}

//
// Grid system
// --------------------------------------------------

// Container
.#{$grid-prefix}-container {
	@include make-container;
}

//TODO box-sizing keeps getting wiped out when i put it in the mixin
//no idea why, works here but makes more sense there
*[class*='#{$grid-prefix}-col-'] {
	box-sizing: border-box;
}

// Row
.#{$grid-prefix}-row {
	@include make-row;
}

.#{$grid-prefix}-row-full-bleed {
	@include make-row-full-bleed;
}

@include make-grid-columns;

@include make-grid(xs);

@media (min-width: $screen-sm-min) {
	@include make-grid(sm);
}

@media (min-width: $screen-md-min) {
	@include make-grid(md);
}

@media (min-width: $screen-lg-min) {
	@include make-grid(lg);
}

//cascading override to lg if no media queries (default to desktop)
.no-mediaqueries {
	@include make-grid(sm);

	@include make-grid(md);

	@include make-grid(lg);
}
