@import "ui_helpers";

// Still being used in the legacy parts
.input_skin_1 {
	@include btnflexy-v2(100px);
}

.flagvisibility {
	display: none;
}

.btn-resaas {
	display: inline-block;
	border-radius: 5px;
	border: 1px solid #000;
	background: #000;
	color: #fff;
	padding: 7px 35px 7px 10px;
	position: relative;
	font-weight: bold;
	font-size: $font-size-xs;

	.arrow {
		position: absolute;
		right: 4px;
		top: 4px;
		width: 21px;
		height: 21px;
		background: transparent url(/assets/home/images/ui/btn-resaas-arrow.png) center center no-repeat;
		-webkit-transition: -webkit-transform 0.2s ease-in-out;
	}

	&.btn-arrow, &.btn-create-listing span {
		position: absolute;
		right: 4px;
		top: 4px;
		width: 25px;
		height: 25px;
		background: transparent url(/assets/home/images/ui/sprite-icons.png) top left no-repeat;
	}

	&.btn-follow span, &.btn-create-listing span {
		background-position: 0 -30px;
	}

	&.btn-unfollow span, &.btn-disconnect span {
		background-position: 0 -59px;
	}

	&.btn-connect span {
		background-position: 0 0;
	}

	&.btn-connect, &.btn-follow, &.btn-unfollow {
		padding: 7px 10px;
	}
}

.btn-delete-answer {
	position: absolute;
	right: 0;
	top: -5px;
	background: transparent url(/assets/home/images/trash.png) 2px 1px no-repeat;
	height: 22px;
	width: 18px;
	display: block;
	text-indent: -9999px;

	&:hover {
		background-position: 2px -25px;
	}
}

.btn-group {
	position: relative;

	> ul {
		position: absolute;
		left: 0;
		top: 20px;
		width: 275px;
		display: none;
		background: #fff;
		z-index: 9999;
		box-shadow: 2px 2px 2px #ccc;
		border: 1px solid #666;

		li {
			list-style: none;
			padding: 4px 5px;
			border-bottom: 1px solid #ccc;
		}
	}

	&.open > ul {
		display: block;
	}
}