@font-face {
	font-family: 'FontAwesome';
	font-style: normal;
	font-weight: 400;
	font-display: block;
	src: url('/assets/common/css/font-awesome/fonts/fa-brands-400.eot');
	src: url('/assets/common/css/font-awesome/fonts/fa-brands-400.eot?#iefix') format('embedded-opentype'),
	url('/assets/common/css/font-awesome/fonts/fa-brands-400.woff2') format('woff2'),
	url('/assets/common/css/font-awesome/fonts/fa-brands-400.woff') format('woff'),
	url('/assets/common/css/font-awesome/fonts/fa-brands-400.ttf') format('truetype'),
	url('/assets/common/css/font-awesome/fonts/fa-brands-400.svg#fontawesome') format('svg');
}

@font-face {
	font-family: 'FontAwesome';
	font-style: normal;
	font-weight: 400;
	font-display: block;
	src: url('/assets/common/css/font-awesome/fonts/fa-regular-400.eot');
	src: url('/assets/common/css/font-awesome/fonts/fa-regular-400.eot?#iefix') format('embedded-opentype'),
		url('/assets/common/css/font-awesome/fonts/fa-regular-400.woff2') format('woff2'),
		url('/assets/common/css/font-awesome/fonts/fa-regular-400.woff') format('woff'),
		url('/assets/common/css/font-awesome/fonts/fa-regular-400.ttf') format('truetype'),
		url('/assets/common/css/font-awesome/fonts/fa-regular-400.svg#fontawesome') format('svg');
}

@font-face {
	font-family: 'FontAwesomeSolid';
	font-style: normal;
	font-display: block;
	src: url('/assets/common/css/font-awesome/fonts/fa-solid-900.eot');
	src: url('/assets/common/css/font-awesome/fonts/fa-solid-900.eot?#iefix') format('embedded-opentype'),
		url('/assets/common/css/font-awesome/fonts/fa-solid-900.woff2') format('woff2'),
		url('/assets/common/css/font-awesome/fonts/fa-solid-900.woff') format('woff'),
		url('/assets/common/css/font-awesome/fonts/fa-solid-900.ttf') format('truetype'),
		url('/assets/common/css/font-awesome/fonts/fa-solid-900.svg#fontawesome') format('svg');
}