﻿@import "modules/variables.scss";
@import "modules/mixins.scss";

@mixin navigation-page() {
	@media #{$mediaquery-nav-full-min} {
		padding-left: $navigation-column-width;
		padding-right: 20px;
	}
}

// LAYOUTS
#content .content_inner {
	padding-top: 20px;
}

// Layout
.re-layout-layout:not(.sharecrm-campaigns),
.re-layout-layout:not(.sharecrm-campaign-detail) {

	.layout-two-column-no-profile {
		padding-left: 0;
		margin-top: 0;
		max-width: none;
	}
	
	
	.component-reblast-details{
		min-height: 200px;
	}
}

.resaas-question-profile-wrapper .content_inner > #search_bg {
	left: calc(50% - 400px);
	max-width: 800px;
	margin-right: 0;
	margin-left: 0;
	position: relative;

	@media only screen and (max-width: 1290px) {
		left: calc(50% - 400px);
	}

	@media only screen and (max-width: 1020px) {
		position: static;
	}
}

// Question
.resaas-question-profile-wrapper {

	.notification-toggle.visible {
		@media only screen and (min-width: 1290px) {

			+ #questions_module {
				width: 660px;
				margin-left: 93px;
			}
		}
	}

	@media #{$mediaquery-md-min} {
		.component-footer-wrapper {
			max-width: 800px;
			padding-left: 20px;
		}
	}
}

body.sharecrm-campaigns,
body.sharecrm-campaign-detail {
	.component-sharecrm-view {
		max-width: 100%;
		width: 743px;
	}
}

// Activity feed
.re-activity-feed-page {
	.component UIBlock,
	.component-profile-add-reblasts {
		@include outerContainer;
	}

	.component-profile-add-reblasts {
		padding: 15px;
	}

	.btn-listings {
		margin-top: 17px;
		margin-bottom: 0;
	}

	.referral-feed-action .btn-post-referral {
		margin: 0;
	}
}

.re-btn.btn-blastoff,
.re-btn.re-btn-blastoff {
	margin: 0;
}


// Hashtag page
body.re-hashtag-page {
	.layout-two-column-no-profile,
	.layout-two-column {
		margin-top: 0;
		padding-left: 0;
		max-width: 650px;
	}
}


.resaas-listings-wrapper {
	&#content {
		min-width: 0;

		.content_inner {
			min-width: 0;
			max-width: 1070px;
			@include navigation-page();
		}
	}

	#profile_nav_module .inner .user_details {
		margin-top: 20px;
	}

	.col_main {
		margin: 0;
		display: block;
	}

	.component-footer-wrapper {
		@media #{$mediaquery-md-min} {
			padding-left: 20px;
			width: 810px;
		}
	}
}

.resaas-listings-wrapper.resaas-my-listings {
	&#content {
		min-width: 0;
	}

	.col_left {
		display: none;
	}
}

// Leads

.re-maxcenterupdate-page {
	@include navigation-page();
}

.re-confirmation-formContainer {
	@include navigation-page();
}

//MODERN PAGE LAYOUTS
.re-page-outer-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 100vh;
	overflow-x: hidden;
}

.re-page-wrapper {
	display: flex;
	flex-wrap: wrap;
	margin: ($spacing-lg + $topnavheight) auto 0;
	position: relative;
	width: 100%;

	&.re-page-wrapper-navigationhidden {
		margin-top: 0;
	}
}

.re-page-content {
	flex: 1;
	margin-left: auto;
	margin-right: auto;

	.re-page-navigation-anonymous ~ & {
		flex: 0 1 100%;
		margin-top: -$topnavheight;
	}

	@media #{$mediaquery-nav-compact-max} {
		padding-left: $spacing-sm;
		padding-right: $spacing-sm;
	}
}

.re-page-footer {
	margin-top: $spacing-xl;
}

@mixin navigation-page-section($width, $padding-left: $spacing-lg, $padding-right: $spacing-lg) {
	@media #{$mediaquery-nav-full-min} {
		padding-left: $padding-left;
		padding-right: $padding-right;
	}
}

@mixin navigation-page-type($width) {
	$widthNoPx: $width / 1px;

	.re-page-wrapper {
		max-width: $width + $navigation-column-width;
	}

	.re-page-navigation {
		& ~ .re-page-content {
			box-sizing: border-box;
			max-width: 100%;
			@include navigation-page-section($width);

			@media #{$mediaquery-nav-full-min} {
				max-width: calc(100% - #{$navigation-column-width - $spacing-lg});
			}
		}
	}

	.re-page-navigation-anonymous {
		position: relative;
		top: -20px - $topnavheight;
		width: 100%;

		.re-page-toolbar {
			$w: if($width > 1440px, $width, 1440px);
			@include navigation-page-section($w, 50px, 50px);
			min-height: 100px;

			@media #{$mediaquery-nav-compact-max} {
				padding-right: 0;
				padding-left: $spacing-lg;
				min-height: 50px;
			}
		}

		.re-page-toolbar-background {
			background: inherit;
			height: 100%;
			position: absolute;
			margin-left: calc(50% - 50vw);
			margin-right: calc(50% - 50vw);
			width: 100vw;
			top: 0;
			z-index: -1;
		}

		.re-page-toolbar-list {
			@media #{$mediaquery-nav-full-min} {
				margin-top: $spacing-sm;
				margin-bottom: $spacing-sm;
			}
		}

		& ~ .re-page-content {
			max-width: $width;

			@include navigation-page-section($width, 50px, 50px);
		}
	}

	.re-page-navigation-focused {
		& ~ .re-page-content {
			max-width: $width;

			@include navigation-page-section($width, 50px, 50px);
		}
	}
}

.re-page-type-a {
	@include navigation-page-type($pagewidth-a);
}

.re-page-type-b {
	@include navigation-page-type($pagewidth-b);
}

.re-page-type-c {
	@include navigation-page-type($pagewidth-c);
}

.re-page-type-d {
	@include navigation-page-type($pagewidth-d);
}

.re-page-type-e {
	@include navigation-page-type($pagewidth-e);
}

//fullwidth (e.g. listing discovery) with no footer
.re-page-type-f {
	@include navigation-page-type($pagewidth-f);

	.re-page-navigation, .re-page-navigation-anonymous {
		& ~ .re-page-content {
			@media only screen and (min-width: 0em) {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}

	.re-page-footer {
		display: none;
	}
}

.re-page-type-g {
	@include navigation-page-type($pagewidth-g);
}

.re-page-type-h {
	@include navigation-page-type($pagewidth-h);
}

.re-page-type-i {
	@include navigation-page-type($pagewidth-i);
}

//profile page to adjust the margin of the left and right columns.
.re-page-type-j {
	@include navigation-page-type($pagewidth-j);
}

//fullwidth with footer
.re-page-type-k {
    @include navigation-page-type($pagewidth-f);

    .re-page-navigation-anonymous {
        & ~ .re-page-content {
            @media only screen and (min-width: 0em) {
                padding-left: 0;
                padding-right: 0;
                margin-top: -70px;
            }

            @media #{$mediaquery-nav-compact-max} {
                margin-top: -80px;
            }
        }
    }
}

.re-page-content {
	.re-page-navigation-anonymous-hidden + & {
		padding-top: 0;
		margin: 0 auto;
	}

	@media #{$mediaquery-nav-compact-max} {
		.re-page-navigation-anonymous + & {
			padding-top: $spacing-lg + $topnavheight;
		}

		.re-page-navigation-anonymous-hidden + & {
			padding-top: $spacing-lg;
		}
	}
}

.re-page-toolbar-hide {
	.re-page-toolbar-list, .re-page-toolbar-control {
		display: none;
	}
}