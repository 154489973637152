﻿.re-notifications-drawer {
	width: 300px;
	position: fixed;
	bottom: 15px;
	z-index: 600;
	left: 20px;
}

.re-notificationdrawer-container {
	border-radius: 0;
	margin-bottom: 5px;
	position: relative;
	box-shadow: 2px 2px 10px 2px rgba(109,110,113,0.2);

	.re-notificationdrawer-deeplink {
		#{$hoverfocus} {
			color: $color-black;
		}

		.mediaItem-media {
			margin-right: 15px;
		}
	}
}

.re-close-item {
	cursor: pointer;
	color: $color-grey-light;
	position: absolute;
	top: 5px;
	right: 5px;

	#{$hoverfocus} {
		color: $color-black;
	}
}

.re-notificationdrawer-title {
	font-weight: $font-weight-semibold;
}

.re-notificationdrawer-message {
	margin-top: 5px;
	opacity: 0.7;
	@include textEllipsis();
}
