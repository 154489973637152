/*********************************** */
/* RESAAS oevrlays					 */
/* everything overlays				 */
/*********************************** */

@import 'modules/variables';
@import 'modules/mixins';

.re-overlay-open {
	body {
		overflow-y: scroll;
	}

	.re-nav-container {
		visibility: hidden;
	}
}

.re-overlay-stick-content {
	position: fixed;
	left: 0;
	width: 100%;
}

.global-overlay {
	display: none;
}

.overlay-background {
	background: rgba(0, 0, 0, 0.8);
	height: 100%;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1001;
}

.overlay-header {
	padding: 15px;
	background: $rGreyLightest;
	font-weight: 700;
	font-size: $font-size-md;

	.fa-times {
		padding: 5px;
		color: $rGreyDarkest;
	}
}

.overlay-wrapper, .overlay-dark-wrapper {
	@include container;
	@include outerContainer;

	box-sizing: border-box;
	left: 0;
	margin: 0 auto $spacing-lg;
	position: absolute;
	right: 0;
	top: 25%;
	max-width: calc(100% - #{$spacing-lg});
	z-index: 1001;

}

.overlay-dark-wrapper {
	background: $color-black;
	padding: $spacing-md;

	p {
		color: $color-white;
	}

	a {
		color: $rYellow;
	}
}

.overlay-content {
	padding: 10px;
	position: relative;

	.overlay-profile {
		.item-image {
			float: left;
		}

		.reblast-user-information {
			padding: 0 0 0 10px;
			float: left;
		}
	}

	.overlay-text {
		clear: both;
	}
}

.overlay-popup-actions {
	padding: 10px;
}

.overlay-actions {
	padding: 10px;

	li {
		margin: 0;
	}

	.btn-submit {
		font-size: $font-size-sm;
		padding: 10px;
	}

	.btn-cancel {
		line-height: 18px;
	}
}

// overlay premium
.overlay-premium {
	border: 0;
	background: #323232;
	color: #fff;

	border-radius: 0;

	box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.5);

	.btn-cancel {
		color: white;
	}

	.membership-pricing {
		color: #fff;
		font-size: $font-size-sm;
	}

	.fa-check {
		color: #14d002;
	}

	li {
		line-height: 19px;
		padding: 0 0 8px;

		&:last-child {
			padding: 0;
		}
	}

	.overlay-actions {
		.btn-red {
			margin: 0;
			min-width: 150px;
			font-size: 18px;
			font-weight: 100;
		}
	}
}

.overlay-badges-wrapper {
	left: 17px;
	position: absolute;
	top: 30px;
}


.overlay-premium-content {
	font-size: $font-size-sm;

	p {
		margin: 0 0 8px;
		color: #fff;
		line-height: 12px;
	}
}

.overlay-premium-benefits {
	margin: 24px 0 0;
}

.overlay-content {
	padding: 30px 30px 10px 30px;
}

.overlay-actions {
	padding: 0 30px 30px 30px;
}

.overlay-close-btn {
	color: #cfcfcf;
	font-size: $font-size-sm;
	padding: 5px;
	position: absolute;
	right: 15px;
	top: 12px;
}

// global overlay
.re-overlay-wrapper-intro {
	background: #fff;
	border-radius: $border-radius-large;
	box-sizing: border-box;
	font-family: $font-family-sans;
	font-size: $font-size-sm;
	padding: 35px 40px 30px;
	position: absolute;
	margin: auto;
	max-width: 1200px;
	width: 90%;
	top: 100px;
	left: 0;
	right: 0;
	z-index: 1001;

	.re-overlay-wrapper-close-btn {
		background-color: transparent;
		color: $rGrey;
		font-size: $font-size-sm;
		padding: 5px;
		position: absolute;
		right: 5px;
		top: 5px;
	}
}

// confirm location overlay
.re-overlay-wrapper-container {
	overflow: hidden;
	padding: 50px 0;
	position: relative;

	@media #{$mediaquery-md-max} {
		padding: 0;
	}

	.re-overlay-wrapper-col {
		float: left;
		min-height: 10px;
		width: 45%;

		@media #{$mediaquery-md-max} {
			float: none;
			width: 100%;
		}
	}

	.re-overlay-wrapper-col + .re-overlay-wrapper-col {
		margin-left: 10%;

		@media #{$mediaquery-md-max} {
			margin-left: 0;
		}
	}

	.re-overlay-wrapper-description {
		margin: 0 auto 50px;

		p {
			color: $color-black;
			font-size: $font-size-lg;
			font-weight: $font-weight-light;
			line-height: 1.4;
			margin: 1em 0;

			&:first-child {
				margin-top: 0;
			}

			@media #{$mediaquery-xs-max} {
				font-size: $font-size-md;
			}
		}
	}

	.re-overlay-wrapper-title {
		text-align: center;
		font-size: $font-size-xl;
		line-height: 1.4;
		color: $color-black;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 45%;

		@media #{$mediaquery-md-max} {
			margin: 38px auto;
		}

		@media #{$mediaquery-md-max} {
			font-size: $font-size-lg;
			position: static;
			transform: none;
			width: auto;
		}

		em {
			font-weight: $font-weight-bold;
			color: $color-black;
			font-style: italic;
		}

		&::before {
			content: " ";
			display: block;
			border-right: 1px solid $color-grey-light;
			height: 240px;
			position: absolute;
			right: -11%;
			top: 50%;
			transform: translateY(-50%);

			@media #{$mediaquery-md-max} {
				display: none;
			}
		}
	}
}

.pac-container {
	z-index: 1002;
}

#CreateGroupForm {
	.re-overlay-wrapper-intro {
		max-width: 550px;
		top: 30px;

		@media #{$mediaquery-nav-compact-max} {
			overflow-y: auto;
			height: unquote("min(100%, 736px)");
			position: fixed;
		}

		@media only screen and (max-width: 450px) {
			padding: $spacing-lg;
		}
	}
}

aside.re-popup-component {
	position: static;

	.re-overlay-wrapper-intro {
		/*	
			defined in PopUp.js
			maxWidth: popUpWidth,
			minHeight: popUpHeight	
		*/
		display: flex;
		flex-flow: column;

		&::after {
			bottom: -20px;
			content: " ";
			display: block;
			height: 1px;
			left: 0;
			position: absolute;
			width: 1px;
		}
	}
}

.re-popup-align-center {
	.re-popup-header {
		text-align: center;
	}

	.re-popup-content {
		text-align: center;
	}
}

.re-popup-header {
    .re-popup-header-title {
        letter-spacing: normal;
        line-height: 1.2;
    }
}

.re-popup-content {
	flex: 1;
	margin: $spacing-xl 0;

	p {
		margin-top: $spacing-sm;
		line-height: 1.4;

		&:first-child{
			margin-top: 0;
		}
	}
}

.re-popup-actions {
	text-align: right;

	.re-btn {
		margin-bottom: 0;
		margin-top: 0;
	}

	.re-btn-full-width + .re-btn-full-width {
		margin-top: $spacing-sm;
	}
}

