@import "modules/mixins.scss";
@import "modules/variables.scss";
@import "partials/searchbar";

.create-group-container {
	textarea, select, input {
		margin-bottom: 15px !important;
		padding: 5px;
		border-radius: 3px;
	}

	.group-name-container {
		margin-top: 15px;
	}

	label {
		display: block;
		padding-bottom: 5px;
	}

	textarea {
		width: 100%;
		box-sizing: border-box;
	}

	.error {
		width: 100%;
		text-align: center;
		margin: 10px 0 10px 0;
		background: #ffbaba;
		color: #d8000c;
		padding: 10px;

		border-radius: $border-radius-small;
	}
}

.create-new-group-title {
	padding-bottom: 10px;
}

.invite-members-container {
	float: right;
}

.create-invite-container {
	.overlay-container {
		width: 343px;
		position: fixed;
		top: 20px;
		left: 50%;
		transform: translateX(-50%);
		max-width: 95%;
		margin: 0;
	}

	.invite-title {
		font-weight: bold;
	}

	.group-members-textarea {
		border-color: $color-black;
		margin: 6px 0 25px 0;
	}
}

.group-privacy-container {
	li {
		display: inline-block;
	}

	margin-top: 10px;

	input {
		float: left;
	}

	span {
		padding-left: 5px;
	}
}

.btn-clicked {
	pointer-events: none;
	opacity: 0.5;
}

.ui-helper-hidden-accessible {
	display: none;
}

.overlay {
	@include UIOverlay(#000, 0.4, 103);
}

.overlay-container {
	z-index: 1001;
	overflow: hidden;
	padding: 20px;
	background: $color-white;
	position: fixed;

	.overlay-close {
		display: block;
		color: $rGrey;
		position: absolute;
		top: 0;
		right: 0;
		text-decoration: none;
		text-indent: -9999px;

		&:after {
			content: "\f00d";
			font: $font-awesome;
			padding: 10px;
			position: absolute;
			right: 0;
			text-indent: 0;
			top: 0;
		}
	}
}

.groups-bar-icon {
	position: relative;
	float: right;
	top: -45px;
	right: 10px;
	cursor: pointer;
	pointer-events: all;
}

.tab-container {

	@media #{$mediaquery-xs-max} {
		flex-direction: column;
		text-align: center;
	}
}

.newtabsection {
	margin: 20px 2px 20px 2px;

	.tab-container {
		background: white;
		overflow: hidden;
		z-index: 11;
		margin-left: 0;
		margin: 0;
		padding: 0 !important;
	}

	.tab-header {
		h3 {
			color: $rGreyDarkest;
			padding: 12px 0 2px 0;
		}
	}

	.reblasts-subnav, .questions-nav, .groups-subnav {
		font-size: $font-size-sm;

		li {
			padding: 5px 0 5px 0;
			cursor: pointer;

			a {
				color: $rGreyDarkest;
			}

			&.active {
				border-left: 4px solid $rBlueDark;
				padding-left: 5px;
				position: relative;
				right: 9px;

				a {
					font-weight: bold;
					font-size: $font-size-sm;
					color: #000;
				}
			}
		}
	}

	.round {
		border-radius: 250px;
		font-size: $font-size-xs;
		color: white;
		text-align: center;
		background: #dc4c4c;
		margin-left: 9px;
		padding-left: 7px;
		padding-right: 7px;
	}

	.reblasts-nav-create-btn {
		color: #000;
		line-height: 22px;
	}

	.fa-plus-circle {
		color: $rGreyDarkest;
	}

	li.tab.firm {
		display: none;
	}
}

.ask-questions-infeed {
	#search_bg {
		padding: 0 !important;
		min-width: 0 !important;
	}

	#search_module {
		max-width: 97% !important;
		min-width: 97% !important;
		margin: 0 10px 10px 10px !important;
		padding: 10px 0 10px 0 !important;
	}

	.search_bar {
		padding: 8px !important;

		@include ArrowBox($color-white, 1px solid #ccc, 6px, 0, -1px, 5px, 0, 0);

		&:after, &:before {
			top: 40px;
		}
	}

	textarea {
		width: 100% !important;
		min-height: 32px;
	}

	button {
		float: right;
		margin-top: 10px;
	}

	.top-nav-container {
		margin-bottom: 10px;
	}

	.actions-container {
		overflow: hidden;
	}

	.top-nav-active {
		border-radius: $border-radius-small;

		padding: 5px;
		font-size: $font-size-sm;
	}

	li {
		display: inline-block;
		margin-right: 5px;
	}

	.content-creation-profile-photo {
		width: 30px;
		vertical-align: middle;
	}

	.rs-questions-message {
		margin: 10px 0 0 0 !important;
	}
}

.question-filters {
	background: $color-white;
	border-bottom: 1px solid #ccc;
	margin-bottom: 10px !important;
	padding: 10px !important;
	font-size: $font-size-sm;

	.questions-nav-select {
		border: 1px solid rgb(204, 204, 204);
		max-width: 176px;
		width: 135px;
		padding: 5px;
	}
}

.component-tabs {
	position: relative;

	.failed-screen, .failed-question {
		position: relative;
		top: 1px;
		left: 0%;
		width: 97%;
		height: 16px;

		html.ie7 & {
			height: 220px !important;
		}

		> div {
			position: absolute;
			top: 18%;
			left: 10px;
			height: 20%;
			width: 100%;
			text-align: left;
		}
	}

	.failed-question {
		position: relative;
	}

	.questions-page {
		width: 100% !important;

		span.questions {
			left: 44% !important;
		}
	}

	.tab-nav {
		overflow: hidden;
		max-width: 634px;

		.group-badges {

			li {
				list-style: none;
			}

			img {
				max-height: 150px;
				width: 100%;
				margin: -5px auto 0px;
				border-radius: 0 0 $border-radius-large $border-radius-large;
			}
		}

		.group-links-inner-withnotifications {

			.members-menu {
				top: 28px;
				right: 46px;
			}

			.settings-menu {
				top: 28px;
				right: 8px;
			}

			.usercreated-group-button {
				margin-left: 13px;
				display: inline-block;
				cursor: pointer;
			}

			.usercreated-group-count {
				position: absolute;
				top: 24px;
				font-size: $font-size-md;
				font-weight: bold;
				color: $color-grey;
			}

			.usercreated-group-dropdown {
				display: none;
				z-index: 15;
				margin-top: 15px;
				position: absolute;
				border: 1px solid #ccc;
				width: auto;
				float: right;
				background: $color-white;
				color: $color-grey;
				text-align: right;
				cursor: pointer;

				.re-usercreated-group-dropdown-item {
					color: $color-grey;
					font-size: $font-size-xs;
					min-width: 200px;
					padding: 8px 10px 9px;
					text-align: left;
					white-space: nowrap;

					&:hover {
						background: $color-grey;
						color: $color-white;
					}

					&:hover a {
						color: $color-white;
					}
				}

				a {
					text-decoration: none;
					color: $color-grey;
					display: block;
				}
			}



			li:hover .usercreated-group-dropdown-text {
				display: none;
			}

			.usercreated-group-dropdown-text-hover {
				display: none;
			}

			li:hover .usercreated-group-dropdown-text-hover {
				display: inline;
			}

			.usercreated-group-dropdown-text,
			.usercreated-group-dropdown-text-hover {
				margin-right: 30px;
			}

			.re-group-notifications-icon:before {
				float: right;
			}
		}



		.tab-header {
			float: left;
			list-style: none;
			display: none !important;

			&.active {
				display: block !important;

				.realtors-conference-and-expo {
					background: url(/assets/common/images/conference/2013-realtors-conference.jpg) 0 15px no-repeat !important;
					width: 387px !important;
				}

				.groupHeader {
					color: $color-grey;
					padding: 14px;
				}
			}

			a {
				display: block;
				color: $color-black;
				padding: 0;
				position: relative;
				font-size: 18px;
			}
		}
	}

	li.tab.firm {
		display: none;
	}

	.tab-content {
		position: relative;

		> .content {
			display: none;

			&.active {
				display: block;
			}

			> .gutter {
				margin: 10px;
			}

			.reblasts-subnav {
				overflow: auto;
				padding: 15px 0 11px 15px;
				border-bottom: solid 1px #ccc;
				font-size: $font-size-sm;
			}
		}

		.timeout-reblast-error {
			border-radius: $border-radius-large;
			position: fixed;
			top: 42px;
			left: 36%;
			z-index: 98;
			margin: auto;
			text-align: center;
			opacity: 0.9;

			.inner {
				margin: auto;
				text-align: center;
				z-index: 98;
				margin-left: 10px;
				width: 341px;
				padding: 7px;
				color: #d8000c;
			}
		}
	}

	.realtors-conference-and-expo-header {
		margin-left: 41px;

		li {
			display: inline-block;
			width: 107px;
			height: 120px;
		}

		.nar-1 {
			background: url(/assets/common/images/conference/realtors-features-speakers.jpg) 0 12px no-repeat;
		}

		.nar-2 {
			background: url(/assets/common/images/conference/realtors-features-speakers.jpg) -106px 12px no-repeat;
		}

		.nar-3 {
			background: url(/assets/common/images/conference/realtors-features-speakers.jpg) -212px 12px no-repeat;
		}

		.nar-4 {
			background: url(/assets/common/images/conference/realtors-features-speakers.jpg) -318px 12px no-repeat;
		}

		.nar-5 {
			background: url(/assets/common/images/conference/realtors-features-speakers.jpg) -424px 12px no-repeat;
		}
	}
}

//ie10 hack
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.btn-invite-to-group {
		position: relative;
		top: 9px;
	}

	.members-menu {
		.btn-invite-to-group {
			top: 0;
		}
	}
}

.ie9, .ie8, .ie7 {
	.btn-invite-to-group {
		position: relative;
		top: 9px;
	}

	.members-menu {
		.btn-invite-to-group {
			top: 0;
		}
	}
}
