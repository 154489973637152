﻿$topnavborder: 1px solid rgba($color-white, 0.2);

// -------------------------------
// TOP NAVIGATION
// -------------------------------
.re-top-nav {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: $topnavheight;
	background: $color-black;
	display: flex;
	z-index: 400;
	color: $color-white;
	font-size: $font-size-sm;

	&.re-tour-highlight {
		position: fixed;
		z-index: 1002;
		background: $color-black;
		border-radius: 0;
		box-shadow: 0 10px 40px white;
	}

	* {
		box-sizing: border-box;
	}

	a {
		color: inherit;
	}

	.re-top-nav-logo {
		padding: 13px;
		width: 63px;

		@media #{$mediaquery-sm-min} {
			width: 140px;
		}

		img {
			height: 100%;
		}
	}

	.re-upsell-premium {
		border: 1px solid rgba(241, 241, 241, 0.42);
		border-bottom: 0;
		border-top: 0;
		padding: 0 $spacing-md;
		font-size: $font-size-sm;

		.re-upgrade-bold {
			font-weight: $font-weight-bold;
		}
	}

	&-list {
		flex: 1;
		display: flex;
		justify-content: flex-end;

		.re-nav-list-item {
			display: flex;
			position: relative;
			min-width: 40px;

			@media #{$mediaquery-md-min} {
				min-width: 50px;
			}

			&.re-nav-border-top {
				@include border('top');
				margin-top: 10px;
				padding-top: 10px;
			}

			&.hide {
				display: none;
			}

			.re-nav-item-downloadapp {
				.re-icon-mobile {
					padding-right: $spacing-sm;
					color: $color-primary;
				}
			}
		}
	}

	.re-nav-item {
		display: flex;
		align-items: center;
		justify-content: center;
		flex: 1;
		flex-basis: auto;
		width: auto;
		padding: 5px 10px;

		#{$hoverfocus} {
			background-color: rgba($color-white, 0.1);
		}

		&.re-icon:before {
			opacity: 0.4;
		}
	}

	@media #{$mediaquery-nav-compact-max} {
		.re-nav-notifications .re-nav-item.re-nav-item-open {
			background-color: $color-primary;
		}
	}

	.re-nav-profile {
		.re-icon-chevron-down {
			margin-left: 10px;
			opacity: 0.8;
		}

		.re-nav-item-primary {
			padding: 5px 15px;
			border-left: $topnavborder;
		}

		img {
			margin-right: 8px;
		}
	}

	.re-nav-user-handle {
		opacity: 0.8;
		font-size: $font-size-xs;
	}

	.re-image-item img {
		max-width: 24px;
		width: 100%;
		margin-right: 8px;
	}

	.re-nav-inbox .re-nav-item-active.re-nav-highlight {
		color: lighten($color-primary, 5%);

		&:before {
			opacity: 1;
		}
	}

	.re-nav-secondary {
		background: $color-white;
		box-shadow: $box-shadow-dropDown;
		color: $color-black;
		position: absolute;
		right: 0;
		top: 100%;
		width: 250px;

		&-list {
			padding: 10px 0;
		}

		.re-nav-item {
			justify-content: flex-start;
			padding: 5px 15px;

			&.re-nav-item-active.re-nav-highlight {
				color: lighten($color-primary, 5%);
			}
		}

		.re-nav-user-info {
			@include border('bottom');
			padding: 10px 15px;

			span {
				display: block;
			}

			.re-nav-user-name {
				margin-bottom: 3px;
			}

			#{$hoverfocus} {
				text-decoration: none;

				.re-nav-user-name {
					text-decoration: underline;
					text-decoration-skip-ink: auto;
				}
			}
		}
	}

	.re-mobile-toggle {
		border-left: $topnavborder;
		margin-left: 10px;
		text-decoration: none;
		width: 50px;

		&:after {
			content: "\f0c9";
			font: $font-awesome;
			font-size: $font-size-lg;
			line-height: inherit;
			opacity: 0.4;
		}

		.re-notification {
			right: 10px;
			top: 25px;
		}

		.re-toggle-img {
			margin-right: -8px;
			z-index: 1;
		}

		&.re-nav-open {
			text-align: center;
			background: $color-secondary;
			color: white;
			text-decoration: none;

			&:after {
				content: "\2715";
				font: normal normal $font-size-lg $font-family-sans;
			}

			.re-toggle-img {
				display: none;
			}
		}
	}
}

// -------------------------------
// NOTIFICATION COUNTS
// -------------------------------

.re-topnav-notificationcount {
	background-color: $color-error;
	border-radius: 17px;
	color: #fff;
	display: none;
	font-size: 10px;
	font-weight: 700;
	height: 17px;
	line-height: 15px;
	min-width: 17px;
	padding: 0 6px;
	position: absolute;

	.re-nav-hasactivity & {
		display: block;
	}
}

.re-topnav-notificationcount {
	left: 100%;
	margin-left: -50%;
	top: 8px;
}

.re-nav-inbox .re-topnav-notificationcount {
	border-radius: 100%;
	height: 10px;
	left: auto;
	min-width: 0;
	padding: 0;
	right: 8px;
	top: 12px;
	width: 10px;
}

// -------------------------------
// TOOLBAR NOTIFICATIONS
// -------------------------------
.re-notifications-panel {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .re-notification-invitation-message {
        flex: 0 0 100%;
        border-left: $border-size solid $color-grey-light;
        padding: $spacing-sm;
        margin: $spacing-sm 0;

        .re-notification-invitation-message-see-more {
            font-weight: $font-weight-semibold;
            cursor: pointer;
        }
    }
}

.re-nav-secondary.re-notifications-list {
	min-width: 530px;

	@media #{$mediaquery-nav-compact-max} {
		height: calc(100% - #{$topnavheight});
		min-width: 0;
		position: fixed;
		top: $topnavheight;
		width: 100%;
	}

	.re-nav-secondary-list {
		max-height: calc(100vh - 120px);
		overflow-y: auto;
		padding: 0;
	}

	.re-nav-list-item {
		overflow: hidden;
		padding: $spacing-md;
		position: relative;

		&:not(:first-child) {
			@include border('top');
		}

		&:nth-last-child(n+2) {
			position: relative;
			z-index: 999;
		}

		+ .re-nav-notifications-no-notifications {
			display: none;
		}

		.re-notification-avatar {
			display: block;
			margin-right: $spacing-md;

			img {
				display: block
			}
		}

		.re-notification-message {
			flex: 1;
			font-size: $font-size-sm;

			a {
				color: $color-primary-dark;
				white-space: nowrap;
			}

			.re-notification-date {
				color: $color-grey;
				display: block;
				font-size: $font-size-xs;
				line-height: 1.5;
			}
		}

		.re-notification-action {
			color: $color-white;
			font-size: $font-size-md;
			white-space: nowrap;

			.re-btn, .re-delete-notification {
				margin: 0 0 0 $spacing-sm;
			}

			.re-delete-notification {
				background: transparent;

				#{$hoverfocus} {
					color: $color-primary;
				}
			}
		}

		&.re-notification-new,
		&.re-notification-read {
			background: $color-primary-highlight;

			.re-notification-message {
				font-weight: $font-weight-semibold;
			}

			& + .re-nav-list-item {
				border-top-color: $color-grey-light;
			}
		}

		&.re-notification-upsell {
			.premium-badges-silver {
				width: 36px;
				height: 36px;
				margin: 0 5px;
			}
		}
	}

	.re-nav-notifications-more-notifications {
		display: block;
		font-weight: $font-weight-normal;
		padding: $spacing-md;
		text-align: center;
		width: 100%;
	}
}

//animate inbox notification
.re-nav-item .sonar-wave {
	overflow: visible;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: 100%;
		background-color: $color-error;
		opacity: 0;
		z-index: -1;
		pointer-events: none;
		animation: sonarWave 0.5s linear 3;
	}
}

@keyframes sonarWave {
	from {
		opacity: 0.4;
	}

	to {
		transform: scale(5);
		opacity: 0;
	}
}

.re-back-to-nav {
	background: $color-secondary;
	bottom: 0;
	color: $color-white;
	display: none;
	height: $topnavheight;
	opacity: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 999;
	@include nav-transition(0.5s);

	.re-experienceswitcher-open & {
		display: block;
		opacity: 1;
		width: 100%;
	}

	.re-back-btn {
		float: right;
		height: 100%;

		&:after {
			content: "\2715";
		}
	}
}

// -------------------------------
// Voice Navigation
// -------------------------------
.re-voice-header {
	font-size: $font-size-xl;
}
.re-btn.re-overlay-status{
	margin-bottom: 30px;
}
.re-overlay-status-speak {
	background-color: $color-primary !important;
	border-color: $color-primary !important;
	animation: pulse 1.5s infinite;
}
@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 rgba(31,186,214, 0.8), 0 0 0 0 rgba(31,186,214, 0.4);
	}

	70% {
		box-shadow: 0 0 0 15px rgba(31,186,214, 0), 0 0 0 30px rgba(31,186,214, 0);
	}

	100% {
		box-shadow: 0 0 0 0 rgba(31,186,214, 0), 0 0 0 0 rgba(31,186,214, 0);
	}
}

.re-overlay-status-info {
	background-color: $color-primary;
	border-color: $color-primary;
}
.re-overlay-status-blocked {
	background-color: $color-grey-light;
	border-color: $color-grey-light;
}
.re-overlay-status-prompt {
	background-color: $color-grey-light;
	border-color: $color-grey-light;
}
.re-overlay-wrapper-intro.re-voice-nav-overlay {
	text-align: center;
	max-width: 650px;
}
#nav-voice-info {
	font-size: $font-size-md;
	padding-top: $spacing-md;
	padding-left: $spacing-md;
	padding-right: $spacing-md;
	color: $color-grey;
}
#nav-voice-info.hint {
	color: $color-grey-light;
}
#nav-voice-info.info {
	color: $color-black;
}
#nav-voice-info.success {
	color: $color-primary;
}

.re-btn.js-overlay-close-btn{
	margin-top: 0px;
}


.re-broadcast-banner {
	cursor: pointer;
	display: flex;
	padding: $spacing-sm $spacing-lg;
	position: fixed;
	top: 50px;
	left: 0;
	right: 0;
	background: orange;
	transition: height 0.4s linear;
	z-index: 399;
	transition: 0.4s ease-in-out;

	p {
		margin: auto;
		text-align: center;

		a {
			color: $color-black;
			text-decoration: underline;
		}
	}

	@media #{$mediaquery-nav-compact-max} {
		z-index: 299;
	}
}

.re-broadcast-banner-hidden .re-broadcast-banner {
	transform: translate(0, calc(-100% + 3px));
}

@media #{$mediaquery-nav-compact-max} {
	.re-broadcast-banner-hidden .re-broadcast-banner + .re-page-wrapper {
		top: 18px !important;
	}
}

.re-notification-expand-network {
	.re-icon-users:before {
		font-size: 29px;
	}
}

.re-invite-options {
	display: flex;
	border: 1px solid #eaeaea;
	padding: 1px;
	border-radius: 30px;
	margin: 20px 0;
	max-width: max-content;
}
.re-invite-option {
	cursor: pointer;
	padding: 10px 15px;
}

.re-invite-option-selected {
	background: #1fbad6;
	color: white;
	border-radius: 30px;
}
