@import "modules/variables.scss";
@import "modules/mixins.scss";

.re-footer {
	background: $color-black;
	box-sizing: border-box;
	width: 100%;
	padding: $spacing-xl $spacing-lg;

	ul {
		text-align: center;
		margin: $spacing-sm 0;
		padding-left: 0;
	}

	.re-footer-wrapper {
		margin: 0 auto;
	}

	.re-footer-language-availability-filtered-list, .re-footer-policy-list {

        position: relative;

		li {
			display: inline-block;
			word-break: break-word;
			padding: 0 6px;
			color: $color-grey-light;
			font-size: $font-size-xs;

			a {
				color: $color-grey-light;
				text-decoration: none;

				&:hover, &:focus {
					text-decoration: underline;
				}
			}
		}
	}

	.re-footer-language-list li {
		padding: 0 8px;
		white-space: nowrap;
	}

	.re-footer-policy-list li {
		padding: 0 2px;

		&:after {
			padding-left: 6px;
			content: "\2022";

			@media (max-width: 400px) {
				display: none;
			}
		}

		&:last-child:after {
			padding-left: 6px;
			content: none;
		}
	}

	.re-footer-social-media-icons {
		margin-top: 0;

		li {
			display: inline-block;
			margin: 0 5px;
		}

		a {
			display: flex;
			align-items: center;
			justify-content: center;
			color: $color-white;
			border-radius: 100%;
			border: 2px solid $color-white;
			width: 36px;
			height: 36px;
			box-sizing: border-box;

			&:hover, &:focus {
				opacity: 0.5;
			}
		}
	}

	.re-footer-resaas-copyright {
		text-align: center;
		color: $color-grey-light;
		font-size: $font-size-xs;
		margin: $spacing-sm 0 0 0;
		padding-left: 0;
	}

	.re-footer-policy-list {
		margin-top: 0;
	}
}

.re-footer-language-more {
	width: 20px;
}

.re-footer-language-more-link {
	color: $color-white;
	display: block;
	position: relative;
	text-indent: -9999em;

	&:before {
		content: "\2022\a0\2022\a0\2022";
		left: 0;
		position: absolute;
		text-align: left;
		text-indent: 0;
	}

    cursor: pointer;
    
}

.re-qtip-white {
    background: $rWhite;
    color: $rGreyDarkest;
    border-color: $rWhite;
    border: 1px solid $rGrey;
    cursor: pointer;

    .qtip-content {
        font-size: $font-size-sm;
        margin: 10px 5px;
    }
}

.re-footer-language-modal {
    display: flex;
    overflow: auto;

    ul {
        padding: 10px 10px 20px 10px;
    }

    li {
        padding: 5px;
        text-wrap: nowrap;
    }
}
