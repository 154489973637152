@import "modules/variables";

$logoWidth: 140px;
$logoBOSWidth: 230px;

@media #{$mediaquery-nav-compact-max} {
	.re-page-wrapper {
		position: relative;
	}

	.re-page-wrapper, .re-page-footer {
		transition: all 0.5s;
	}

	.re-show-nav {
		.re-page-wrapper, .re-page-footer {
			transform: translateX(-250px);
		}
	}

	.re-show-nav .re-page-outer-wrapper {
		position: fixed;
		overflow: hidden;
	}
}

/* TOOLBAR */
.re-page-toolbar-logo {
	height: 30px;
	margin-top: -15px;
	position: absolute;
	top: 50%;
	width: $logoWidth;
	z-index: 1;

	&.re-brokeros-toolbar-logo {
		margin-top: -30px;
		width: $logoBOSWidth;
		height: 60px;

		@media #{$mediaquery-nav-compact-max} {
			height: 47px;
			margin-top: -24px;
		}
	}

	@media #{$mediaquery-nav-compact-max} {
		height: 28px;
		margin-top: -14px;
	}
}


.resaas-logo-transparent {
	max-width: 100%;
	max-height: 100%;
}

.re-page-toolbar {
	padding-left: $spacing-lg;
	padding-right: $spacing-lg;

	@media #{$mediaquery-nav-compact-max} {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
	}
}

.re-page-toolbar-control {
	display: none;
	text-align: right;

	@media #{$mediaquery-nav-compact-max} {
		display: block;
	}
}

.re-page-toolbar-control-link {
	background: $color-primary;
	border-width: 1px;
	border-style: solid;
	border-radius: $border-radius-small;
	color: $color-white;
	display: inline-block;
	font-size: $font-size-md;
	padding: 19px 20px;
	text-decoration: none;

	&:hover, &:focus {
		text-decoration: underline;
	}

	@media #{$mediaquery-nav-compact-max} {
		border: none;
		border-radius: 0;
	}
}

.re-page-toolbar-list {
	list-style: none;
	margin: 0 0 0 $logoWidth;
	padding: 0;
	text-align: right;
	transition: all 0.5s;

	@media #{$mediaquery-nav-compact-max} {
		background: $navBackground;
		margin: 0;
		left: 100%;
		position: absolute;
		text-align: left;
		top: 0;
		width: 250px;
		height: calc(100vh;
		-60px)
	}
}

.re-page-toolbar-item {
	display: inline-block;
	padding: 10px 0;
	margin: 0 0 0 10px;
	position: relative;

	.re-btn {
		margin-left: 16px;
	}

	@media #{$mediaquery-nav-compact-max} {
		display: block;
	}
}

.re-page-toolbar-link {
	display: inline-block;
	color: $color-white;
	font-size: 15px;
	font-weight: $font-weight-normal;
	padding: 8px 16px;
	text-decoration: none;
	text-transform: uppercase;
	margin-top: $spacing-sm;

	&:hover, &:focus {
		text-decoration: underline;
	}

	@media #{$mediaquery-nav-compact-max} {
		display: block;
	}
}

.re-page-toolbar-item .re-page-toolbar-logo-mobile {
	display: none;

	@media #{$mediaquery-nav-compact-max} {
		display: block;
		height: 28px;
	}

	&.re-brokeros-logo-mobile {
		@media #{$mediaquery-nav-compact-max} {
			height: 38px;
		}
	}
}
