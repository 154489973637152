@import "modules/mixins";


@mixin like-component-v2($bg-position: 6px 1px, $margin: 0 0 0 0, $color: #959595 !important) {
	display: none;
	float: none;
	z-index: 12;
	width: 100px;
	height: 20px;

	span.counter {
		display: inline;
		margin: 0 0 0 4px;
		padding: 0 4px 0 4px;
		position: relative;
		border: 1px #959595 solid;
		border-radius: 3px;
		text-align: center;
		color: $color;
		font-weight: normal;
		font-size: 11px;
	}

	span.visitor {
		display: none;
		color: $color;
		font-size: 11px;
	}

	a {
		padding: 0 0 0 20px;
		font-weight: normal !important;
		color: $color;
		font-size: 11px;
		margin: $margin;

		html.ie9 & {
			background: transparent url(/assets/home/images/ui/like2.png) no-repeat;
			background-position: $bg-position;
			padding-top: 2px;
		}

		html.ie8 & {
			background: transparent url(/assets/home/images/ui/like2.png) no-repeat;
			background-position: $bg-position;
		}
	}
}

@mixin like-component-v3($bg-position: 6px 1px, $margin: 0 0 0 0, $color: #959595 !important) {
	display: none;
	float: none;
	z-index: 12;
	width: 100px;
	height: 20px;

	span.counter {
		display: inline;
		margin: 0 0 0 4px;
		padding: 0 4px 0 4px;
		position: relative;
		border: 1px #959595 solid;
		border-radius: 3px;
		text-align: center;
		color: $color;
		font-weight: normal;
		font-size: 11px;
	}

	span.visitor {
		display: none;
		color: $color;
		font-size: 11px;
	}

	a {
		background: none;
		font-weight: normal !important;
		color: $color;
		font-size: 11px;
		margin: $margin;

		html.ie9 & {
			background: none;
		}

		html.ie8 & {
			background: none;
		}
	}
}

@mixin absolutely($top, $left, $width, $height) {
	position: absolute;
	display: block;
	top: $top;
	left: $left;
	width: $width;
	height: $height;
}

@mixin btnflexy-v2($width, $float: none, $color: #ffffff, $background: #646464, $border: none, $gradient: false, $gradient-top: #808080, $gradient-bottom: #646464) {
	float: $float;
	display: block;
	color: $color;
	width: $width;
	background: $background;

	@if $gradient == true {
		$experimental-support-for-svg: true;

		background-image: linear-gradient($gradient-top, $gradient-bottom);
	}

	font-weight: bold;
	padding: 7px 8px;
	text-align: center;
	font-size: $font-size-xs;
	border: $border;
	text-transform: none;

	border-radius: $border-radius-small;

	@if $gradient == true {
		&:hover {
			text-decoration: none;
		}
	}
	@else {
		&:hover {
			text-decoration: none;
			background: #333;
		}
	}
}

@mixin btnflexy($width, $float, $direction: "default") {
	float: $float;
	display: block;
	color: #fff;
	width: $width;
	padding: 8px 8px 8px;
	text-align: left;
	font-size: $font-size-xs;

	@if $direction == "down" {
		background: #000 url(../../../home/images/sprites/sprite-icons.png) 100% -66px no-repeat;

		&:hover {
			background-position: 100% -99px;
		}
	}
	@else {
		background: #000 url(../../../home/images/sprites/sprite-icons.png) 100% 0 no-repeat;

		&:hover {
			background-position: 100% -33px;
		}
	}

	font-weight: bold;
	text-transform: uppercase;

	border-radius: $border-radius-large;

	&:hover {
		text-decoration: none;
	}
}

@mixin suckerfish($topLevelWidth, $lowerLevelWidth) {
	ul {
		list-style-type: none;
		padding: 0;
		margin: 0;
		line-height: 1;
	}

	a {
		display: block;
		width: $topLevelWidth;
	}

	li {
		float: left;
		width: $topLevelWidth;

		&:hover {
			ul {
				left: auto;

				li {
					float: none;
					display: block;
				}

				ul {
					left: -999em;
				}
			}
		}

		li:hover {
			ul {
				left: auto;
			}
		}

		ul {
			position: absolute;
			width: $lowerLevelWidth;
			left: -999em;

			ul {
				margin: (-1em) 0 0 $lowerLevelWidth;
			}
		}
	}
}

// view more helpers
@mixin expander($bottom) {
	position: absolute;
	bottom: $bottom;
	left: 0;
	width: 100%;
	z-index: 100;
	padding-top: 8px;

	.notch-left, .notch-right {
		position: absolute;
		top: 0;
		width: 9px;
		height: 8px;
	}

	.notch-left {
		left: 0;
		background: url(../../../home/images/sprites/sprite-icons.png) 0 -434px no-repeat;
	}

	.notch-right {
		right: 0;
		background: url(../../../home/images/sprites/sprite-icons.png) -8px -434px no-repeat;
	}

	.expander {
		background-color: #000;
		height: 41px;
		padding-top: 9px;

		.btn-more {
			@include btnflexy(96px, none, down);

			margin: 0 auto;
		}
	}

	.mask {
		position: absolute;
		left: 10px;
		bottom: -6px;
		height: 6px;
		width: 760px;
		background-color: #fff;
	}
}

@mixin closeButton($top, $right) {
	position: absolute;
	right: $right;
	top: $top;
	width: 23px;
	height: 23px;

	@include replace-text("../../../home/images/sprites/sprite-icons.png", -2px, -319px);

	&:hover {
		background-position: (-2px) -346px;
	}
}

@mixin soldBanner {
	position: absolute;
	width: 63px;
	height: 62px;
	top: 0;
	left: 0;

	@include replace-text("../../../home/images/sprites/sprite-icons.png", 0, -1222px);
}

@mixin tabButton($background) {
	background: $background;
}

/* folllow icons */
.icon-contact-following, .icon-contact-following-pending, .icon-contact-following-connected {
	display: block;
	width: 18px;
	height: 18px;

	@include replace-text("../../../home/images/sprites/sprite-icons.png", 0, -1152px);
}

.icon-contact-following-pending {
	background-position: (-18px) -1152px;
}

.icon-contact-following {
	background-position: (-36px) -1152px;
}

/* connect icons */
.icon-contact-connection, .icon-contact-connection-pending, .icon-contact-connection-connected {
	display: block;
	width: 18px;
	height: 18px;

	@include replace-text("../../../home/images/sprites/sprite-icons.png", 0, -1170px);
}

.icon-contact-connection-pending {
	background-position: (-18px) -1170px;
}

.icon-contact-connection {
	background-position: (-36px) -1170px;
}

/* listings icons */
.icon-contact-listings, .icon-contact-listings-pending, .icon-contact-listings-connected {
	display: block;
	width: 18px;
	height: 18px;

	@include replace-text("../../../home/images/sprites/sprite-icons.png", 0, -1188px);
}

.icon-contact-listings-pending {
	background-position: (-18px) -1188px;
}

.icon-contact-listings {
	background-position: (-36px) -1188px;
}

.icon-dashboard-contact {
	background: #000000 url(/assets/home/images/sprites/sprite-icons.png) 0 0 no-repeat;
	min-width: 56px;
	color: #fff200;
	padding-left: 4px;
	padding-right: 20px;
	display: inline-block;
	line-height: auto;

	border-radius: $border-radius-small;

	font-size: 11px;

	&.icon-dashboard-connect {
		background-position: 100% -1288px;
	}

	&.icon-dashboard-connected {
		color: #ffffff;
		background-color: #ababab;
		background-position: 100% -1305px;
		cursor: default;

		&:hover {
			text-decoration: none;
		}
	}

	&.icon-dashboard-invite {
		background-position: 100% -1322px;
	}

	&.icon-dashboard-invited {
		color: #ffffff;
		background-color: #ababab;
		background-position: 100% -1339px;
		cursor: default;

		&:hover {
			text-decoration: none;
		}
	}
}

.icon-question, .icon-questions {
	display: block;
	width: 64px;
	height: 64px;
}

.icon-carousel-control {
	display: block;
	width: 14px;
	height: 13px;
	float: left;
	margin: 0 1px;

	@include replace-text("../../../home/images/sprites/sprite-icons.png", -219px, -962px);

	&.active, &:hover {
		@include replace-text("../../../home/images/sprites/sprite-icons.png", -201px, -962px);
	}
}

.icon-learn-more, .icon-external-link {
	background: transparent url(/assets/home/images/sprites/sprite-icons.png) 100% -1429px no-repeat;
	padding-right: 14px;

	&:hover {
		background-position: 100% -1463px;
	}
}

.icon-external-link {
	background-position: 100% -1557px;

	&:hover {
		background-position: 100% -1594px;
	}
}

.icon-reblast-comments, .icon-reblast-likes {
	display: inline-block;
	padding-left: 18px;
	background: transparent url(/assets/home/images/sprites/sprite-icons.png) 3px -1944px no-repeat;
}

.icon-reblast-likes {
	display: none;
	background-position: 3px -1963px;
}

@mixin pageOverlay {
	background: #000;
	opacity: 0.8;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
	filter: alpha(opacity = 80);
	height: 100%;
	width: 100%;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 100;
}

@mixin pageOverlayInner {
	background: #fff;
	width: 400px;
	height: 240px;
	position: fixed;
	padding-top: 10px;

	border-radius: $border-radius-large;

	top: 50%;
	left: 50%;
	z-index: 101;
	margin-top: -200px;
	margin-left: -200px;
	text-align: center;
}