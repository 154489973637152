@import "modules/variables.scss";
@import "modules/_mixins.scss";

// Body

body {
	background: $backgroundColor;
}

p {
	color: #464646;
}

a {
	text-decoration: none;

	&:hover, &:focus {
		text-decoration: underline;
	}
}

.re-header-short {
	@include container;
	@include outerContainer;

	margin: $spacing-lg auto;
	text-align: center;

	img {
		display: block;
		max-height: 50px;
		max-width: 90%;
	}

	* {
		margin: $spacing-lg 0;
	}
}

blockquote {
	width: 540px;
	padding: 20px 8px 0 20px;
	font-size: 24px;
	color: #000;
	text-transform: uppercase;
	line-height: 110%;
	color: #464646;
	border-top: 10px solid #e1e1e1;
}

#call_to_action {
	.login_input {
		margin: 10px 0;
		padding: 6px 5px 5px;
		width: 240px;
		border-top: 2px solid #ABABAB;
		border-left: 2px solid #ABABAB;
		background: #fff;

		input {
			&[type='text'], &[type='password'] {
				width: 100%;
				font-size: $font-size-xs;
				border: 0;
			}
		}
	}

	.submit_btn {
		float: right;
	}

	.forgot_password {
		display: block;
		position: relative;
		clear: right;
		bottom: 0;
		padding-top: 10px;
		font-size: $font-size-xxs;
		text-align: right;
	}
}

// Page Layout

#content {
	position: relative;
	max-width: 100%;
	min-width: 960px;
	overflow: hidden;
	background: $backgroundColor;
	clear: both;

	&.profile-page {
		overflow: visible;
	}

	.content_inner {
		position: relative;
		max-width: 1160px;
		min-width: 940px;
		min-height: 650px;
		margin: 0 auto;
		padding: 10px;
	}
}

.top_level #content .content_inner {
	max-width: 1060px;
}

#content .content_inner {
	.shadow {
		position: absolute;
		top: 0;
		width: 39px;
		height: 418px;
	}

	.left {
		left: -39px;
	}

	.right {
		right: -39px;
		background-position: (-39px) 0;
	}
}

.three_col_layout {
	.col_left {
		float: left;
		width: 226px;
	}

	.col_right {
		float: right;
		width: 271px;

		.right-col-fixed.re-container-md {
			width: 241px;

			@media only screen and (max-width: 600px), (min-width: $screen-md-min) and (max-width: 840px) {
				position: static !important;
			}
		}
	}

	.col_main {
		margin: 0 291px 0 236px;
		min-height: 1300px;

		.question-detail {
			display: none;
		}

		a.profile-link {
			font-weight: bold;
			color: #0961a8 !important;

			&:hover, &:visited {
				color: #0961a8 !important;
			}
		}
	}

	.realtors-conference-alert {
		padding: 5px;
		background: #f30000;
		margin-left: 5px;
		margin-bottom: 1px;

		a {
			color: #fff;
			font-weight: bold;
		}
	}
}

.two_col_layout {
	.col_left {
		float: left;
		width: 230px;
		overflow: hidden;
	}

	.col_main {
		margin-left: 250px;
	}
}

.one_col_layout .col_main {
	width: 100%;
}

#twitter {
	padding: 10px 1px 10px 20px;
	background: url(/assets/common/images/twitter_16.png) left center no-repeat;
	display: inline-block;
	float: right;
}
