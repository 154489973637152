/*********************************** */
/* RESAAS tooltips					 */
/*********************************** */
.re-tooltip-child-container {
    cursor: pointer;
}

.re-tooltip-contents {
    position: absolute;
    z-index: 55;
    background: $color-white;
    color: $color-black;
    border-radius: $spacing-sm;
    border: 1px solid $rGreyLight;

    @media #{$mediaquery-sm-max} {
        left: 0;
        right: 0;
    }
}

.re-tooltip-contents-with-arrow {
    background: black;
    color: white;
    width: 220px;
    margin-top: 5px;
    margin-left: -10px;
    left: auto;
    right: auto;
}

.re-tooltip-arrow {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 20px;
    margin-left: -7px; 
    border: solid transparent;
    border-width: 7px;
    border-top-width: 12px;
    border-bottom-color: black;
}







