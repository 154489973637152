@import "modules/variables.scss";

// reblast and featured listing labels (new style)
.re-listing-labels {
    align-items: flex-start;
    cursor: default;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    margin-top: auto;

    @media #{$mediaquery-lg-min} {
        flex-direction: row;
    }

    .re-listing-labels-right {
        align-self: flex-start;
        margin-bottom: $spacing-sm;

        @media #{$mediaquery-lg-min} {
            align-self: flex-end;
            margin-bottom: 0;
        }

        .re-post-label {
            margin-right: 0;
        }
    }

    .re-listing-labels-left {
        display: flex;
        flex-flow: column;
        align-items: flex-start;
    }

    .re-post-label:last-child {
        margin-bottom: 0;
    }
}
.re-post-label {
    background-clip: padding-box;
    border-width: 1px;
    border-style: solid;
    border-radius: $border-radius-large;
    color: $color-white;
    cursor: default;
    display: inline-block;
    font-weight: $font-weight-semibold;
    font-size: $font-size-sm;
    margin-bottom: $spacing-sm;
    margin-right: $spacing-sm;
    padding: 1px 9px;

    &.listing-active {
        background-color: $color-blue-dark;
        border-color: $color-blue-dark;
    }

    &.listing-sold {
        background-color: $color-yellow;
        border-color: $color-yellow;
        color: $color-black;
    }

    &.listing-lease {
        background-color: $color-primary-darker;
        border-color: $color-primary-darker;
    }

    &.mls-premarket-featured {
        background-color: rgba($sfarPink, 0.8);
        border-color: rgba($sfarPink, 0.8);
    }

    &.premarket-days-label {
        background-color: rgba($sfarPink, 0.8);
        border-color: rgba($sfarPink, 0.8);
    }

    &.hidden-address-label {
        background-color: rgba($color-purple, 0.6);
        border-color: rgba($color-purple, 0.6);
    }

    &.mls-uploaded-label {
        background-color: rgba($color-black, 0.8);
        border-color: rgba($color-black, 0.8);
    }

    &.temporarily-withdrawn-label {
        background-color: rgba($rGreyDarker, 0.8);
        border-color: rgba($rGreyDarker, 0.8);
    }

    &.re-post-label-premarket {
        background-color: rgba($color-green-dark, 0.8);
        border-color: rgba($color-green-dark, 0.8);
    }

    &.pending-acceptance {
        background: $color-yellow-dark;
        border: $color-yellow-dark;
    }

    &.automatically-withdrawn-label-alert {
        border-color: rgba($color-error, 0.8);
        color: rgba($color-error, 0.8);
    }

    &.automatically-withdrawn-label-clickable {
        cursor: pointer;
    }

    &.automatically-withdrawn-label {
        border: 0;
        color: $color-black;
        padding-left: 0;
        padding-right: 0;
        font-size: $font-size-xs;

        @media #{$mediaquery-lg-min} {
            margin: 0;
        }
    }

    &.pushed-to-zillow-label {
        background-color: $color-zillow;
        border-color: $color-zillow;
    }

    &.draft-label {
        border-color: $color-grey;
        color: $color-grey;
    }

    .re-icon:before {
        margin-right: 7px;
    }
}

// right column listings module labels
.listings-module-component .re-sold-label {
    position: absolute;
    bottom: 4px;
    right: 3px;
    background-color: rgba($sfarYellow, 0.8);
    color: $color-white;
    padding: 2px 4px;
    font-weight: bold;
}

// listing detail page labels
.sfar-premarket-listing {
    background-color: $sfarPink;
    background-color: rgba($sfarPink, 0.8);
}

// listing status labels for listing widgets
.re-status-label {
    text-align: center;
    width: 100px;
    padding: 2px;
    display: block;
    box-sizing: border-box;
    margin-top: 6px;
    overflow: hidden;
    display: inline-block;

    &.mls-premarket-locked {
        background-color: $sfarPink;
        font-size: $font-size-xs;
    }

    &.re-listings-overlay-sold-label {
        background-color: $sfarYellow;
        font-size: $font-size-xs;
    }

    &.re-listings-overlay-premarket-label {
        background-color: $color-green-dark;
        font-size: $font-size-xs;
    }

    .status-label-text {
        color: white;
        white-space: normal;
    }
}

//generic status labels
.re-status-labels {
    text-align: center;
    box-sizing: border-box;
    font-size: $font-size-xs;
    color: $color-white;
    padding: 3px 10px;
    border-radius: 5px;
    display: inline-block;
    text-transform: uppercase;

    @media #{$mediaquery-lg-min} {
        width: 100%;
    }
}

.re-status-label-green {
    background-color: rgba(70,157,10,0.46);
    color: #469d0a;
}

.re-status-label-yellow {
    background-color: rgba(246,188,78,0.51);
    color: #f6bc4e
}

.re-status-label-blue {
    background-color: rgba(103,155,208,0.23);
    color: #679bd0;
}

.re-status-label-pink {
    background-color: rgba(240,110,97,0.48);
    color: #f06e61;
}

