﻿@import "modules/variables";

.re-icon {
    // GENERAL
    &:before {
        font: $font-awesome;
        font-size: $icon-size-lg;
        text-decoration: none;
        display: inline-block;
    }

    &.re-icon-inline:before {
        margin-right: 0.4em;
    }
    // SOLID
    &-solid:before {
        font: $font-awesome-solid;
    }
    // SIZES
    &-xxs:before {
        font-size: $icon-size-xxs;
    }

    &-xs:before {
        font-size: $icon-size-xs;
    }

    &-sm:before {
        font-size: $icon-size-sm;
    }

    &-md:before {
        font-size: $icon-size-md;
    }

    &-lg:before {
        font-size: $icon-size-lg;
    }

    &-xl:before {
        font-size: $icon-size-xl;
    }

    &-xxl:before {
        font-size: $icon-size-xxl;
    }
    // STATES
    &:hover,
    &:focus {
        text-decoration: none;
    }
    // ICONS
    // custom
    &-custom {
        &:before {
            content: '';
            display: inline-block;
            vertical-align: middle;
            height: $icon-size-lg;
            width: $icon-size-lg;
        }

        &.re-btn:before {
            margin-right: 8px;
        }

        &.re-icon-xxs:before {
            height: $icon-size-xxs;
            width: $icon-size-xxs;
        }

        &.re-icon-xs:before {
            height: $icon-size-xs;
            width: $icon-size-xs;
        }

        &.re-icon-sm:before {
            height: $icon-size-sm;
            width: $icon-size-sm;
        }

        &.re-icon-md:before {
            height: $icon-size-md;
            width: $icon-size-md;
        }

        &.re-icon-lg:before {
            height: $icon-size-lg;
            width: $icon-size-lg;
        }

        &.re-icon-xl:before {
            height: $icon-size-xl;
            width: $icon-size-xl;
        }

        &.re-icon-xxl:before {
            height: $icon-size-xxl;
            width: $icon-size-xxl;
        }
    }

    &-referral:before {
        background: transparent url('/assets/src/images/sprites/custom-icons/referral-icon.svg') center center no-repeat;
    }

    &-sharecrm-blue:before {
        background: transparent url('/assets/src/images/sprites/sharecrm/sharecrm-logo-blue.svg') center center no-repeat;
    }

    &-sharecrm-white:before {
        background: transparent url('/assets/src/images/sprites/sharecrm/sharecrm-logo-white.svg') center center no-repeat;
    }

    &-gps-location-black:before {
        background: transparent url('/assets/src/images/sprites/custom-icons/gps-location-icon.svg') center center no-repeat;
    }

    &-resaas-black:before {
        background: url(/assets/src/images/resaas-logo/resaas-black-re-logo.svg) center center no-repeat;
    }
    // fontawesome
    &-dollar-sign:before {
        content: '\f155';
    }

    &-close:before {
        content: '\f057';
    }

    &-check:before {
        content: '\f00c';
    }

    &-check-circle:before {
        content: '\f058';
    }

    &-x:before {
        content: '\f00d';
    }

    &-attach-image:before {
        content: '\f03e';
    }

    &-attach-pdf:before {
        content: '\f1c1';
    }

    &-attach-file:before {
        content: '\f0c6';
    }

    &-calendar:before {
        content: '\f073';
    }

    &-lock:before {
        content: '\f023';
    }

    &-nomicrophone:before {
        content: '\f131';
    }

    &-microphone:before {
        content: '\f130';
    }

    &-comment:before {
        content: '\f075';
    }

    &-comment-solid:before {
        content: '\f075';
    }

    &-comments:before {
        content: '\f086';
    }

    &-file:before {
        content: '\f15b';
    }

    &-question-circle:before {
        content: '\f059';
    }

    &-dot-circle:before {
        content: '\f192';
    }

    &-home:before {
        content: '\f015';
    }

    &-house-circle-xmark:before {
        content: '\e50b';
    }

    &-house-circle-check:before {
        content: '\e509';
    }

    &-eye-slash:before {
        content: '\f070';
    }

    &-sign-hangin:before {
        content: '\f4d9';
    }

    &-file-check:before {
        content: '\f316'
    }

    &-location:before {
        content: '\f3c5';
    }

    &-globe:before {
        content: '\f0ac';
    }

    &-earth:before {
        content: '\f57d';
    }

    &-signal:before {
        content: '\f690';
    }

    &-repeat:before {
        content: '\f364';
    }

    &-bolt:before {
        content: '\f0e7';
    }

    &-users:before {
        content: '\f0c0';
    }

    &-users-plus:before {
        content: '\f234';
    }

    &-play:before {
        content: '\f04b';
    }

    &-user:before {
        content: '\f007';
    }

    &-building:before {
        content: '\f1ad';
    }

    &-building-black:before {
        content: '\f1ad';
    }

    &-search:before {
        content: '\f002';
    }

    &-clock:before {
        content: '\f017';
    }

    &-share:before {
        content: '\f1e0';
    }

    &-email:before {
        content: '\f0e0';
    }

    &-config:before {
        content: '\f013';
    }

    &-caret-right:before {
        content: '\f0da';
    }

    &-bars:before {
        content: '\f0c9';
    }

    &-angle-double-left:before {
        content: '\f100';
    }

    &-angle-double-down:before {
        content: '\f103';
    }


    &-angle-double-up:before {
        content: '\f102';
    }

    &-external-link:before {
        content: '\f08e';
    }

    &-exchange:before {
        content: '\f0ec'
    }

    &-info:before {
        content: '\f05a';
    }

    &-briefcase:before {
        content: '\f0b1';
        font-size: $icon-size-sm;
    }

    &-star:before {
        content: '\f005';
    }

    &-star-half:before {
        content: '\f5c0';
    }

    &-edit:before {
        content: '\f040';
    }

    &-pen-to-square:before {
        content: '\f044';
    }

    &-trash:before {
        content: '\f1f8';
    }

    &-cc-card:before {
        content: '\f09d';
    }

    &-add:before {
        content: '\f067';
    }

    &-dropDown:before {
        content: '\f141';
    }

    &-chevron-right:before {
        content: '\f054';
    }

    &-upload:before {
        content: '\f093';
    }

    &-plus:before {
        content: '\f067';
    }

    &-plus-square:before {
        content: '\f0fe';
    }

    &-plus-circle:before {
        content: '\f055';
    }

    &-download:before {
        content: '\f019';
    }

    &-user-plus:before {
        content: '\f234';
    }

    &-flag:before {
        content: '\f024';
    }

    &-pin:before {
        content: '\f08d';
    }

    &-like:before {
        content: '\f164';
    }

    &-trophy:before {
        content: '\f091';
    }

    &-bell:before {
        content: '\f0f3';
    }

    &-bell-slash:before {
        content: '\f1f6';
    }

    &-phone:before {
        content: '\f095';
    }

    &-mobile:before {
        content: '\f10b';
    }

    &-arrow-left:before {
        content: '\f060';
    }

    &-arrow-right:before {
        content: '\f061';
    }

    &-arrow-up:before {
        content: '\f062';
    }


    &-chevron-down:before {
        content: '\f078';
    }

    &-caret-down:before {
        content: '\f0d7 ';
    }

    &-bullhorn:before {
        content: '\f0a1';
    }

    &-eye:before {
        content: '\f06e';
    }

    &-link:before {
        content: '\f0c1';
    }

    &-analytics:before {
        content: '\f643';
    }

    &-quote:before {
        content: '\f10d';
    }

    &-angle-up:before {
        content: '\f106';
    }

    &-angle-down:before {
        content: '\f107';
    }

    &-angle-right:before {
        content: '\f105';
    }

    &-angle-left:before {
        content: '\f104';
    }

    &-open-folder:before {
        content: '\f07c';
    }

    &-archive:before {
        content: '\f187';
    }

    &-inbox-in:before {
        content: '\f310';
    }

    &-shield:before {
        content: '\f3ed';
    }

    &-leave:before {
        content: '\e433';
    }

    &-subtitles:before {
        content: '\f7a4';
    }

    &-calendar-pen:before {
        content: '\e472';
    }

    @keyframes icon-spin {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    }

    &-spin:before {
        animation: icon-spin 2s infinite linear;
        content: '\f3f4'
    }
    //social media icons
    &-facebook:before {
        content: '\f39e';
    }

    &-linkedin:before {
        content: '\f0e1';
    }

    &-pinterest:before {
        content: '\f231';
    }

    &-twitter:before {
        content: '\f099';
    }

    &-instagram:before {
        content: '\f16d';
    }

    &-google-plus:before {
        content: '\f0d5';
    }

    &-youtube:before {
        content: '\f167';
    }

    &-google:before {
        content: '\f1a0';
    }

    &-apple:before {
        content: '\f179';
    }

    &-microsoft:before {
        content: '\f3ca';
    }

    &-credit-card:before {
        content: '\f09d';
    }

    &-money-bill:before {
        content: '\f0d6';
    }

    &-clockwise:before {
        content: '\f064';
    }

    &-counterclockwise:before {
        content: '\f3e5'
    }

    &-connection:before {
        content: '\f4c6'
    }

    &-refresh:before {
        content: '\f021'
    }

    &-caution:before {
        content: '\f071'
    }

    &-copy:before {
        content: '\f0c5';
    }

    &-user-lock:before {
        content: '\f502';
    }

    &-tasks:before {
        content: '\f0ae';
    }

    &-video:before {
        content: '\f03d';
    }
    //icon colors
    &-green:before {
        color: $rGreen;
    }

    &-red:before {
        color: $rRed;
    }

    &-yellow:before {
        color: $color-yellow;
    }

    &-grey:before {
        color: $color-grey;
    }

    &-grey-light:before {
        color: $color-grey-light;
    }

    &-primary:before {
        color: $color-primary;
    }

    &-secondary:before {
        color: $color-secondary;
    }

    &-black:before {
        color: $color-black;
    }
}
