@import "_sprites.scss";

@mixin avatar($size) {
	border-radius: 100%;
	height: $size;
	position: relative;
	width: $size;

	@at-root img#{&} {
		background: $color-grey-xtralight;
		display: block;
		overflow: hidden;
	}

	img {
		background: $color-grey-xtralight;
		border-radius: 100%;
		display: block;
		height: $size;
		width: $size;
		overflow: hidden;
	}
}

.re-avatar-xxs {
	@include avatar(30px);
}

.re-avatar-xs {
	@include avatar(36px);
}

.re-avatar-sm {
	@include avatar(50px);
}

.re-avatar-md {
	@include avatar(84px);
}

.re-avatar-lg {
	@include avatar(110px);
}

.re-avatar-xl {
	@include avatar(220px);
}


.re-avatar-sm {
	&.re-avatar-badge:after {
		position: absolute;
		top: 25px;
		left: auto;
		display: block;
		z-index: 1;
		content: " ";
		right: -8px;
	}

	&.re-avatar-badge-premium {
		&:after {
			@extend .re-svg-badges-premium;
			height: 26px;
			width: 26px;
		}

		&.re-premiumplus:after {
			@extend .re-svg-badges-premiumplus;
		}
	}

	&.re-avatar-badge-globalelite:after {
		@extend .re-svg-badges-globalelite;
		height: 42px;
		width: 42px;
		left: 24px;
		top: 32px;
	}
}

.re-avatar-xs {
	&.re-avatar-badge:after {
		position: absolute;
		top: 18px;
		right: -5px;
		display: block;
		height: 15px;
		width: 11px;
		z-index: 1;
		content: " ";
	}

	&.re-avatar-badge-premium{
		&:after {
			@extend .re-svg-badges-premium;
		}
		&.re-premiumplus:after {
			@extend .re-svg-badges-premiumplus;
		}
	}

	&.re-avatar-badge-globalelite:after {
		@extend .re-svg-badges-globalelite;
	}
}

.re-avatar-lg {
	&.re-avatar-badge:after {
		position: absolute;
		top: 65px;
		left: auto;
		display: block;
		z-index: 1;
		content: " ";
		right: -8px;
	}

	&.re-avatar-badge-premium {
		&::after {
			@extend .re-svg-badges-premium;
			height: 30px;
			width: 30px;
		}

		&.re-premiumplus:after {
			@extend .re-svg-badges-premiumplus;
		}
	}

	&.re-avatar-badge-globalelite:after {
		@extend .re-svg-badges-globalelite;
		height: 45px;
		width: 43px;
		top: 80px;
	}
}

.re-avatar-xl {
	&.re-avatar-badge:after {
		position: absolute;
		top: 130px;
		left: auto;
		display: block;
		z-index: 1;
		content: " ";
		right: -8px;
	}

	&.re-avatar-badge-premium {
		&::after {
			@extend .re-svg-badges-premium;
			height: 60px;
			width: 60px;
		}

		&.re-premiumplus:after {
			@extend .re-svg-badges-premiumplus;
		}
	}

	&.re-avatar-badge-globalelite:after {
		@extend .re-svg-badges-globalelite;
		height: 73px;
		width: 73px;
		top: 150px;
		right: -28px;
	}
}

.re-avatar-placeholder {
	background: $color-grey-xtralight;
	flex-shrink: 0;
}

.re-avatar-initials {
	border-radius: 30px;
	background-color: #6c6e70;
	color: white;
	display: flex;
	padding: 15px;
	width: 20px;
	height: 20px;
	justify-content: center;
	align-items: center;
	font-size: $font-size-lg;
}