/*********************************** */
/* RESAAS alerts					 */
/* success, error, info				 */
/*********************************** */

.alert {
	margin-bottom: 12px;
	padding: 12px;
}

//.alert-error
//.alert-success

.alert-info {
	background-color: #e7f1fd;
	border: 2px solid #666;
	color: #000;
}

.alert-failure {
	background-color: #ffbaba;
	color: $rRed;
}

.alert-success {
	background-color: #dff0d8;
	color: $rGreenDark;
}
