/*********************************** */
/* RESAAS tabs						 */
/* works with angular UI bootstrap	 */
/*********************************** */

// base tabs style
.nav-tabs {
	border-bottom: 3px solid $rGreyDarkest;
	cursor: pointer;

	@extend .clearfix;

	> .active {
		background: $rGreyDarkest;

		border-radius: $border-radius-small $border-radius-small 0 0;

		a {
			color: #fff;
		}
	}

	> li {
		float: left;
		position: relative;

		a {
			display: block;
			padding: 10px;
			text-decoration: none;
		}
	}
}

.tab-content {
	> .tab-pane {
		display: none;
	}

	> .active {
		display: block;
	}
}


// react tabs styles

.react-tabs {
	-webkit-tap-highlight-color: transparent;

	&__tab-list {
		border-bottom: 1px solid $color-grey-light;
		margin: 0 0 30px;
		padding: 0;
	}

	&__tab {
		display: inline-block;
		border-bottom: none;
		bottom: -1px;
		box-sizing: border-box;
		position: relative;
		list-style: none;
		padding: $spacing-sm;
		cursor: pointer;
		color: $color-secondary;
		font-size: $font-size-sm;
		font-weight: $font-weight-bold;
		text-align: center;

		&--selected {
			color: $color-primary;
			border-bottom: 4px solid $color-primary;
		}

		&:focus {
			outline: none;
		}
	}

	&__tab-panel {
		display: none;

		&--selected {
			display: block;
		}
	}
}