/*********************************** */
/* RESAAS like component			 */
/* Any comment related style		 */
/*********************************** */

// These rules can be deleted if we decide to unhide Likes

.hide-likes {
	.re-like-component {
		display: none !important;
	}
}

// this class is hooked into by mojo
.component-like-display {
	a {
		&:hover {
			text-decoration: underline;
		}

		&:before {
			font-size: inherit;
			margin-right: 10px;
		}
	}

	&.liked {
		a {
			color: $color-primary-dark;
		}
	}
}

// this mirrors the above but is not used by mojo
.like-component {
	a {
		&:hover {
			text-decoration: underline;
		}

		&:before {
			font-size: inherit;
			margin-right: 10px;
		}
	}

	&.liked {
		a {
			color: $color-primary-dark;
		}
	}
}

.re-like-count.re-like-component {
    cursor: pointer;
    padding: 10px 15px;

    #{$hoverfocus} {
        text-decoration: underline;
        text-decoration-skip-ink: auto;
    }

    &:before {
        font-size: inherit;
        margin-right: 5px;
    }
}

