@import "modules/variables.scss";
@import "partials/_rss_feed";

//profile right_col

.mls-tile-right-column {
	display: none;
}

.sign-up-note {
	padding-bottom: 10px;
}

.btn-signup {
	margin: 20px 0 0 0;

	@include btnflexy-v2(230px, none, #5d5c5c, #f0f0f0, 1px solid #e1e1e1, true, #f5f5f5, #efefef);
}

#CondensedAdContainer {

	.re-rightside-adv-component {
		position: relative;
		top: 0;
	}

	.re-rightside-adv-component-stickyAds {
		position: fixed;
		top: 70px;
		width: 241px;
	}
}

.col_right, .re-stickysidebar-inner, .re-feedapp-content-secondary {
	.rightside-module {
		padding-top: 0;
		overflow: auto;

		.re-activity-feed-page &,
		.re-own-profile-page &,
		.re-others-profile-page & {
			padding-bottom: 0;
		}

		.re-activity-feed-page & {
			margin-bottom: $spacing-lg;
		}
	}

	.component-right-column-blocks {
		.img-box {
			padding: 15px 0 15px 0;
			border-top: 1px solid #E3E3E3;

			img {
				width: 100%;
			}

			&:first-child {
				border-top: 0;
			}
		}
	}

	.re-call-to-action-tile {
		font-size: $font-size-sm;

		&:first-of-type {
			padding-top: $spacing-lg;
		}

		&:last-of-type {
			padding-bottom: $spacing-lg;
		}

		li {
			background: $rGreyLight;
			margin-bottom: 2px;

			&:first-child {
				font-weight: $font-weight-bold;
				padding: 8px 8px 8px 15px;
				background: $rGreyDarkest;
				color: $color-white;
				position: relative;
				cursor: pointer;

				&:after {
					content: "\f067";
					font: $font-awesome;
					position: absolute;
					right: 10px;
				}

				&:hover {
					text-decoration: underline;
				}
			}

			a {
				display: none;
			}
		}

		&.active {
			li {

				&:first-child {
					cursor: default;

					&:after {
						content: " ";
					}

					&:hover {
						text-decoration: none;
					}
				}

				a {
					color: #000;
					display: block;
					line-height: 1.2;
					max-width: 250px;
					padding: 13px 25px 13px 15px;
					position: relative;
					text-decoration: none;
					word-wrap: break-word;

					&:after {
						color: $rGreyDarkest;
						content: "\f0a9";
						font: $font-awesome;
						font-size: $font-size-sm;
						position: absolute;
						right: 10px;
						top: calc(50% - #{$font-size-sm}/2);
					}

					&:hover {
						background: $rGrey;
					}
				}
			}
		}
	}
	// sidebar widgets common elements
	.re-sidebarwidget-title {
		font-weight: $font-weight-bold;
		margin-bottom: 20px;
		font-size: $font-size-sm;
	}
}





// group info

.re-groupinfo-container:not(.hide) + .rightside-module {
	margin-top: 10px;
}


.usercreated-group-details {
	@include flexSpaceBetween(wrap);

	.btn-view-members {
		#{$hoverfocus} {
			text-decoration: underline;
		}
	}

	.re-grouptype {
		font-weight: $font-weight-semibold;
	}
}

.usercreated-group-settings {
	@media only screen and (min-width: 500px) and (max-width: 900px) {
		@include flexSpaceBetween(wrap);
	}

	a {
		cursor: pointer;
		flex: 0 0 49%;
	}

	.re-btn {
		@include flexSpaceBetween();
		align-items: center;

		&.re-icon:before {
			order: 2;
			margin-right: 0;
			margin-left: 10px;
		}

		&.re-icon-user-plus:before {
			margin-right: -2%;
		}
	}

	.re-leavegroup {
		display: block;
		text-align: right;
		flex: 1 0 100%;
	}
}

.re-group-banner {
	@media only screen and (max-width: 900px) {
		display: none;
	}

	li {
		list-style: none;
	}
	img {
		display: block;
		max-width: 100%;
		margin-top: 8px;
	}
}

.group-image-badge-container, .group-image-header-container {
	input {
		width: 98%;
	}
}

.re-side-tile-title {
	font-size: $font-size-sm;
	border-bottom: $border;
	padding-bottom: $spacing-sm;
	margin-bottom: $spacing-sm;
	font-weight: $font-weight-bold
}

.re-remax-tile-list {
	margin: $spacing-sm 0;
}
