@import "modules/mixins_buttons";

.smoke-base {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	visibility: hidden;
	opacity: 0;
	transition: all 0.3s;
}

.smoke-base.smoke-visible {
	opacity: 1;
	visibility: visible;
    z-index: 1002 !important;
}

.smokebg {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}

.smoke-base .dialog {
	/*position: absolute */
	position: fixed;
	overflow: auto;
	z-index: 9999;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
}

.dialog-prompt {
	margin-top: 5px;
	text-align: center;
}

.dialog-buttons {
	margin: 30px 0 5px 0;

	button {
		@include btn2();

		&.cancel {
			@include btn2-color(transparent, $color-black, transparent, $color-black);
			padding-left: 0;
			min-width: auto;
			padding-right: 5px;

			&:hover, &:focus {
				outline: 0;
				text-decoration: underline;
			}
		}

		+ button {
			margin-left: 10px;
		}
	}
}

.smoke {
	font-weight: bold;
	text-align: center;
	font-size: 30px;
	line-height: 130%;
}

.dialog-prompt input {
	margin: 0;
	border: 0;
	outline: none;
	border: 1px solid #333;
	width: 97%;
	background-color: #fff;
	font-size:  $font-size-sm;
	padding: 5px;
}

.smoke-base {
	background: rgba(0, 0, 0, 0.7);
}

.smoke-base .dialog {
	top: 25%;

	/*left: 33% */
	width: 400px;

	border-radius: $border-radius-large;
}

.smoke-base .dialog-inner {
	padding: 35px 40px 30px;
	font-size: $font-size-md;
	color: $headingColor;
	line-height: 1.8;
	font-weight: $font-weight-semibold;
}

.smoke {
	background-color: white;
}

.queue {
	display: none;
}

@media only screen and (max-width: 420px) {
	.smoke-base .dialog {
		left: 2%;
		max-width: 285px;
	}
}
