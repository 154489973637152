@import "modules/variables.scss";

// global content wrapper
// ---------------------------------------------
body {
    background: $backgroundColor;
}

.content-wrapper {
    font-size: $font-size-md;
    height: 100%;
    width: 100%;
}

.content-inner {
    box-sizing: border-box;
    max-width: 1160px;
    padding: 10px;
}

.component-footer-wrapper {
    font-size: $font-size-xs;
}

// *****************************
// tablet and desktop - min width 800px
// *****************************
@media only screen and (min-width: 800px) {
    // global content wrapper
    .content-wrapper {
        min-height: 650px;
    }

    .content-inner {
        background: #fff;
        margin: 20px auto;
        padding: 20px 15px;
        position: relative;
        transition: margin 0.3s linear;
    }
}

