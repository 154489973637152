@import "modules/variables.scss";

.re-message-container {
	font-family: $font-family-sans;
	font-size: $font-size-sm;
	left: 50%;
	position: fixed;
	top: 10px + $topnavheight;
	z-index: 1500;
}

.re-message-control-delete {
	color: $color-white;
	cursor: pointer;
	display: block;
	height: 20px;
	position: absolute;
	right: 2px;
	text-indent: -9999em;
	top: 2px;
	width: 20px;

	&:before {
		content: "\f00d";
		font: $font-awesome;
		height: 15px;
		position: absolute;
		right: 0;
		text-indent: 0;
		top: 2px;
		width: 15px;
	}
}

.re-message {
	left: -50%;
	background: $color-green;
	color: $color-white;
	max-width: 500px;
	margin-bottom: 5px;
	min-width: 200px;
	padding: 10px 30px 10px 20px;
	position: relative;
	text-align: center;

	a {
		color: $color-white;
		text-decoration: underline;
	}
}

.re-message-warning {
	background: $color-yellow;
	color: $color-black;

	a {
		color: $color-black;
	}
}

.re-message-error {
	background: $color-error;
}

.re-message-control-retry {
	display: inline-block;
	position: relative;
	text-decoration: underline;
	color: $color-white;
}

.re-message-loading .re-message-control-retry {
	text-indent: -9999em;
}

.re-message-loading .re-message-control-retry::after {
	background: url("/assets/common/images/ajax-loader-error-2.gif") scroll no-repeat center center transparent;
	content: " ";
	display: block;
	left: 0;
	height: 16px;
	position: absolute;
	top: 0;
	width: 16px;
}
