﻿@import "modules/variables";
@import "modules/mixins";

.re-usertask-container {
    display: flex;
    flex-direction: column;

    .re-usertask-banner {
        border-radius: $border-radius-large;
    }

    .re-usertask-title {
        font-weight: $font-weight-semibold;
        font-size: $font-size-md;
        margin: $spacing-sm 0 $spacing-md;
    }

    .re-usertask-desc {
        margin-bottom: $spacing-md;
    }

    .re-usertask-cta {
        font-weight: $font-weight-semibold;
    }

    .re-usertask-checkbox {
        background-color: $color-blue-dark;
        color: $color-white;
        margin: $spacing-md -15px -15px;
        border-bottom-left-radius: $border-radius-large;
        border-bottom-right-radius: $border-radius-large;
        display: flex;
        padding: $spacing-md;
        cursor: pointer;

        .re-usertask-checkbox-label {
            padding-left: 5px;
        }
    }

    .re-usertask-completed-content {
        margin-top: $spacing-md;

        .re-usertask-completed-message {
            font-size: $font-size-md;
            font-weight: $font-weight-semibold;
        }

        .re-usertask-undo {
            background: transparent;
            margin: $spacing-lg auto;
            display: block;
            text-decoration: underline;
            color: black;
        }
    }
}

.re-usertask-content {
    display: flex;
    justify-content: space-between;
    flex-flow: column;
    opacity: 1;
    transition: opacity 0.6s ease-in-out;
    pointer-events: auto;

    .re-usertask-done & {
        opacity: 0;
        pointer-events: none;
    }
}

.re-usertask-completed {
    text-align: center;
    margin: $spacing-md 0;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.6s ease-in-out;
    width: 100%;
    pointer-events: none;

    .re-usertask-done & {
        opacity: 1;
        pointer-events: auto;
    }
}
