@import "modules/variables.scss";

.re-stickysidebar {
	@media #{$mediaquery-nav-full-min} {
		will-change: min-height;
		z-index: 1;
	}
}

.re-stickysidebar-inner {
	@media #{$mediaquery-nav-full-min} {
		transform: translate(0, 0); /* For browsers don't support translate3d. */
		transform: translate3d(0, 0, 0);
		will-change: position, transform;
	}
}
