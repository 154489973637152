/*********************************** */
/* RESAAS user info component */
/*********************************** */


.re-userinfo-container {
    display: flex;
    flex-flow: row;
    width: 475px;

    @media #{$mediaquery-sm-max} {
        width: 250px;
        margin: auto;
        flex-flow: column;
    }
}

.re-userinfo-details {
    display: flex;
    flex-flow: column;
    width: 100%;
}

.re-userinfo-avatar {
    flex-basis: 125px;
    margin: 7px 0 $spacing-lg $spacing-sm;

    @media #{$mediaquery-sm-max} {
        margin: $spacing-md auto 5px;
    }
}

.re-userinfo-item {
    margin: $spacing-sm $spacing-lg;

    @media #{$mediaquery-sm-max} {
        text-align: center;
        margin: 5px $spacing-lg;
    }

    a {
        color: $rBlack;
    }
}



.re-placeholder-avatar {
    animation: fading 1.5s infinite;
    background: $color-grey-xtralight;
    margin-bottom: $spacing-sm;
    border-radius: 100%;
    height: 110px;
    position: relative;
    margin: 7px 0 $spacing-lg $spacing-sm;
    width: 110px;
}

.re-placeholder-text {
    background: $color-grey-xtralight;
    border-radius: 3px;
    height: $spacing-sm;
    width: 150px;
    margin: 0 auto 10px;
}

.re-placeholder-btn {
    background: $color-grey-xtralight;
    border-radius: 3px;
    display: inline-block;
    height: 30px;
    margin: 5px;
    width: 220px;
}

.re-userinfo-fallback-message {
    margin: 20px;
    width: 100%;
}

.re-userinfo-detail {
    display: inline-flex;
    flex-flow: column;

    @media #{$mediaquery-md-min} {
        flex-flow: row;
    }
}

.re-userinfo-firm:before {
    @media #{$mediaquery-md-min} {
        content: '\2002\00b7\2002';
        font-weight: $font-weight-bold;
    }
}

.re-userinfo-name {
    font-weight: $font-weight-bold;
}

