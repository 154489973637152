#firm-branding-details, #firm-office-details {
	margin: 0 auto 18px;
}

.leftside-module {
	background: #fff;
	padding: 0 5px 0 5px;
	margin: 2px;
}

.ask-a-question {
	padding: 28px;
	margin-bottom: 10px;

	.ask-a-question-img {
		height: 27px;
		margin: 0 -3px -15px 112px;
	}
}

#ask-question-text {
	margin-bottom: 10px;
	line-height: 20px;
	font-size: $font-size-lg;
}

.arrow-question-box {
	float: right;
}

.thanks-for-asking {
	border-bottom: 1px #ccc solid;
	padding: 25px 25px 55px 25px;
	margin-bottom: 10px;

	.icon-thanks-arrow {
		float: right;
	}

	.icon-q2 {
		position: absolute;
		background: transparent url(/assets/home/images/ui/sprite-feature-icons.png) top left no-repeat;
		height: 35px;
		width: 35px;
		left: 35px;
		text-align: center;
		text-indent: -9999px;
	}

	.thanks-single-word {
		font-size: $font-size-lg;
	}
}

.why-ask-a-question {
	margin: 10px 0 0 0;
}


.border-glow-search-bar {
	position: absolute;
	width: 96%;
	right: 0;
	left: 0;
	top: -1px;
	border: 1px yellow solid;
	padding: 16px 16px 22px 16px;
	z-index: 0;
	border-radius: 5px;
	box-shadow: 0 0 2px 2px yellow;
}

.ie7 .border-glow-search-bar {
	position: absolute;
	width: 95%;
	left: -1px;
	top: -1px;
	border: 2px yellow solid;
	padding: 19px 19px 7px 19px;
	z-index: 0;
}

.ie8 .border-glow-search-bar {
	position: absolute;
	width: 95%;
	left: -1px;
	top: -1px;
	border: 2px yellow solid;
	padding: 19px;
	z-index: 0;
}

.ie9 .border-glow-search-bar {
	position: absolute;
	width: 95%;
	right: 0;
	left: 0;
	top: -1px;
	border: 1px yellow solid;
	padding: 19px;
	z-index: 0;
}
