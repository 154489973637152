@import "modules/variables.scss";


.re-email-verify-container {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;

    .re-email-verify-title {
        margin: $spacing-lg 0;
    }

    .re-email-verify-main-text {
        margin: 20px auto 10px;
        max-width: 600px;
        text-align: center;
    }

    .re-email-verify-email {
        font-size: $font-size-lg;
    }

    .re-email-verify-resend-btn {
        margin-top: 30px;
    }

    .re-email-verify-support {
        font-size: $font-size-xs;
        margin-top: $spacing-sm;
    }
}

.re-email-verify-trouble {
    font-size: $font-size-sm;
    margin-top: $spacing-xl;
    text-align: center;

    p {
        font-size: $font-size-sm;
        margin-bottom: 1.2em;
    }

    a {
        text-decoration: underline;
        color: $color-black;
    }
}

.re-email-send-again {
    font-size: $font-size-sm;
    text-decoration: underline;
}

.re-email-verify-icon-outer {
    border-radius: 100px;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.re-email-verify-icon-inner {
    border-radius: 80px;
    width: 80px;
    height: 80px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.re-email-verify-icon-outer-grey {
    background: $color-grey-light;
}

.re-email-verify-icon-inner-grey-light {
    background: $color-grey-xtralight;
}

.re-email-verify-icon-outer-green-highlight {
    background: $color-green-highlight;
}

.re-email-verify-icon-inner-green-light {
    background: $color-green-light;
}

.re-code-verify-content {
    text-align: center;
    flex: 1;

    p {
        margin-top: $spacing-sm;
        line-height: 1.4;

        &:first-child {
            margin-top: 0;
        }
    }

    .re-code-verify-content-text {
        margin-bottom: $spacing-lg;
    }
}

.re-popup-component {
    .re-code-verify-actions {
        text-align: right;
    }
}



