.single-reblast-container .re-sharecrmform-open-btn,
.component-view-listings .re-sharecrmform-open-btn {
	position: absolute;
	right: 10px;
	top: 10px;
}

.overlay-wrapper {
	.re-overlay-close-btn {
		position: absolute;
		top: 10px;
		right: 10px;
		color: $color-grey-light;
	}

	.re-sharecrm-description {
		margin-bottom: 30px;
	}

	.re-sharecrm-form-title {
		font-size: $font-size-xl;
		font-weight: $font-weight-bold;
		margin-bottom: 0.5em;

		&:before {
			margin-right: 5px;
			vertical-align: bottom;
		}
	}

	.re-input-container {
		position: relative;
	}

	.re-sharecrm-open-contactlist-btn {
		color: $color-primary;
		position: absolute;
		right: 10px;
		bottom: 21px;
	}
	// this will need to be positioned to be responsive
	.re-sharecrm-contactlist {
		position: absolute;
		right: -300px;
		top: 3px;
		width: 270px;
		height: 317px;
		box-sizing: border-box;
	}

	.re-sharecrm-contactlist-list {
		overflow: auto;
		max-height: 165px;
		box-sizing: border-box;
		padding-top: 1px;
	}

	.re-sharecrm-contactlist-actions {
		margin-top: 10px;
	}

	.re-sharecrm-message-textarea {
		resize: vertical;
	}

	.sharecrm-image {
		margin-top: 10px;

		img {
			max-width: 100%;
			display: block;
			margin-left: auto;
			margin-right: auto;
		}
	}

	.re-sharecrm-listing-preview {
		display: flex;
		align-items: center;
		margin-bottom: 20px;

		@media #{$mediaquery-xs-max} {
			flex-wrap: wrap;
		}
	}

	.re-sharecrm-listing-image {
		width: 100px;
		display: inline-block;
		vertical-align: middle;
		flex: 1 0 100px;
	}

	.re-sharecrm-listing-specs {
		display: inline-block;
		vertical-align: middle;
		padding-left: 20px;
		flex: 3 0 250px;
		box-sizing: border-box;

		@media #{$mediaquery-xs-max} {
			padding: 0;
			margin-bottom: 10px;
			order: -1;
		}
	}

	.re-sharecrm-listing-price {
		display: block;
		font-weight: $font-weight-bold;
		font-size: $font-size-lg;
	}

	.re-sharecrm-listing-info {
		text-align: center;
		margin-top: 10px;
	}

	.re-sharecrm-listing-address {
		color: $color-primary;
		text-decoration: underline;
	}

	.re-sharecrm-listing-details {
		li {
			display: inline-block;

			&:not(:last-child):after {
				content: '|';
				margin-left: 4px;
			}
		}
	}
	//preview
	&.overlay-sharecrm-preview {
		width: 640px;
		max-width: 100%;

		@media only screen and (max-width: 710px) {
			top: 0 !important; // overriding JS
			margin: 10px;
			position: fixed;
			height: calc(100% - 20px);
			width: calc(100% - 20px);
			overflow: auto;
		}

		.sharecrm-preview-header {
			padding: 12px 15px;
			border: 1px solid $rGrey;
			margin-top: -1px;

			&:first-child {
				border-radius: $border-radius-small $border-radius-small 0 0;
			}
		}

		.sharecrm-preview-header-label:after {
			content: ":";
		}

		.sharecrm-email-content {
			border: 1px solid #cfcfcf;
			padding: 20px;
			margin-top: -1px;
			margin-bottom: 20px;
			// overriding _utilities.scss override (this hurts)
			td[align="center"] {
				text-align: center;
			}

			a span,
			a img {
				margin-left: auto;
				margin-right: auto;
			}
		}

		.re-sharecrm-preview-actions {
			text-align: right;
		}
	}
	//success
	&.overlay-sharecrm-success {
		.re-form-actions {
			padding-bottom: 0;

			.re-btn {
				margin-bottom: 0;
			}
		}
	}
}
