﻿body {
	line-height: 1.4;
	font-size: $font-size-sm;
}

a {
    text-decoration: none;
    color: $linkColor;

    &:hover, &:focus {
        text-decoration: underline;
        text-decoration-skip-ink: auto;
    }
}

// heading
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
	font-weight: 400;
}

h1, .h1 {
	font-size: 24px;
	line-height: 1.5em;
	letter-spacing: -1px;
}

h2, .h2 {
	font-size: 24px;
}

h3, .h3 {
	font-size: $font-size-sm;
	line-height: 1.2em;
	// temporary solution
	font-weight: normal;
	text-transform: none;
}

hr {
	border-top-color: $border-color;
}

em {
    font-style: italic;
}

/* texts */
.re-text-xxs {
    font-size: $font-size-xxs;
}

.re-text-xs {
    font-size: $font-size-xs;
}

.re-text-sm {
    font-size: $font-size-sm;
}

.re-text-md {
    font-size: $font-size-md;
}

.re-text-lg {
    font-size: $font-size-lg;
}

.re-text-xl {
    font-size: $font-size-xl;
}

.re-text-xxl {
    font-size: $font-size-xxl;
}


/* text Weights */
.re-text-semibold {
    font-weight: $font-weight-semibold;
}

.re-text-bold {
    font-weight: $font-weight-bold;
}

.re-text-black {
    color: $color-black;
}
