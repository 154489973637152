@mixin message-error-colors {
	color: $color-white;
	background: $color-error;
}

@mixin message-success-colors {
	color: $color-white;
	background: $color-green;
}

@mixin message-warning-colors {
	color: $rBlack;
	background: $color-yellow;
}

@mixin message-info-colors {
	color: #00529B;
	background: #BDE5F8;
}

.rs-message {
	overflow: hidden;
	padding: 5px 10px;
	width: 100%;

	p {
		line-height: 25px;
		font-size: $font-size-sm;
	}

	&.hide {
		display: none;
	}

	.gutter {
		margin: 8px;
	}

	&.error {
		@include message-error-colors;
	}

	&.success {
		@include message-success-colors;
	}

	&.warning {
		@include message-warning-colors;
	}

	&.info {
		@include message-info-colors;
	}
}
