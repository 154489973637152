/*********************************** */
/* RESAAS containers				 */
/*********************************** */
@import 'modules/variables';
@import 'modules/mixins';

.re-container {
    @include container;

    &-child-fullwidth {
        margin-left: $spacing-lg * -1;
        margin-right: $spacing-lg * -1;
    }

    &-sm {
        @include container($spacing-sm);
    }

    &-md {
        @include container($spacing-md);
    }

    &-xl {
        @include container($spacing-lg);

        @media #{$mediaquery-sm-min} {
            @include container($spacing-xl);
        }
    }

    &-outer {
        @include outerContainer;
    }

    &-inner {
        border-radius: $border-radius-large;
        background: $color-grey-xtralight;
    }

    &-warning {
        background: $color-yellow;
    }

    &-header {
        font-size: $font-size-sm;
        border-bottom: $border;
        padding-bottom: $spacing-sm;
        margin-bottom: $spacing-sm;
        font-weight: $font-weight-bold
    }
}