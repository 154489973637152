@import "modules/variables.scss";
@import "modules/mixins.scss";
@import "modules/mixins_buttons";

.formV2 {
    fieldset {
        border: 0;
    }

    legend {
        display: none;
    }

    label, .formv2-label {
        display: inline-block;
        margin-bottom: 5px;

        .placeholder & {
            display: inline-block;
        }
    }

    input[type="text"],
    input[type="url"],
    input[type="tel"],
    input[type="number"],
    input[type="password"],
    input[type="email"],
    select {
        height: 48px;
        background: $color-white;
    }

    textarea {
        resize: vertical;
        min-height: 120px;
        padding-top: 20px;
        padding-bottom: 20px;

        &.re-textarea-short {
            min-height: 70px;
        }
    }

    input[type="date"] {
        -webkit-appearance: none;
    }

    input[type="date"],
    input[type="text"],
    input[type="url"],
    input[type="tel"],
    input[type="number"],
    input[type="password"],
    input[type="email"],
    select,
    textarea {
        border-radius: $border-radius-small;
        border: 1px solid $color-grey-light;
        box-sizing: border-box;
        color: $color-black;
        padding: 12px 15px;
        width: 100%;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type="number"] {
        -moz-appearance: textfield;
    }

    input[type="date"],
    input[type="text"],
    input[type="url"],
    input[type="tel"],
    input[type="number"],
    input[type="password"],
    input[type="email"],
    select,
    input[type="checkbox"],
    input[type="radio"],
    input[type="checkbox"] + label,
    input[type="radio"] + label,
    textarea,
    p {
        font-size: $font-size-md;
        margin-bottom: 10px;

        &:focus {
            border: 1px solid $color-grey;
        }
    }

    input[type="checkbox"],
    input[type="radio"] {
        margin: 8px;
        vertical-align: baseline;

        &:focus {
            outline: solid;
            outline-width: 1px;
            outline-color: $color-grey;
        }
    }

    input[type="checkbox"] + label,
    input[type="radio"] + label {
        display: inline-block;
        font-size: $font-size-sm;
    }

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        appearance: none;
        background: url(/assets/common/images/formV2-select-down-arrow.png) no-repeat $color-white 100% 15px;
        padding-right: 30px;
    }

    select[disabled] {
        background-color: $color-white;
        color: $color-grey-light;
    }

    select::-ms-expand {
        display: none;
    }

    .formV2-inputSelectCombo input {
        border-radius: 3px 0 0 3px;
        border-right: 1px;
    }

    .formV2-inputSelectCombo select {
        border-radius: 0 3px 3px 0;
    }

    .formV2-inputSelectButtonCombo input {
        border-radius: 0;
        border-right: 1px;
        border-left: 1px;
    }

    .formV2-inputSelectButtonCombo select {
        border-radius: 3px 0 0 3px;

        @media (max-width: $screen-sm-min) {
            width: 105px;
        }
    }

    .formV2-inputSelectButtonCombo button {
        border-radius: 0 3px 3px 0;
    }

    .formV2-inputGeolocationButtonCombo {
        display: flex;
    }

    .formV2-inputGeolocationButtonCombo input {
        border-radius: $border-radius-small 0 0 $border-radius-small;
        border-right: 0;
        min-width: 0;
    }

    .formV2-inputGeolocationButtonCombo button {
        background: #eff0f4;
        border: 1px solid $color-grey-light;
        border-radius: 0 $border-radius-small $border-radius-small 0;
        box-sizing: border-box;
        height: 48px;
        min-width: 0;
        width: 58px;

        &:before {
            opacity: 0.5;
            transition: all 0.2s ease;
        }

        #{$hoverfocus} {
            &:before {
                opacity: 1;
            }
        }

        .submitting &,
        &.submitting {
            @include submitting;

            &::before {
                @include submittingBefore;
            }
        }
    }

    .formV2-invite-title {
        font-weight: $font-weight-bold;
        font-size: $font-size-lg;
    }

    .formV2-invite-subtext {
        font-size: $font-size-md;
    }

    .formV2-inviteInput {

        .re-invite-href {
            color: $color-grey;
        }

        button {
            color: $color-white;
            background-color: $color-primary;
            width: 145px;
        }

        .re-input-wrapper {
            width: 100%;
        }

        input[type="text"], input[type="text"].error {
            box-shadow: none;
        }
    }

    .formV2-inputSubmitButtonCombo {
        display: flex;
        flex-wrap: wrap;
    }

    .formV2-inputSubmitButtonCombo input {
        border-radius: $border-radius-small 0 0 $border-radius-small;
        border-right: 0;
        flex: 1;
        min-width: 0;
    }

    .formV2-inputSubmitButtonCombo button {
        background: $color-primary;
        border: 1px solid $color-primary;
        border-radius: 0 $border-radius-small $border-radius-small 0;
        box-sizing: border-box;
        color: $color-white;
        height: 48px;
        min-width: 0;
        width: 58px;

        &[disabled] {
            background: $color-grey-xtralight;
            border-color: $color-grey-light;
            color: $color-grey-light;
        }

        .submitting &,
        &.submitting {
            &::before {
                @include submittingBefore;
            }
        }
    }

    .formV2-inputSubmitButtonCombo .error-message {
        order: 3;
        width: 100%;
    }

    .formV2-inputPrefixed {
        display: flex;
        flex-direction: row-reverse;

        input {
            border-left: 0;
            border-radius: 0 3px 3px 0;
            padding-left: 0;

            &:focus {
                border-left: 0;
            }
        }

        &-prefix {
            height: 22px;
            padding: 12px 0 12px 12px;
            border: 1px solid $color-grey-light;
            border-radius: 3px 0 0 3px;
            border-right-width: 0;
            color: $color-grey-light;
            font-size: $font-size-md;
        }

        input:focus + .formV2-inputPrefixed-prefix {
            border-color: $color-black;
        }
    }

    input[type=text].formV2-datepicker {
        background-image: url(/assets/common/images/calendar-alt-light.svg);
        background-size: 20px;
        padding-right: 40px;
        background-repeat: no-repeat;
        background-position: calc(100% - 10px) center;
    }

    html.checked & {
        input[type="radio"] {
            @include visually-hidden;

            & + label {
                padding-left: 24px;
                position: relative;

                &:before {
                    border: 1px solid $color-grey-light;
                    border-radius: 12px;
                    content: " ";
                    display: inline-block;
                    height: 16px;
                    left: 0;
                    position: absolute;
                    top: 0;
                    vertical-align: middle;
                    width: 16px;
                }
            }

            &:focus + label:before {
                border: 1px solid $color-black;
            }

            &:checked + label::after {
                background: $color-primary;
                border-radius: 10px;
                content: " ";
                display: block;
                height: 12px;
                left: 3px;
                position: absolute;
                top: 3px;
                width: 12px;
            }
        }
        //multiple inline radio buttons
        input[type='radio'] + label + input[type='radio'] + label {
            margin-left: $spacing-lg;
        }

        input[type="checkbox"] {
            @include visually-hidden;

            & + label {
                padding-left: 24px;
                position: relative;

                &:before {
                    border: 1px solid #cacece;
                    border-radius: 3px;
                    content: " ";
                    display: block;
                    font: $font-awesome;
                    height: 16px;
                    left: 0;
                    line-height: 16px; // vertically aligning icon
                    position: absolute;
                    text-indent: 1px;
                    top: -1px;
                    width: 16px;
                }
            }

            &:checked + label:before {
                background: $color-green;
                border-color: $color-green;
                color: $color-white;
                content: "\f00c";
            }

            &:focus + label:before {
                border: 1px solid $color-black;
            }

            &.re-checkbox-secondary:checked + label {
                &:before {
                    background: $color-primary;
                }
            }
        }
        //input toggle button
        .formV2-inputToggleButton {
            font-size: $font-size-sm;

            &.small {
                font-size: $font-size-xs;
            }

            input[type="checkbox"] {

                * {
                    display: inline-block
                }

                &:focus + label {
                    box-shadow: 0px 0px 1px 2px $color-white, 0px 0px 2px 3px $color-primary;
                }

                & + label {
                    color: $color-white;
                    background: $color-grey-light;
                    border-radius: 20px;
                    font-size: inherit;
                    line-height: 2;
                    padding-left: 2em;
                    min-width: 3em;
                    text-align: center;

                    .label {
                        @include visually-hidden;
                    }

                    &:before {
                        border-radius: 50%;
                        content: " ";
                        display: inline-block;
                        height: 1.4em;
                        width: 1.4em;
                        font-size: inherit;
                        background: $color-white;
                        left: 0.3em;
                        top: 50%;
                        transform: translateY(-50%);
                        border: none;
                    }

                    .unchecked {
                        display: inline-block;
                        margin-right: 0.5em;
                    }

                    .checked {
                        display: none;
                    }
                }

                &:checked + label {
                    background: $color-primary;
                    padding-left: 0;
                    padding-right: 2em;

                    &:before {
                        right: 0.3em;
                        left: auto;
                    }

                    .checked {
                        display: inline-block;
                        margin-left: 0.5em;
                    }

                    .unchecked {
                        display: none;
                    }
                }
            }
        }
    }

    .re-input-clear::-ms-clear {
        width: 0;
        height: 0;
    }

    .re-icon-x {
        position: absolute;
        right: 60px;
        top: 15px;
        cursor: pointer;
    }

    .formV2-actions {
        margin-top: $spacing-lg;
    }

    input[type="text"],
    input[type="url"],
    input[type="tel"],
    input[type="number"],
    input[type="password"],
    input[type="email"],
    select,
    textarea {
        &.error {
            border-color: red;
            color: $color-black;
            background-color: $color-white;
            border-color: $color-grey-light;
            box-shadow: 0 4px 6px 1px #d5d7e2;
            padding-right: 45px;
        }
    }

    .re-label-disabled {
        color: $color-grey-light;
    }

    .re-label-optional {
        background-color: $rGreyLight;
        border-radius: 5px;
        padding: 2px 5px;
        font-size: $font-size-xs;
        font-weight: $font-weight-semibold;
    }

    label.re-label-conatiner {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    .re-field-disabled, input.re-field-disabled, input[disabled] {
        background-color: $color-white;
        color: $color-grey-light;
    }

    input::placeholder,
    textarea::placeholder {
        color: $color-grey-light;
    }

    input.re-field-disabled[type="radio"] + label:before {
        background-color: $color-grey-light;
    }

    .re-textfield-with-tip {
        margin-bottom: 10px;
        position: relative;

        textarea {
            border-radius: $border-radius-small $border-radius-small 0 0;
            margin-bottom: 0 !important; //overriding JS
            display: block;
            overflow-y: auto;
        }

        .re-textfield-tip {
            border: 1px solid $color-grey-light;
            border-top: none;
            border-radius: 0 0 $border-radius-small $border-radius-small;
            font-size: $font-size-xs;
            padding: 10px;
            padding-right: 50px;
            color: $color-grey;
            display: block;
            background: $color-grey-xtralight;
        }

        .counter {
            position: absolute;
            right: 1px;
            bottom: auto;
            margin-top: 1px;
            padding: 5px;
            background: none;
            font-size: $font-size-xs;
        }
    }

    .formV2-inputSelectCombo2 input {
        width: 50%;
    }

    .formV2-inputSelectCombo2 select {
        width: 50%;
    }

    .formV2-inputSelectCombo2 {
        display: flex;
        flex-wrap: wrap;

        label:nth-child(1) {
            flex: 1 0 100%;
        }

        label:nth-child(3) {
            @include visually-hidden;
        }

        label:nth-child(4) {
            @include visually-hidden;
        }

        input {
            border-right: 0;
            border-radius: $border-radius-small 0 0 $border-radius-small;
            flex: 1 1 0;
            max-width: 50%;
        }

        select {
            border-radius: 0 $border-radius-small $border-radius-small 0;
            flex: 1 1 auto;
            max-width: 50%;
        }

        &.formV2-inputSelectCombo2-30-70 {
            input {
                max-width: 30%;
            }

            select {
                max-width: 70%;
            }
        }

        &.formV2-inputSelectCombo2-hideLabel {
            label {
                @include visually-hidden;
            }
        }
    }

    .legend-visible {
        display: inline-block;
    }
    // input span combo
    .formV2-inputSpanCombo {
        display: flex;
        flex-wrap: wrap;

        label:nth-child(1) {
            flex: 1 0 100%;
        }

        .re-formV2-inputSpanCombo-input {
            border-right: 0;
            border-radius: $border-radius-small 0 0 $border-radius-small;
            max-width: 80%;
        }

        .re-formV2-inputSpanCombo-suffix {
            border-radius: 0 $border-radius-small $border-radius-small 0;
            border: 1px solid $color-grey-light;
            box-sizing: border-box;
            color: $color-black;
            flex: 1 1 auto;
            font-size: $font-size-md;
            height: 48px;
            margin-bottom: $spacing-sm;
            max-width: 20%;
            padding: 12px 15px;
            text-align: center;
        }
    }
}

.form-small {

    input[type="text"],
    input[type="url"],
    input[type="tel"],
    input[type="number"],
    input[type="password"],
    input[type="email"],
    select {
        height: 33px;
        padding: 5px 10px;
    }

    p {
        font-size: $font-size-sm;
    }

    select {
        background-position-y: 9px;
    }

    .re-input-wrapper-text.error:after, .re-input-wrapper-select.error:after {
        top: 6px;
    }
}

.re-textarea-lengthwarning {
    display: none;
    margin-top: -10px;
    text-align: right
}

.re-form-actions {
    padding: 10px 0;
    text-align: right;
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0;
    }
}

// validation error messages
.error-message {
    margin-top: -10px;
    margin-bottom: 10px;
    color: #ef4e4e;
    font-style: italic;
    margin: 0 0 15px 0;
}

.re-input-wrapper {
    position: relative;
}

.re-input-wrapper-text.error:after, .re-input-wrapper-select.error:after {
    color: #ef4e4e;
    content: "\f057";
    font-family: "FontAwesome";
    font-size: 23px;
    height: 22px;
    line-height: 1;
    position: absolute;
    right: 12px;
    text-align: center;
    top: 12px;
    width: 22px;
    background: white;
}


//input select combo
.formV2-inputSelectCombo {
    display: flex;

    input {
        box-sizing: border-box;
        width: 100%;
    }

    select {
        width: auto;
    }
}

.formV2-inputSelectCombo-input {
    flex: 1 1 100%;

    input {
        border-radius: 3px 0 0 3px;
        border-right: 0;
    }
}

.formV2-inputSelectCombo-select {
    flex: 1 1 0;

    label {
        visibility: hidden;
        white-space: nowrap;
        width: 1px;
    }
}

//input button combo
.formV2-inputSelectButtonCombo {
    display: flex;

    input {
        box-sizing: border-box;
        width: 100%;
    }

    select {
        width: auto;
    }
}

.formV2-inputSelectButtonCombo-input {
    flex: 1;

    input {
        border-radius: 3px 0 0 3px;
        border-right: 0;
    }

    label {
        visibility: hidden;
    }
}

.formV2-inputSelectButtonCombo-select {
    label {
        visibility: hidden;
    }
}

.formV2-inputSelectButtonCombo-button {
    label {
        visibility: hidden;
    }

    button {
        &:focus {
            border: 1px solid $color-grey;
            outline: 0;
        }
    }

}

//form actions
.formV2-actions {
    text-align: right;
}

//accordion
.formV2-accordion-link {
    color: $color-black;
    display: inline-block;
    margin: 20px 0 10px;
    text-transform: uppercase;

    &:before {
        content: "+ ";
    }

    &.js-accordion-link-selected:before {
        content: "\2013\2002";
    }

    &:focus {
        color: $rGreen2;
        text-decoration: underline;
    }
}

.formV2-accordion-section .formV2-accordion-link {
    margin-left: 15px;
}

.ie9, .ie8 {
    .formV2 {
        select {
            background: $color-white;
        }
    }
}

//placeholder form

.formV2-placeholder-fieldset {
    border-top: 1px solid #d8d8d8;
    padding: 0;

    &:first-child {
        border-top: 0;
    }
}

.formV2-placeholder-label {
    background: $color-grey-xtralight;
    border-radius: $border-radius-small;
    margin-bottom: $spacing-sm;
    height: 15px;
    width: 85px;
}

.formV2-placeholder-input {
    background: $color-grey-xtralight;
    border-radius: $border-radius-small;
    display: inline-block;
    height: 48px;
    margin-bottom: $spacing-sm;
    width: 100%;
}

.formV2-placeholder-radio {
    background: $color-grey-xtralight;
    border-radius: 100%;
    display: inline-block;
    height: 18px;
    margin: 0 4px $spacing-sm 0;
    width: 18px;

    & + .formV2-placeholder-label {
        display: inline-block;
        margin-right: $spacing-lg;
    }
}

.re-tooltip-link {
    margin-left: $spacing-lg;
    cursor: pointer;
    color: $color-primary;
    display: inline-block;
}

.formV2.formV2-fieldset fieldset {
    border-bottom: $border;
    padding: $spacing-lg 0;

    label {
        margin-top: $spacing-sm;
    }
}
.re-pin-container {
    display: flex;
    flex-flow: column;
    max-width: 367px;
    justify-content: center;
    margin: 0 auto;

    .re-pin-input-container {
        display: flex;
        margin: 7px auto;

        .re-input-pin {
            width: 43px;
            height: 48px;
            margin-left: $spacing-md;
            border: 1px solid $color-grey-light;
            text-align: center;
            font-size: 32px;
            font-family: $font-family-sans;
            border-radius: 3px;
            &[type="text"]{
                padding: 0px;
            }
            @media #{$mediaquery-xs-max} {
                width: 16%;
                height: 35px;
                font-size: $font-size-lg;
            }

            &:first-child {
                margin-left: 0
            }

            &:focus {
                outline: none;
                border: 1px solid black;
            }
        }
    }
}


