@import "modules/variables.scss";

//profile right_col rss feed

.re-rss-feed {
	padding: 20px 0 10px 0;
}

.re-rss-feed-header {
	font-size: $font-size-sm;
	font-weight: $font-weight-bold;
}

.re-rss-feed-content {
	padding: 22px 3px 22px 3px;
	border-bottom: 1px solid #E3E3E3;
}

.re-rss-feed-link {
	font-family: $font-family-sans;
	font-size: $font-size-sm;
	line-height: 18px;
	color: $color-grey;

	&:hover {
		color: $color-primary-dark;
		text-decoration: none;
	}
}
