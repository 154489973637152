@import 'partials/sprites_generated';

%svg-common {
	background-size: auto 100%;
}

// premium badges
.premium-badges-silver,
.premium-badges-silver-large,
.premium-badges-silver-medium,
.premium-badges-silver-small,
.premium-badges-silver-tiny {
	@extend .re-svg-badges-premium;

	&.re-premiumplus {
		@extend .re-svg-badges-premiumplus;
	}
}

// globalelite badges
.globalelite-assigned-badges-large,
.globalelite-assigned-badges-medium,
.globalelite-assigned-badges-small,
.globalelite-assigned-badges-tiny {
	@extend .re-svg-badges-globalelite;
}

// plan icons
.plan-icons-BrokerOS-1 {
	@extend .re-svg-planicons-star;
}

.plan-icons-BrokerOS-2 {
	@extend .re-svg-planicons-graph;
}

.plan-icons-BrokerOS-3 {
	@extend .re-svg-planicons-connections;
}

.plan-icons-BrokerOS-4 {
	@extend .re-svg-planicons-chat;
}

.plan-icons-Enterprise-1 {
	@extend .re-svg-planicons-timesaving;
}

.plan-icons-Enterprise-2 {
	@extend .re-svg-planicons-calendar;
}

.plan-icons-Enterprise-3 {
	@extend .re-svg-planicons-chat;
}

.plan-icons-Enterprise-4 {
	@extend .re-svg-planicons-global;
}

.plan-icons-GlobalElite-1 {
	@extend .re-svg-planicons-globalmarketing;
}

.plan-icons-GlobalElite-2 {
	@extend .re-svg-planicons-oneonone;
}

.plan-icons-GlobalElite-3 {
	@extend .re-svg-planicons-ondemandvideo;
}

.plan-icons-Premium-1 {
	@extend .re-svg-planicons-prioritydiscovery;
}

.plan-icons-Premium-2 {
	@extend .re-svg-planicons-alerts;
}

.plan-icons-Premium-3 {
	@extend .re-svg-planicons-emailmarketing;
}

.plan-icons-Premium-4 {
	@extend .re-svg-planicons-leadgen;
}

.plan-icons-RESAAS-1 {
	@extend .re-svg-planicons-listinguploads;
}

.plan-icons-RESAAS-2 {
	@extend .re-svg-planicons-unlimitedconnections;
}

.plan-icons-RESAAS-3 {
	@extend .re-svg-planicons-posts;
}

.plan-icons-RESAAS-4 {
	@extend .re-svg-planicons-ontouch;
}

// file type
.re-documenticon {
	@extend .re-svg-file-text;

	height: 33px;
	text-indent: -9999px;
	width: 26px;

	&.re-documenticon-pdf, &.re-documenticon-PDF {
		@extend .re-svg-file-pdf;
	}

	&.re-documenticon-png, &.re-documenticon-PNG {
		@extend .re-svg-file-png;
	}

	&.re-documenticon-jpg, &.re-documenticon-jpeg, &.re-documenticon-JPG, &.re-documenticon-JPEG {
		@extend .re-svg-file-jpg;
	}

	&.re-documenticon-mov, &.re-documenticon-MOV {
		@extend .re-svg-file-mov;
	}

	&.re-documenticon-m4v, &.re-documenticon-M4V {
		@extend .re-svg-file-m4v;
	}

	&.re-documenticon-wmv, &.re-documenticon-WMV {
		@extend .re-svg-file-wmv;
	}

	&.re-documenticon-mp4, &.re-documenticon-MP4 {
		@extend .re-svg-file-mp4;
	}

	&.re-documenticon-avi, &.re-documenticon-AVI {
		@extend .re-svg-file-avi;
	}

	&.re-documenticon-docx, &.re-documenticon-DOCX {
		@extend .re-svg-file-docx;
	}

	&.re-documenticon-xlsx, &.re-documenticon-XLSX,
	&.re-documenticon-xls, &.re-documenticon-XLS,
	&.re-documenticon-xlsm, &.re-documenticon-XLSM,
	&.re-documenticon-xlsb, &.re-documenticon-XLSB,
	&.re-documenticon-xltx, &.re-documenticon-XLTX,
	&.re-documenticon-xltm, &.re-documenticon-XLTM,
	&.re-documenticon-xlt, &.re-documenticon-XLT {
		@extend .re-svg-file-excel;
	}

	&.re-documenticon-ppt, &.re-documenticon-PPT {
		@extend .re-svg-file-ppt;
	}

	&.re-documenticon-pptx, &.re-documenticon-PPTX {
		@extend .re-svg-file-pptx;
	}

	&.re-documenticon-doc, &.re-documenticon-DOC {
		@extend .re-svg-file-doc;
	}
}